import React, { useContext, useEffect, useState } from 'react'

import FilterDateRange from '@components/filters/filter-date-range'
import FilterCurrency from '@components/filters/filter-dates-currency'
import FilterTypeKey from '@components/filters/filter-type-key'
import L3TabsLayout from '@components/layouts/l3-tabs-layout'
import { filterDateAsOfType } from '@constants/filter'
import AuthContext from '@contexts/auth'
import { useUserAccessFeature } from '@helpers/auth-provider'

import BorrowerConcentration from './borrower-concentration'
import DelinqByCohort from './delinq-by-cohort'
import FirstPaymentDefault from './first-payment-default'
import OutstandingDelinq from './outstanding-delinq'
import PortfolioAtRisk from './portfolio-at-risk'
import RollingDefaultRate from './rolling-default-rate'

const DelinquencyL3 = () => {
  const { filter_access, get_access } = useUserAccessFeature()
  const FEATURE = `analytics_risk_delinquency`
  const { activeFilters, appliedFilters, setAppliedFilters, company } =
    useContext(AuthContext)

  const {
    dateStart,
    dateEnd,
    categoryTypes = [],
    activeType,
    range,
    currency,
  } = activeFilters

  /**
   * observe whats filter required at initiation
   */
  const [filterReady, setFilterReady] = useState<boolean>(false)
  useEffect(() => {
    if (!filterReady && dateStart && dateEnd && categoryTypes.length > 0) {
      //carries filters over unless no previously applied filters
      JSON.stringify(appliedFilters) !== JSON.stringify({ activeFilters }) &&
        setAppliedFilters(activeFilters)
      setFilterReady(true)
    }
  }, [activeFilters])

  useEffect(() => {
    setFilterReady(false)
  }, [company?.slug_name])

  /**
   * observe reset button disable state criteria
   */
  const disableReset =
    range === 0 &&
    activeType === 'All' &&
    currency === company?.currencies_available?.[0]?.to_currency

  const tabs = [
    {
      label: 'Portfolio at Risk',
      component: PortfolioAtRisk,
    },
    {
      label: 'Borrower Concentration',
      component: BorrowerConcentration,
    },
    {
      label: 'Delinquency by Cohort',
      component: DelinqByCohort,
    },
    {
      label: 'First Payment Default (FPD) by Cohort',
      component: FirstPaymentDefault,
    },
    {
      label: 'Outstanding by Delinquency',
      component: OutstandingDelinq,
    },
    {
      label: 'Rolling Default Rate',
      component: RollingDefaultRate,
    },
  ]
    .filter(t =>
      filter_access(`${FEATURE}_${t.label.toLowerCase().split(' ').join('-')}`)
    )
    .map(t => {
      const exportable = get_access(
        `${FEATURE}_${t.label.toLowerCase().split(' ').join('-')}`,
        'export'
      )
      return {
        ...t,
        headerClassName: 'text-xs lg:text-sm',
        component: <t.component exportable={exportable} />,
      }
    })

  return (
    <L3TabsLayout
      title="Collection"
      filters={{
        left: <FilterCurrency dateAsOfType={filterDateAsOfType.loanTape} />,
        middle: <FilterDateRange />,
        right: <FilterTypeKey />,
      }}
      disableReset={disableReset}
      tabs={tabs}
    />
  )
}
export default DelinquencyL3
