import React, { useEffect, useRef } from 'react'

/**
 * HeightObserver is a wrapper that returns the size of the children with the onSizeChange callback. This function is required with react-grid-layout because react-grid-layout cannot detect child size automatically so we need to compute it with getBoundingClientRect after rendering.
 *
 * We also need this wrapper because react-grid-layout actually react-grid-layout clones our component a HTMLDivElement ref cannot be used directely by the parent of our children, thus the need for this middle Observer layer
 * @param param0
 * @returns
 */
const HeightObserver = ({
  children,
  onSizeChange,
  identifier,
}: {
  children: React.ReactNode
  onSizeChange: (s: any, indentifier: string) => void
  identifier: string
}) => {
  const heightRef = useRef<HTMLDivElement | null>(null)

  const measureSize = () => {
    if (!heightRef.current) {
      return
    }
    const { height } = heightRef.current.getBoundingClientRect()
    onSizeChange(height, identifier)
  }

  useEffect(() => {
    if (!heightRef.current) {
      return
    }
    measureSize()

    // Stateful observer for sizes
    const resizeObserver = new ResizeObserver(() => {
      measureSize()
    })

    resizeObserver.observe(heightRef.current)

    return () => resizeObserver.disconnect()
  })

  return <div ref={heightRef}>{children}</div>
}

export default HeightObserver
