import adminApiClient from './client'

export interface ValidationQuery {
  searchId?: string
  tests?: string[]
  severities?: string[]
}

export interface ValidationSubset {
  results: any[]
  total: number
}
export interface ValidationSelection {
  [key: string]: {
    tests: string[]
    severities: string[]
  }
}

export interface ValidationTest {
  name: string
  description: string
  entity: 'loans' | 'customers' | 'transactions'
  severity: string
  status: 'active' | 'inactive'
  type: string
  columns: string[]
  count: number
}

export interface AvailableDate {
  dates: string[]
}

export class ValidationService {
  static async getSummaries(slugName: string) {
    const { data } = await adminApiClient.get<ValidationTest[]>(`/${slugName}`)
    return data
  }

  static async getValidationSelections(slugName: string) {
    const url = `/results/${slugName}`

    const { data } = await adminApiClient.get<ValidationSelection>(url)
    return data
  }

  static async getValidationSubset(
    slugName: string,
    type: string,
    offset: number,
    limit: number,
    searchId: string | undefined,
    tests: string[] | undefined,
    severities: string[] | undefined
  ): Promise<ValidationSubset> {
    const url = `/results/${slugName}/${type}`
    const { data } = await adminApiClient.get<ValidationSubset>(url, {
      params: {
        offset,
        limit,
        ...(tests?.length ? { test_names: tests.join() } : {}),
        ...(severities?.length ? { severities: severities.join() } : {}),
        ...(searchId ? { id: searchId } : {}),
      },
    })
    return data
  }
}
