import React from 'react'

import Typography from '@components/atoms/typography'
import RadarChart from '@components/chart/radar'
import { greenToRed } from '@helpers/green-to-red'
import { ArrowPathIcon } from '@heroicons/react/24/outline'
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/solid'
import { Facility } from '@interfaces/facility'
import { MonitorOverviewResponse } from '@interfaces/manage-monitor-borrowing-base'

import {
  extractDisplayValueByOrderNumber,
  extractTitleByOrderNumber,
} from '../helper'

const SurplusChart = ({
  facility,
  data,
  isSurplusFetching,
}: {
  facility?: Facility
  data?: MonitorOverviewResponse[]
  isSurplusFetching: boolean
}) => {
  const percent = extractDisplayValueByOrderNumber(1, data)
  const surplusDeficiency = extractDisplayValueByOrderNumber(2, data)
  const daysDeficient = extractDisplayValueByOrderNumber(3, data)
  const collateralizationPercentTitle = extractTitleByOrderNumber(1, data)
  const surplusTitle = extractTitleByOrderNumber(2, data)
  const daysDeficientTitle = extractTitleByOrderNumber(3, data)
  const graphPercent = (Number(percent.replace(/[%|,]/g, '')) || 0) / 2
  const isPercentage = percent.includes('%')
  const Icon = graphPercent >= 50 ? CheckCircleIcon : ExclamationTriangleIcon
  const color = greenToRed(
    100 - graphPercent - (graphPercent >= 50 ? 20 : 0),
    true
  )

  return (
    <div className="flex flex-col gap-6 items-center justify-between h-full p-6">
      <Typography className="font-semibold text-cc-primary-gray-dark">
        Borrowing Base
      </Typography>
      {isSurplusFetching ? (
        <div className="flex justify-center mt-2 w-full h-full relative">
          <ArrowPathIcon className="animate-spin text-primary-main w-8" />
        </div>
      ) : (
        <div className="w-full">
          {isPercentage && (
            <RadarChart
              id={`radar-chart${facility ? `-${facility?.facility_id}` : ''}`}
              percent={graphPercent}
            />
          )}
          <div className="flex flex-col items-center">
            <Typography className="font-light text-sm text-cc-text-primary">
              {collateralizationPercentTitle === ''
                ? 'Collateralization Percentage'
                : collateralizationPercentTitle}
            </Typography>
            <div
              className={`text-xl font-medium pb-6 ${
                Number(percent.substring(0, percent.length - 1)) >= 100 ||
                !isPercentage
                  ? 'text-cc-positive'
                  : 'text-cc-negative'
              }`}
            >
              {percent ? percent : '-'}
            </div>
            <Typography className="font-light text-sm text-cc-text-primary">
              {surplusTitle === '' ? 'Surplus or Deficency' : surplusTitle}
            </Typography>
            <div className="flex justify-center">
              <Icon
                className="w-6 h-6 mr-2"
                style={{ color: color.toString() }}
              />
              <Typography className="text-l font-light text-cc-text-primary pb-6">
                {surplusDeficiency}
              </Typography>
            </div>
            <Typography className="font-light text-sm text-cc-text-primary">
              {daysDeficientTitle === ''
                ? 'Days Deficient'
                : daysDeficientTitle}
            </Typography>
            <div
              className={`text-2xl font-medium ${colorDeficient(
                daysDeficient
              )}`}
            >
              {daysDeficient === '0'
                ? daysDeficient
                : Number(daysDeficient).toFixed(0)}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default SurplusChart

const colorDeficient = (daysDeficient: string) => {
  if (daysDeficient === '-') {
    return ''
  }
  if (Number(daysDeficient) >= 15) {
    return 'text-danger-main'
  } else if (Number(daysDeficient) >= 5) {
    return 'text-warning-main'
  } else {
    return 'text-success-main'
  }
}
