import { OverviewDataResponse } from '@interfaces/analytics-risk'
import {
  FinancialsFilters,
  FinancialsStatementsRequest,
  StatementResponse,
} from '@interfaces/financial'

import apiClient from './client'

export class StatementService {
  static async getBalanceSheet(params: FinancialsFilters) {
    if (params?.entityId) {
      const { data } = await apiClient.get<StatementResponse>(
        '/financial/v1/balance-sheet',
        { params }
      )

      return data
    }
  }

  static async getPnL(params: FinancialsFilters) {
    if (params?.entityId) {
      const { data } = await apiClient.get<StatementResponse>(
        '/financial/v1/pnl-sheet',
        { params }
      )

      return data
    }
  }

  static async getFinancialsAvailableStatements(
    slugName?: string,
    legalEntityID?: string
  ) {
    if (slugName && legalEntityID) {
      const { data } = await apiClient.get<string[]>(
        `/financials/available-statements/${slugName}/${legalEntityID}`
      )

      return data
    }
  }

  static async getFinancialsBalanceSheet(params: FinancialsStatementsRequest) {
    if (params.legal_entity_id) {
      const { data } = await apiClient.get<StatementResponse>(
        `/financials/balance-sheet/${params.slug_name}/${params.legal_entity_id}?from_date=${params.from_date}&to_date=${params.to_date}&raw=${params.raw}&currency=${params.currency}`
      )

      return data
    }
  }

  static async getFinancialsCustomSheet(params: FinancialsStatementsRequest) {
    if (params.legal_entity_id) {
      const { data } = await apiClient.get<StatementResponse>(
        `/financials/custom-sheet/${params.slug_name}/${params.legal_entity_id}?from_date=${params.from_date}&to_date=${params.to_date}&currency=${params.currency}`
      )

      return data
    }
  }

  static async getOverview(params: any) {
    if (params?.entityId) {
      const { data } = await apiClient.get<OverviewDataResponse>(
        `/financials/total-assets-overview/${params.slug_name}/${params.entityId}`,
        { params }
      )

      return data
    }
  }

  static async getFinancialsPnL(params: FinancialsStatementsRequest) {
    if (params.legal_entity_id) {
      const { data } = await apiClient.get<StatementResponse>(
        `/financials/pnl-sheet/${params.slug_name}/${params.legal_entity_id}?from_date=${params.from_date}&to_date=${params.to_date}&raw=${params.raw}&currency=${params.currency}`
      )

      return data
    }
  }

  static async getFinancialsCashflow(params: FinancialsStatementsRequest) {
    if (params.legal_entity_id) {
      const { data } = await apiClient.get<StatementResponse>(
        `/financials/cashflow/${params.slug_name}/${params.legal_entity_id}?from_date=${params.from_date}&to_date=${params.to_date}&raw=${params.raw}&currency=${params.currency}`
      )

      return data
    }
  }
}
