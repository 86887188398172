import React, { useContext, useEffect, useState } from 'react'
import { parse } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'

import Button from '@components/atoms/button'
import FormInput from '@components/form/form-input'
import Table from '@components/table'
import { SortOrder } from '@components/table/type'
import AuthContext from '@contexts/auth'
import { UTCDate } from '@date-fns/utc'
import { useActiveFacility } from '@helpers/active-facility-hook'
import {
  ArrowPathIcon,
  CheckCircleIcon,
  PlusIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline'
import { ReportingSchedulesResult } from '@interfaces/manage-reporting'
import { Card, CardBody } from '@material-tailwind/react'
import { FacilityDetailsService } from '@services/api-manage/facility-details'

import { OptionsMenu } from './options-menu'

const frequencyOptions = [
  'Daily',
  'Weekly',
  'Bi-weekly',
  'Monthly',
  'Month-end',
  'Quarterly',
  'Bi-annual',
  'Annual',
]

export const ReportingScheduleAdmin = () => {
  const { company } = useContext(AuthContext)
  const [sortField, setSortField] = useState<string>('status')
  const [sortOrder, setSortOrder] = useState<SortOrder>('asc')
  const [dataArray, setDataArray] = useState<ReportingSchedulesResult[]>([])
  const activeFacility = useActiveFacility()

  const {
    formState: { errors, isValid },
    getValues,
    handleSubmit,
    register,
    reset,
    setValue,
  } = useForm()

  const params = {
    facility: activeFacility?.facility_name,
    slug_name: company?.slug_name ?? '',
    facility_id: activeFacility?.facility_id,
  }

  const { data, isLoading, refetch } = useQuery(
    ['getReportingScheduleRequest', params],
    () => FacilityDetailsService.getReportingScheduleRequest(params),
    {
      enabled: !!company && !!activeFacility,
    }
  )
  useEffect(() => data && setDataArray(data), [data])

  const { mutate: updateReportingSchedule, isLoading: createLoading } =
    useMutation(
      (values: ReportingSchedulesResult) =>
        FacilityDetailsService.updateReportingScheduleRequest({
          ...params,
          entry: values,
        }),
      {
        onSuccess: () => {
          refetch()
          reset()
        },
      }
    )

  const { mutate: deleteReportingSchedule, isLoading: deleteLoading } =
    useMutation(
      (id: string) =>
        FacilityDetailsService.deleteReportingScheduleRequest({
          ...params,
          id: id,
        }),
      {
        onSuccess: () => {
          refetch()
          reset()
        },
      }
    )

  const onSubmit = (values: any) => {
    if (values.interval_ref_date) {
      values.interval_ref_date = formatInTimeZone(
        values.interval_ref_date,
        'UTC',
        'yyyy-MM-dd'
      )
    }
    updateReportingSchedule(values)
  }

  const handleEdit = (id: string) => {
    const entry = dataArray.find(e => e.id === id)
    if (!entry) {
      return
    }
    Object.keys(entry).forEach((key: string) => {
      if (key === 'interval_ref_date') {
        setValue(
          key,
          parse(
            entry.interval_ref_date.slice(0, 10),
            'yyyy-MM-dd',
            new UTCDate()
          ),
          {
            shouldValidate: true,
          }
        )
      } else if (key === 'grace_period') {
        setValue(key, Number(entry.grace_period), { shouldValidate: true })
      } else {
        setValue(key, entry[key as keyof ReportingSchedulesResult], {
          shouldValidate: true,
        })
      }
    })
  }

  const cancelEdit = () => {
    if (dataArray?.length && !dataArray[dataArray.length - 1].id) {
      setDataArray(dataArray.slice(0, dataArray.length - 1))
    }
    reset()
  }

  const isWorking = createLoading || deleteLoading

  return (
    <Card className="rounded-md h-full w-full">
      <CardBody className="flex flex-col items-left ">
        <div className="flex justify-between w-full">
          <div className="text-lg font-bold">Reporting Schedule</div>
          <div className="flex gap-2">
            <button
              className="flex justify-center items-center w-24 h-10 py-1 mb-2 text-sm font-medium cursor-pointer rounded-lg bg-primary-main hover:bg-primary-hover text-white transition ease-in-out delay-50 duration-300"
              onClick={() => {
                setValue('interval_ref_date', new Date(), {
                  shouldValidate: true,
                })
                setDataArray([...dataArray, {} as ReportingSchedulesResult])
              }}
              disabled={
                isWorking ||
                (!!dataArray?.length &&
                  (!dataArray[dataArray.length - 1].id || !!getValues('id')))
              }
            >
              <PlusIcon className="w-4 h-4 mr-2 -ml-2" />
              Add
            </button>
            <Button
              color="default"
              className="mb-2 "
              onClick={() => {
                refetch()
              }}
            >
              <ArrowPathIcon
                className={`${isLoading ? 'animate-spin' : ''} w-4 h-4 `}
              />
            </Button>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Table
            loading={isLoading}
            columns={[
              {
                title: 'Category',
                field: 'doc_name',
                sortable: true,
                render: entry =>
                  !entry.id || entry.id === getValues('id') ? (
                    <FormInput
                      type="text"
                      isDisabled={isWorking}
                      value={getValues('doc_name')}
                      {...register('doc_name', {
                        required: 'Document name is required',
                        minLength: 1,
                        maxLength: 2048,
                        onChange: (e: any) => {
                          setValue('doc_name', e.target.value, {
                            shouldValidate: true,
                          })
                        },
                        onBlur: (e: any) => {
                          setValue('doc_name', e.target.value, {
                            shouldValidate: true,
                          })
                        },
                      })}
                      error={errors?.doc_name?.message as string}
                    />
                  ) : (
                    entry.doc_name
                  ),
              },
              {
                title: 'Target',
                field: 'target',
                sortable: true,
                render: entry =>
                  !entry.id || entry.id === getValues('id') ? (
                    <FormInput
                      type="text"
                      isDisabled={isWorking}
                      value={getValues('target')}
                      {...register('target', {
                        required: 'Target is required',
                        minLength: 1,
                        maxLength: 2048,
                        onChange: (e: any) => {
                          setValue('target', e.target.value, {
                            shouldValidate: true,
                          })
                        },
                        onBlur: (e: any) => {
                          setValue('target', e.target.value, {
                            shouldValidate: true,
                          })
                        },
                        validate: value => {
                          if (
                            value[0] !== '[' ||
                            value[value.length - 1] !== ']'
                          ) {
                            return 'Value should be wrapped in square brackets'
                          }
                          return true
                        },
                      })}
                      error={errors?.target?.message as string}
                    />
                  ) : (
                    entry.target
                  ),
              },
              {
                title: 'Frequency',
                field: 'frequency',
                sortable: true,
                width: '130px',
                render: entry =>
                  !entry.id || entry.id === getValues('id') ? (
                    <FormInput
                      type="select"
                      value={getValues('frequency')}
                      {...register('frequency', {
                        required: 'Frequency is required',
                      })}
                      onSelected={val => {
                        setValue('frequency', val, {
                          shouldValidate: true,
                        })
                      }}
                      options={frequencyOptions.map(a => ({
                        value: a,
                        title: a,
                      }))}
                      isDisabled={isWorking}
                      error={errors?.frequency?.message as string}
                    />
                  ) : (
                    entry.frequency
                  ),
              },
              {
                title: 'Grace Period (days)',
                field: 'grace_period',
                width: '80px',
                sortable: true,
                render: entry =>
                  !entry.id || entry.id === getValues('id') ? (
                    <FormInput
                      type="number"
                      isDisabled={isWorking}
                      value={getValues('grace_period')}
                      {...register('grace_period', {
                        required: `Grace period is required`,
                        onChange: e => {
                          setValue('grace_period', e.target.value, {
                            shouldValidate: true,
                          })
                        },
                        onBlur: e => {
                          setValue('grace_period', e.target.value, {
                            shouldValidate: true,
                          })
                        },
                      })}
                      {...{ step: '1' }}
                      error={errors?.amount?.message as string}
                    />
                  ) : (
                    entry.grace_period
                  ),
              },
              {
                title: 'Start Date',
                field: 'interval_ref_date',
                sortable: true,
                render: entry =>
                  !entry.id || entry.id === getValues('id') ? (
                    <FormInput
                      type="date"
                      isDisabled={isWorking}
                      value={getValues('interval_ref_date')}
                      onSelected={(val: any) => {
                        setValue('interval_ref_date', val, {
                          shouldValidate: true,
                        })
                      }}
                      error={errors?.interval_ref_date?.message as string}
                    />
                  ) : (
                    formatInTimeZone(
                      new Date(entry.interval_ref_date),
                      'UTC',
                      'yyyy-MM-dd'
                    )
                  ),
              },
              {
                title: 'Enabled',
                field: 'enabled',
                sortable: true,
                width: '50px',
                render: entry =>
                  !entry.id || entry.id === getValues('id') ? (
                    <FormInput
                      type="checkbox"
                      isDisabled={isWorking}
                      value={getValues('enabled')}
                      {...register('enabled', {
                        onChange: value => {
                          setValue('enabled', value.currentTarget.checked, {
                            shouldValidate: true,
                          })
                        },
                        onBlur: value => {
                          setValue('enabled', value.currentTarget.checked, {
                            shouldValidate: true,
                          })
                        },
                      })}
                    />
                  ) : (
                    <div className="flex item-center justify-center width-full">
                      {entry.enabled ? (
                        <CheckCircleIcon className="w-6 h-6 text-green" />
                      ) : (
                        <XCircleIcon className="w-6 h-6 text-red" />
                      )}
                    </div>
                  ),
              },
              {
                title: 'Actions',
                field: 'id',
                width: '64.5px',
                render: entry => (
                  <OptionsMenu
                    id={entry.id}
                    edit={entry.id === getValues('id')}
                    disableMenu={
                      isWorking || !dataArray[dataArray.length - 1].id
                    }
                    disableButtons={isWorking}
                    disableSave={!isValid}
                    handleEdit={handleEdit}
                    handleDelete={deleteReportingSchedule}
                    handleCancel={cancelEdit}
                  />
                ),
              },
            ]}
            data={dataArray}
            onSort={(field: string, order: SortOrder) => {
              setSortField(order != '' ? field : 'status')
              setSortOrder(order != '' ? order : 'asc')
            }}
            sortField={sortField}
            sortOrder={sortOrder}
          />
        </form>
      </CardBody>
    </Card>
  )
}
