import { toast } from 'react-toastify'

interface ErrorStatusTypes {
  [key: string]: string
}

const errorStatus: ErrorStatusTypes = {
  '400': 'Bad Request',
  '401': 'Access denied',
  '413': 'Upload size(s) too large',
}
const defaultErrorMessage = 'An unexpected error has occured'

/** Custom Error message if needed, however the weight of a custom
 * message is below that of a response status since response
 * status is more detailed than Error message
 * */
export const useToastNotification = () => {
  /**
   * Handles different logic for various error types
   * @param status incoming error status type
   * @param cusomMessage optional custom message if you want
   */
  const displayToastError = (
    status?: number,
    customMessage?: string | JSX.Element,
    autoClose: number | false = 5000
  ): void => {
    const errorMessage =
      errorStatus?.[status?.toString() ?? ''] ??
      customMessage ??
      defaultErrorMessage
    toast.error(errorMessage, {
      autoClose: autoClose,
      pauseOnHover: true,
      draggable: true,
    })
  }

  const displayToastSuccess = (
    customMessage: string,
    autoClose: number | false = 3000
  ): void => {
    toast.success(customMessage, {
      autoClose: autoClose,
      pauseOnHover: true,
      draggable: true,
    })
  }
  return {
    displayToastSuccess,
    displayToastError,
  }
}
