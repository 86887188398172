import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useMutation } from 'react-query'
import * as XLSX from 'xlsx'

import Button from '@components/atoms/button'
import Typography from '@components/atoms/typography'
import AuthContext from '@contexts/auth'
import {
  ArrowPathIcon,
  CheckCircleIcon,
  ExclamationTriangleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import {
  CovenantListResponse,
  CovenantType,
} from '@interfaces/manage-monitor-covenant'
import { FacilityFilter } from '@interfaces/manage-monitor-filter-facility'
import {
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from '@material-tailwind/react'
import { CovenantService } from '@services/api-manage/monitor-covenant'

import { useCovenantList } from '../helper'

const ExportDialogComponent = ({
  type,
  list,
}: {
  type: CovenantType
  list: CovenantListResponse[]
}) => {
  const [isDownloading, setIsDownloading] = useState<boolean>(false)
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(!open)
  }

  const { company, appliedFilters, optionFilters } = useContext(AuthContext)
  const { activeFacilityId } = appliedFilters
  const { facilities = [] } = optionFilters
  const appliedFacility = facilities?.find(
    (f: FacilityFilter) => f.facility_id === activeFacilityId
  )

  const query = (c?: CovenantListResponse) => {
    const filters = {
      slug_name: company?.slug_name ?? '',
      facility_id: activeFacilityId,
      type,
      id: c?.covenant_id ?? 0,
    }
    return useMutation({
      mutationKey: ['covenant-raw', filters],
      mutationFn: () =>
        c
          ? CovenantService.getRaw(filters)
          : new Promise(resolve => resolve([])),
    })
  }

  /**
   * this is to prevent different number of hook mapping
   * by fix array number
   */
  const prelist = [...new Array(100)].map((_, i) => list[i])

  const parts = prelist.map(l => ({ ...l, ...query(l) }))

  const currentPart: any = parts.find((p: any) => p.isIdle)

  const prepareExport = () => {
    if (open && currentPart) {
      currentPart?.mutate()
    }
  }

  useEffect(() => {
    if (open) {
      prepareExport()
    } else {
      setIsDownloading(false)
    }
  }, [open, currentPart])

  const isDownloadReady = !currentPart
  const _download = async () => {
    setIsDownloading(true)

    const workbook = XLSX.utils.book_new()

    parts.forEach(p => {
      const content = ((p.data as any[]) ?? []).map((d: any) => {
        const processedData = Object.keys(d).reduce((prev, cur) => {
          return {
            ...prev,
            [cur.toUpperCase().split('_').join(' ')]: d[cur],
          }
        }, {})

        //the columns that need to get exported need to be updated so that the new column shows up in the right place
        //this section could be less costly if the new column was added just to the end of the excel columns
        const updatedData = {}
        for (let i = 0; i < Object.keys(processedData).length; i++) {
          const key = Object.keys(processedData)[i]
          updatedData[key] = processedData[key]

          //add "BREACH / NO BREACH" column right after "TRIGGER"
          if (key === 'TRIGGER') {
            updatedData['BREACH / NO BREACH'] =
              processedData[key] > 0 ? 'Breach' : 'No Breach'
          }
        }

        return updatedData
      })

      if ((content ?? []).length > 0) {
        const worksheet = XLSX.utils.json_to_sheet(content ?? [])

        //generate a valid sheet name
        const rawSheetName = `${p.covenant_id} - ${
          p.covenant_name.length > 25
            ? p.covenant_name
                .split(' ')
                .map(x => x.charAt(0))
                .join('')
            : p.covenant_name
        }`

        //sanitize sheet name based on excel rules
        const sanitizedSheetName = rawSheetName
          .replace(/[:\\/?*[\]]/g, '') //remove invalid characters
          .trim() //remove leading and trailing whitespace
          .substring(0, 31) //truncate to 31 characters

        XLSX.utils.book_append_sheet(
          workbook,
          worksheet,
          sanitizedSheetName || 'Sheet1'
        )
      }
    })

    await XLSX.writeFile(
      workbook,
      `${company?.legal_name} ${type} Covenants - ${
        appliedFacility?.facility_name
      } - ${moment().toString()}.xlsx`
    )

    setIsDownloading(false)
  }

  return (
    <>
      <Button
        className="!outline-none h-[40px] w-full border border-neutral-border-2 text-neutral-body-2 py-2 px-6 hover:bg-neutral-white hover:text-neutral-black"
        onClick={handleOpen}
      >
        <Typography className="capitalize font-medium text-sm">
          Export
        </Typography>
      </Button>
      <Dialog open={open} handler={() => undefined}>
        <DialogHeader className="justify-between">
          <Typography className="capitalize font-medium text-xl">
            {`Export ${type} Covenants`}
          </Typography>
          <XMarkIcon
            onClick={handleOpen}
            className="w-6 h-6 cursor-pointer hover:opacity-50"
          />
        </DialogHeader>
        <DialogBody divider className="flex flex-col p-6">
          <Typography className="text-sm text-center font-bold">
            Please DO NOT close this dialog or the browser tab until exporting
            is complete
          </Typography>
          <div className="mt-8 flex flex-col gap-4 max-h-[50vh] overflow-y-auto">
            {parts
              .filter(x => x.covenant_name)
              .map((p: any) => {
                return (
                  <div
                    key={p.label}
                    className="rounded-lg bg-neutral-border-1 px-4 py-2 flex gap-4 justify-between items-center"
                  >
                    <Typography className="capitalize text-sm flex-1">
                      {p.covenant_name}
                    </Typography>
                    {p.isLoading && (
                      <ArrowPathIcon className="animate-spin text-primary-main w-4 h-4" />
                    )}
                    {!p.isLoading && p.isSuccess && (
                      <CheckCircleIcon className="text-primary-main w-4 h-4" />
                    )}
                    {!p.isLoading && !p.isSuccess && (
                      <ExclamationTriangleIcon className="text-primary-main w-4 h-4" />
                    )}
                  </div>
                )
              })}
          </div>
        </DialogBody>
        <DialogFooter>
          <Button
            onClick={_download}
            disabled={!isDownloadReady || isDownloading}
            className="!shadow-none border py-2 px-6 border-primary-main text-primary-main hover:bg-primary-main hover:text-neutral-white disabled:border-neutral-border-3 disabled:bg-neutral-border-2 disabled:text-neutral-border-3"
          >
            {isDownloading && (
              <ArrowPathIcon className="w-4 h-4 mr-4 text-primary-main animate-spin" />
            )}
            Download
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  )
}

const CovenantExport = ({ type }: { type: CovenantType }) => {
  const { data = [], isLoading } = useCovenantList({ type })

  const [t, setT] = useState<CovenantType | undefined>()

  useEffect(() => {
    setT(undefined)
    setTimeout(() => {
      setT(type)
    }, 1000)
  }, [type])

  return isLoading || data?.length === 0 || t !== type ? (
    <></>
  ) : (
    <ExportDialogComponent type={t} list={t ? data : []} />
  )
}

export default CovenantExport
