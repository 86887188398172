import { useContext, useEffect, useState } from 'react'
import { endOfMonth, format, parse, sub } from 'date-fns'
import { Moment } from 'moment'

import AuthContext from '@contexts/auth'
import { UTCDate } from '@date-fns/utc'

const getLatestEndOfMonth = (effectiveDate?: Date) => {
  const currentDate = effectiveDate ?? new Date()
  if (format(currentDate, 'dd') === format(endOfMonth(currentDate), 'dd')) {
    return currentDate
  } else {
    return endOfMonth(sub(currentDate, { months: 1 }))
  }
}

export const useVariantReportData = (effectiveDate?: Moment) => {
  const { appliedFilters, company } = useContext(AuthContext)
  const { activeFacilityId } = appliedFilters

  const [data, setData] = useState<any>([true, {}])

  const effectiveDateAsDate = effectiveDate
    ? parse(effectiveDate.format('YYYY-MM-DD'), 'yyyy-MM-dd', new UTCDate())
    : undefined

  const date = getLatestEndOfMonth(effectiveDateAsDate)

  const fileName = `${company?.slug_name}_month_end_report-${format(
    date,
    'MM.yyyy'
  )}(${Date.now()}).xlsx`

  useEffect(() => {
    setData([
      false,
      {
        fileUrl: `${window.location.origin}/manage/monitor/actions?pid=${company?.slug_name}&facility=${activeFacilityId}&tab=document-centre&fileName=${fileName}`,
        excelUploadCategory: 'Month-end Report',
        excelUploadTitle: `Month-end Report - ${format(date, 'dd MMMM yyyy')}`,
        effectiveDate: format(date, 'yyyy-MM-dd'),
      },
    ])
  }, [])

  return data
}
