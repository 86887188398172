/**
 * List of availables features
 * Structured in L1_L2_L3
 *
 * User features should be set in auth.custom_user_roles in the cascade-stack DB.
 * sample:
 * "features": [
 *      "analytics_risk_traction"
 * ]
 * Otherwise fallback into this
 */
export default [
  'analytics_risk_overview',
  'analytics_risk_indicators',
  'analytics_risk_traction',
  'analytics_risk_delinquency',
  'analytics_risk_collection',
  'analytics_risk_cohort',
  'analytics_risk_characteristics',
  'analytics_risk_curves',
  'analytics_data-exports_loan-tape',
  'analytics_financials_overview',
  'analytics_financials_key-indicators',
  'analytics_financials_statements',

  'manage_monitor_overview',
  'manage_monitor_dashboard',
  'manage_monitor_borrowing-base',
  'manage_monitor_portfolio-covenants',
  'manage_monitor_financial-covenants',
  'manage_monitor_cashflows',
  'manage_monitor_actions',
  'manage_audit_payment-audit',
  'manage_audit_underwriting-audit',

  'administration_sources_banking',
  'administration_sources_accounting',
  'administration_sources_uploads',
  'administration_setting_users',
  'administration_setting_accounting-config',

  'data-validation_quarantine_summary',
  'data-validation_quarantine_details',

  'superadmin_manage-admin_facility-details',

  'notification_notifications_logs',

  'analytics-pro_categories_charts',
]

/*
 * Update as Feature flag requirements grow.
 * flag should be equal to the env variable set for API
 * control to be false by default.
 *
 * An example entry - note that paths must line
 * up exactly with those built up in routes.tsx:
 *
 * {
 *   path: 'administration_setting_accounting-config',
 *   flag: 'FEATURE_ADMIN_ACCOUNTING_ENABLED',
 *   control: false,
 * },
 */
export const initialFeatureFlagMapping: {
  path: string
  permissionPrefix: string
  flag: string
  control: boolean
}[] = [
  {
    path: 'data-validation_quarantine_summary',
    permissionPrefix: 'data-validation_quarantine_summary',
    flag: 'FEATURE_SLUG_BASED_DATA_VALIDATION_ENABLED',
    control: false,
  },
  {
    path: 'data-validation_quarantine_details',
    permissionPrefix: 'data-validation_quarantine_details',
    flag: 'FEATURE_SLUG_BASED_DATA_VALIDATION_ENABLED',
    control: false,
  },
  {
    path: 'notification_notifications',
    permissionPrefix: 'notification_notifications',
    flag: 'FEATURE_EMAIL_BASED_NOTIFICATIONS_ENABLED',
    control: false,
  },
  {
    path: 'analytics-pro',
    permissionPrefix: 'analytics-pro',
    flag: 'FEATURE_ANALYTICS_PRO_ENABLED',
    control: false,
  },
]
