import React from 'react'

import { ReportingCategoriesTable } from './reporting-categories-table'

export const ReportingCategoriesAdmin = () => (
  <div className="flex flex-col gap-6 justify-center">
    <ReportingCategoriesTable action="create" />
    <ReportingCategoriesTable action="upload" />
  </div>
)
