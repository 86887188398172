import {
  CategoriesResult,
  DeleteCategoryFilters,
  DocumentsResult,
  GetDocByIdParams,
  ListCategoriesFilters,
  ReportingDocFilters,
  UpdateCategoryFilters,
} from '@interfaces/manage-reporting'
import { SignatureRequestFilters } from '@interfaces/manage-signature'

import manageApiClient from './client'

class DocumentService {
  static async getSignedDocsInfo(filters: SignatureRequestFilters) {
    if (filters?.slug_name && filters?.facility_id) {
      return await manageApiClient.post(`doc-centre/list-filtered`, filters, {})
    }
    throw new Error('Company and facility are required!')
  }

  static async getOrderedReporting(filters: ReportingDocFilters) {
    const { data } = await manageApiClient.post<DocumentsResult[]>(
      `doc-centre/order-by-column`,
      filters,
      {}
    )
    return data
  }

  static async getDocById(params: GetDocByIdParams) {
    const { slug, facilityName, docId } = params
    const { data } = await manageApiClient.get(
      `doc-centre/${slug}/document/${facilityName}/${docId}`
    )
    return data
  }

  static async voidDoc(id: string, slug_name?: string) {
    return await manageApiClient.post(
      `doc-centre/void-doc`,
      { slug_name, sig_request_id: id },
      {}
    )
  }
  static async uploadDocument(formData: FormData) {
    const { data } = await manageApiClient.post<any[]>(
      `/doc-centre/upload-file`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data;',
        },
      }
    )
    return data
  }

  static async getPresignedUrl(
    docData: { Bucket: any; Key: any },
    slug_name: string,
    facility_id: number
  ) {
    return manageApiClient.post(`doc-centre/presigned-url`, {
      slug_name,
      facility_id,
      bucket: docData.Bucket,
      key: docData.Key,
    })
  }

  static async postNoteOnDoc(payload: {
    note: string
    userId: string
    docId: string
  }) {
    return manageApiClient.post(`notes`, {
      note: payload.note,
      user_id: payload.userId,
      doc_id: payload.docId,
    })
  }

  static async getNotesOnDoc(id: string) {
    return manageApiClient.get(`notes/${id}`)
  }

  static async deleteNote(id: string) {
    return manageApiClient.delete(`notes/${id}`)
  }

  static async updateNote(payload: any) {
    return manageApiClient.post(`notes/update`, {
      ...payload,
    })
  }

  static async listCategories(params: ListCategoriesFilters) {
    const { data } = await manageApiClient.post<CategoriesResult[]>(
      `doc-centre/list-categories`,
      params
    )
    data.sort((a, b) => a.category.localeCompare(b.category))
    return data
  }

  static async updateCategory(params: UpdateCategoryFilters) {
    const { data } = await manageApiClient.post(
      `doc-centre/update-category`,
      params
    )
    return data
  }

  static async deleteCategory(params: DeleteCategoryFilters) {
    const { data } = await manageApiClient.post(
      `doc-centre/delete-category/`,
      params
    )
    return data
  }
}

export default DocumentService
