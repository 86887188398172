import { capitalizeWords } from '@helpers/string-formatter'
import { AllTableData, TableData } from '@interfaces/manage-reporting'

const getTableTemplate = (rowData: Record<string, any>) => {
  const columnKeys = Object.keys(rowData)
  return {
    tableWidth: '100%',
    columns: columnKeys.map((key: string, idx: number) => ({
      key: key,
      title: capitalizeWords(key.replaceAll('_', ' ')),
      width: `${100 / columnKeys.length}%`,
      position: idx === 0 ? 'center' : 'right',
    })),
  }
}

export const generateTables = (data: any[]) => {
  const tables = []

  const summaryTableNumberOfColumns =
    data?.length > 0 ? Math.max(...data.map(x => x.position)) + 1 : 0

  const parseTable = () => {
    const res: TableData[][] = []
    data.map(row => {
      if (row.detail_data.length) {
        const tableTemplate = getTableTemplate(row.detail_data[0])
        const subtable = {
          title: row.display_name,
          tableWidth: tableTemplate.tableWidth,
          key: row.display_name.toLowerCase().replace(' ', '-'),
          titleRow: tableTemplate.columns.map(c => ({
            content: c.title,
            width: c.width,
          })),
          wrap: true,
          data: row.detail_data.map((subtableRow: any) => {
            return tableTemplate.columns.map(c => ({
              content: subtableRow[c.key],
              width: c.width,
              position: c.position,
              bold:
                subtableRow[tableTemplate.columns[0].key]?.toLowerCase() ===
                'total',
            }))
          }),
        }
        tables.push(subtable)
      }
      if (row.display_value !== null) {
        const rowRes = new Array(summaryTableNumberOfColumns)
          .fill('')
          .map((_r, index) => {
            return {
              content:
                index === 0
                  ? row.display_name
                  : row.position === index
                  ? row.display_value
                  : '',
              width: `${100 / summaryTableNumberOfColumns}%`,
              position: index === 0 ? ('left' as const) : ('right' as const),
              bold: row.highlighted,
              separator: row.separator,
            }
          })
        res.push(rowRes)
      }
    })
    return res
  }

  const summaryTable: AllTableData = {
    title: 'Summary',
    key: 'summary',
    tableWidth: '100%',
    titleRow: [
      {
        content: 'USD',
        width: '100%',
      },
    ],
    wrap: true,
    data: parseTable(),
  }

  tables.unshift(summaryTable)
  return tables
}
