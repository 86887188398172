import React, { useContext, useEffect, useState } from 'react'

import Chart from '@components/chart'
import MultiToggleLayout from '@components/layouts/multi-toggle/multi-toggle-layout'
import { REMOUNT_MS } from '@constants/config'
import AuthContext from '@contexts/auth'
import { GetAnalyticsProResponseDTO } from '@interfaces/analytics-pro'

import { AnalyticsDataBuilder } from '../analytics-chart-data-builder'

import AnalyticsProTableItem from './table'

const AnalyticsProStackedAreaItem = ({
  data,
  showTableSwitch,
}: {
  data: GetAnalyticsProResponseDTO
  showTableSwitch: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
}) => {
  const { appliedFilters, optionFilters } = useContext(AuthContext)
  const [isProcessing, setIsProcessing] = useState<boolean>(false)

  const { activeType, calculationDate } = appliedFilters
  const { categoryTypeOptions = [] } = optionFilters

  const builder = new AnalyticsDataBuilder(
    data,
    calculationDate,
    activeType,
    categoryTypeOptions
  )
  const {
    chartSeries,
    unit,
    filteredChartData,
    xSetting,
    xAxisType,
    yAxisLabel,
    yAxisFormat,
    title,
    id,
    xAxisLabel,
    ySetting,
    tableData,
    tableColumns,
  } = builder.build()

  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [data])

  return (
    <MultiToggleLayout
      overrideSwitch={showTableSwitch}
      toggles={[]}
      disableTooltip={true}
      chart={
        <Chart
          data={filteredChartData}
          series={chartSeries}
          loading={isProcessing}
          yLabel={`${yAxisLabel} ${unit ? `(${unit})` : ''}`}
          yFormat={yAxisFormat ?? '#.00a'}
          xSetting={xSetting}
          ySetting={ySetting}
          xLabel={xAxisLabel}
          id={`${title}-${id}`}
          exportable={false}
          xAxisType={xAxisType}
        />
      }
      toggleData={
        <AnalyticsProTableItem
          tableData={tableData}
          tableColumns={tableColumns}
          stickyLeftCount={1}
        />
      }
    />
  )
}
export default AnalyticsProStackedAreaItem
