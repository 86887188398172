import React, { useContext, useEffect, useState } from 'react'
import { format } from 'date-fns'

import Typography from '@components/atoms/typography'
import Chart from '@components/chart'
import { REMOUNT_MS } from '@constants/config'
import AuthContext from '@contexts/auth'
import { DashboardResponse } from '@interfaces/manage-monitor-dashboard'

import { ManageDataBuilder } from '../manage-chart-data-builder'

const MonitorDashboardGroupedGraphItem = ({
  item,
}: {
  item: DashboardResponse
}) => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  const { appliedFilters } = useContext(AuthContext)
  const { calculationDate } = appliedFilters

  const builder = new ManageDataBuilder(item, calculationDate)
  const {
    filteredChartData,
    chartSeries,
    unit,
    xAxisType,
    xAxisLabel,
    displayName,
    yAxisLabel,
    secondaryYAxisConfig,
    yAxisFormat,
    dashboardId,
    title,
    xSetting,
    ySetting,
    dateFilterField,
  } = builder.build()

  /** simulate processing to remount chart component when filters change */
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [appliedFilters])

  return (
    <>
      {displayName && (
        <Typography className="text-center text-lg font-semibold">
          {displayName}{' '}
          {dateFilterField &&
            calculationDate &&
            `as of ${format(calculationDate, 'yyyy-MM-dd')}`}
        </Typography>
      )}
      <Chart
        data={filteredChartData}
        series={chartSeries}
        loading={isProcessing}
        yLabel={`${yAxisLabel} ${unit ? `(${unit})` : ''}`}
        secondaryYConfig={secondaryYAxisConfig}
        yFormat={yAxisFormat}
        ySetting={ySetting}
        xSetting={{
          renderer: {
            cellStartLocation: 0.1,
            cellEndLocation: 0.9,
          },
          ...xSetting,
        }}
        xLabel={xAxisLabel}
        xAxisType={xAxisType}
        id={`${title}-${dashboardId}`}
        exportable={false}
      />
    </>
  )
}
export default MonitorDashboardGroupedGraphItem
