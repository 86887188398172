import React, { useContext } from 'react'
import { format } from 'date-fns'

import Typography from '@components/atoms/typography'
import MultiLevelTable from '@components/multi-level-table'
import AuthContext from '@contexts/auth'
import { DashboardResponse } from '@interfaces/manage-monitor-dashboard'

import { ManageDataBuilder } from '../manage-chart-data-builder'

const MonitorDashboardNumberTableItem = ({
  item,
}: {
  item: DashboardResponse
}) => {
  const { appliedFilters } = useContext(AuthContext)
  const { calculationDate, currency } = appliedFilters

  const builder = new ManageDataBuilder(item, calculationDate)
  const { numberTableColumns, numberTableData, displayName, dateFilterField } =
    builder.build()

  return (
    <div className="h-full">
      {item.external_name && (
        <Typography className="text-center text-lg font-semibold">
          {displayName}{' '}
          {dateFilterField &&
            calculationDate &&
            `as of ${format(calculationDate, 'yyyy-MM-dd')}`}
        </Typography>
      )}
      <div className="flex flex-col overflow-auto h-full">
        <MultiLevelTable
          dataSet={{ cols: numberTableColumns, data: numberTableData }}
          currency={currency}
          defaultCurrency={currency}
          hasCustomStyling={true}
        />
      </div>
    </div>
  )
}
export default MonitorDashboardNumberTableItem
