import {
  CovenantDataBuilder,
  CovenantType,
} from '@helpers/covenant-data-builder'
import { GetAnalyticsProResponseDTO } from '@interfaces/analytics-pro'

export class AnalyticsCovenantDataBuilder extends CovenantDataBuilder {
  constructor(
    setSelectedCohort: React.Dispatch<React.SetStateAction<string>>,
    selectedCohort: string,
    covenant: GetAnalyticsProResponseDTO
  ) {
    super(
      CovenantType.ANALYTICS_PRO,
      setSelectedCohort,
      selectedCohort,
      covenant
    )

    this.withCohortInterval(this.data.x_axis_field[1])
    this.withCohortColumnName(this.data.column)
    this.withInvertedColours(this.covenant.invert_colours)
    this.withSpecialSeriesLabels(
      this.data.special_series_label?.split(',') ?? []
    )
    this.withSpecialSeriesFields(
      this.data.special_series_field?.split(',') ?? []
    )
  }
}
