import React from 'react'
import { format, parse } from 'date-fns'

import { UTCDate } from '@date-fns/utc'
import { formatCurrency } from '@helpers/number-formatter'
import { WaterfallTemplateProps } from '@interfaces/manage-reporting'
import { Document, Page, Text, View } from '@react-pdf/renderer'

import { Link } from '../../../document-centre/templates/components'
import { styles } from '../report-template-style'

const table1Rows = [
  {
    number: 'FIRST',
    description:
      ', on a pari passu basis, (i) to the Servicer (so long as neither Seller nor any of its Affiliates is the Servicer), any Servicing Fees and any expenses reimbursable under the Servicing Agreement (including, any Servicing Expenses or Other Expenses) not previously paid or reimbursed for the subject Remittance Period, (ii) to the Custodian (so long as neither Seller nor any of its Affiliates is the Custodian), any Custodial Fees and any expenses reimbursable under the Servicing Agreement or the Backup Servicing Agreement, as applicable, not previously paid or reimbursed, (iii) to any Backup Servicer, if any, any Backup Servicer Fees and any expenses reimbursable under the Backup Servicing Agreement not previously paid or reimbursed, each for the subject Remittance Period, and (iv) to Account Bank, any fees or expenses reimbursable under the Deposit Account Control Agreement not previously paid or reimbursed;',
  },
  {
    number: 'SECOND',
    description:
      ', after the occurrence and continuance of an Event of Default or a Default, the amount necessary to repay the Aggregate Outstanding Advances until the Aggregate Outstanding Advances have been reduced to zero;',
  },
  {
    number: 'THIRD',
    description:
      ', to Agent, for the benefit of Lenders, an amount equal to any Borrowing Base Deficiency;',
  },
  {
    number: 'FOURTH',
    description:
      ', to Agent, for the benefit of Lenders, any accrued and unpaid interest on the Aggregate Outstanding Advances;',
  },
  {
    number: 'FIFTH',
    description:
      ', to the Servicer (to the extent Seller or any of its Affiliates is the Servicer), any Servicing Fees and any expenses reimbursable under the Servicing Agreement (including, any Servicing Expenses or Other Expenses) not previously paid or reimbursed for the subject Remittance Period;',
  },
  {
    number: 'SIXTH',
    description:
      ', to Agent, for the benefit of Lenders, reimbursement of any and all reasonable fees, out of pocket costs and expenses associated with its administration and enforcement of, and any amendments made or waivers granted with respect to, this Agreement or the Loan Documents (including the Additional Amounts);',
  },
  {
    number: 'SEVENTH',
    description:
      ', during the Revolving Period, to Borrower, any remaining Collections remaining after application of such amounts pursuant to caluses first through sixth above;',
  },
  // {
  //   number: 'EIGHTH',
  //   description:
  //     ', during the period beginning on the termination of the Revolving Period, to Agent, for the benefit of Lenders until the Aggregate Outstanding Advances have been reduced to zero, any Collections remaining after application of such amounts pursuant to clauses first through seventh above; and',
  // },
  // {
  //   number: 'NINTH',
  //   description:
  //     ', to Borrower, any Collections remaining after application of such amounts pursuant to clauses first through eighth above.',
  // },
]

const SpecialtyCapitalWaterfallTemplate = ({
  data,
  date,
  facility,
  formData,
  customData,
}: WaterfallTemplateProps) => {
  // table1Values directly relate to the index of table1Rows above
  const table1Values = [
    Number(data[1]?.value ?? '0'),
    Number(data[2]?.value ?? '0'),
    Number(data[3]?.value ?? '0'),
    Number(data[4]?.value ?? '0'),
    Number(data[5]?.value ?? '0'),
    Number(data[6]?.value ?? '0'),
    Number(data[7]?.value ?? '0'),
    Number(data[8]?.value ?? '0'),
    Number(data[9]?.value ?? '0'),
  ]

  const textStyle = { ...styles.text, fontSize: 10 }

  return (
    <Document>
      <Page style={styles.body}>
        <Text style={{ ...styles.textBold, fontSize: 16 }}>
          SPECIALTY CAPITAL SPV I, LLC
        </Text>
        <View
          style={{
            width: '100%',
            backgroundColor: '#E7E6E6',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Text style={{ ...styles.textBold, fontSize: 14 }}>
            Remittance Report
          </Text>
          <View
            style={{
              width: '35%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <Text style={styles.textBold}>Dated:</Text>
            <Text style={{ ...styles.text, marginLeft: 30 }}>
              {format(parse(date, 'do MMMM yyyy', new UTCDate()), 'yyyy-MM-dd')}
            </Text>
          </View>
        </View>
        <Text style={{ ...textStyle, margin: 0, textDecoration: 'underline' }}>
          Company Name: SPECIALTY CAPITAL SPV I, LLC
        </Text>
        <Text style={{ ...styles.textSpaced, margin: 0 }}>Supported by:</Text>
        <Link href={customData?.fileUrl} width="100%" />
        <Text style={{ ...textStyle, marginBottom: 14 }}>
          According to SECTION 4.3. of the LOAN AND SECURITY AGREEMENT between
          VARIANT ALTERNATIVE INCOME FUND (&quot;Variant&quot; or
          &quot;Agent&quot;) and SPECIALTY CAPITAL SPV I, LLC
          (&quot;Borrower&quot; or the &quot;Company&quot;):
        </Text>

        {/*
         * =================================================================
         * Table 1
         * =================================================================
         */}
        <View style={styles.tableContainer}>
          <View wrap={false} style={styles.tableRow}>
            <Text
              style={{
                ...textStyle,
                margin: 0,
                borderRight: '1px solid black',
                width: '80%',
              }}
            >
              AMOUNT IN COLLECTIONS ACCOUNT
            </Text>
            <Text
              style={{
                ...textStyle,
                margin: 0,
                width: '20%',
                textAlign: 'right',
              }}
            >
              {formatCurrency(Number(data[0]?.value ?? '0'))}
            </Text>
          </View>
          <View wrap={false} style={styles.tableRow}>
            <Text
              style={{
                ...textStyle,
                margin: 0,
                borderRight: '1px solid black',
                width: '80%',
              }}
            >
              MINIMUM CASH IN COLLECTIONS ACCOUNT
            </Text>
            <Text
              style={{
                ...textStyle,
                margin: 0,
                width: '20%',
                textAlign: 'right',
              }}
            >
              {formatCurrency(0)}
            </Text>
          </View>
          <View wrap={false} style={styles.tableRow}>
            <Text
              style={{
                ...textStyle,
                margin: 0,
                borderRight: '1px solid black',
                width: '80%',
              }}
            >
              {' '}
            </Text>
            <Text
              style={{
                ...textStyle,
                margin: 0,
                width: '20%',
                textAlign: 'right',
              }}
            >
              {' '}
            </Text>
          </View>
          {table1Rows.map((row, idx) => (
            <View wrap={false} key={`table1-row${idx}`} style={styles.tableRow}>
              <Text
                style={{
                  ...textStyle,
                  margin: 0,
                  borderRight: '1px solid black',
                  width: '80%',
                }}
              >
                <Text
                  style={{
                    ...textStyle,
                    margin: 0,
                    fontFamily: 'Times-Bold',
                  }}
                >
                  {row.number}
                </Text>
                {row.description}
              </Text>
              <Text
                style={{
                  ...textStyle,
                  margin: 0,
                  width: '20%',
                  textAlign: 'right',
                }}
              >
                {formatCurrency(table1Values[idx])}
              </Text>
            </View>
          ))}
        </View>
        <Text style={textStyle}>
          SPECIALTY CAPITAL SPV I, LLC, by its duly authorized officer signing
          below, hereby certifies that (i) such financial statements were
          prepared in accordance with GAAP applied on a consistent basis (other
          than for the absence of footnotes and normal recurring year-end
          adjustments), (ii) such financial statements fairly and accurately
          present the results of operations and financial condition of Seller in
          all material respects as of the date of such financial statements and
          (iii) Borrower is in compliance with the provisions of this Agreement.
        </Text>
        {/*
         * =================================================================
         * signature
         * =================================================================
         */}
        <View
          wrap={false}
          style={{
            ...styles.tableContainer,
            position: 'absolute',
            bottom: 65,
            width: '88%',
            border: '1px solid lightgray',
          }}
        >
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              borderBottom: '1px solid lightgray',
            }}
          >
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '50%',
                paddingLeft: 4,
                paddingBottom: 10,
              }}
            >
              <Text style={{ ...styles.text, margin: 0, fontWeight: 'bold' }}>
                Prepared by:
              </Text>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: 50,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Text style={{ ...styles.text, color: 'white' }}>
                  [sig|req|signer1|signature]
                </Text>
              </View>
              <Text
                style={{
                  ...styles.text,
                  margin: 0,
                  marginTop: 6,
                  fontFamily: 'Times-Bold',
                }}
              >
                Name:{' '}
                <Text style={{ ...styles.text, margin: 0 }}>
                  {formData?.signers?.[0]?.name}
                </Text>
              </Text>
              <Text
                style={{ ...styles.text, margin: 0, fontFamily: 'Times-Bold' }}
              >
                Title:{' '}
                <Text style={{ ...styles.text, margin: 0 }}>
                  {formData?.signers?.[0]?.title}
                </Text>
              </Text>
            </View>
            {facility?.waterfall_includes_agent && (
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '50%',
                  borderLeft: '1px solid lightgray',
                  paddingLeft: 4,
                  paddingBottom: 10,
                }}
              >
                <Text
                  style={{
                    ...styles.text,
                    margin: 0,
                    fontFamily: 'Times-Bold',
                  }}
                >
                  Authorized Signature
                  <Text
                    style={{
                      ...styles.text,
                      margin: 0,
                      verticalAlign: 'super',
                      fontSize: 7,
                    }}
                  >
                    {' '}
                    (1)
                  </Text>
                  :
                </Text>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: 50,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Text style={{ ...styles.text, color: 'white' }}>
                    [sig|req|signer2|signature]
                  </Text>
                </View>
                <Text
                  style={{
                    ...styles.text,
                    margin: 0,
                    marginTop: 6,
                    fontFamily: 'Times-Bold',
                  }}
                >
                  Name:{' '}
                  <Text style={{ ...styles.text, margin: 0 }}>
                    {formData?.signers?.[1]?.name}
                  </Text>
                </Text>
                <Text
                  style={{
                    ...styles.text,
                    margin: 0,
                    fontFamily: 'Times-Bold',
                  }}
                >
                  Title:{' '}
                  <Text style={{ ...styles.text, margin: 0 }}>
                    {formData?.signers?.[1]?.title}
                  </Text>
                </Text>
              </View>
            )}
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              paddingBottom: 10,
              justifyContent: 'flex-end',
            }}
          >
            <Text style={styles.text}>
              <Text
                style={{
                  ...styles.text,
                  margin: 0,
                  verticalAlign: 'super',
                  fontSize: 7,
                }}
              >
                (1){' '}
              </Text>
              If this document is being transmitted electronically, the Borrower
              acknowledges that by entering the name of its duly authorized
              officer, that officer has reviewed the Certificate and affirmed
              the representations and warranties referenced above.
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  )
}

export default SpecialtyCapitalWaterfallTemplate
