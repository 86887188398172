import React, { useContext, useEffect, useState } from 'react'

import Chart from '@components/chart'
import Table from '@components/table'
import { REMOUNT_MS } from '@constants/config'
import AuthContext from '@contexts/auth'
import {
  CovenantDetailResponse,
  CovenantListResponse,
  CovenantType,
} from '@interfaces/manage-monitor-covenant'

import { getLabel, getLabelFormat } from '../helper'
import { ManageCovenantDataBuilder } from '../manage-covenant-data-builder'

interface CovenantDetailVintageProps {
  covenant: CovenantDetailResponse
  type: CovenantType
  summary: CovenantListResponse
}

const CovenantDetailVintage = ({
  covenant,
  summary,
}: CovenantDetailVintageProps) => {
  const [selectedCohort, setSelectedCohort] = useState<string>('Summary')
  const covenantBuilder = new ManageCovenantDataBuilder(
    setSelectedCohort,
    selectedCohort,
    covenant,
    summary
  )

  const conv = covenant
  const {
    tableColumns,
    tableData,
    tableHeaderData,
    chartSeries,
    chartData,
    minVal,
    maxVal,
  } = covenantBuilder.build()
  const { company, appliedFilters } = useContext(AuthContext)

  /** simulate processing to remount chart component */
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [conv])

  return (
    <div className="gap-6 [&>div]:w-full">
      <Chart
        loading={isProcessing}
        id={`${summary.covenant_id}__${company?.slug_name}_${appliedFilters.activeFacilityId}`}
        yLabel={getLabel(conv)}
        yFormat={getLabelFormat(summary)}
        xLabel="Month on Books (MoB)"
        xAxisType="CategoryAxis"
        data={chartData}
        series={chartSeries}
        exportable={false}
        ySetting={{
          max: maxVal,
          min: minVal,
        }}
      />
      <Table
        containerClass="nohover overflow-auto [&>table>thead>tr>td]:font-semibold"
        allowOverflow={false}
        data={tableData}
        headerData={tableHeaderData}
        columns={tableColumns as any}
      />
    </div>
  )
}

export default CovenantDetailVintage
