import React, { useContext, useEffect, useState } from 'react'
import { format } from 'date-fns'

import Typography from '@components/atoms/typography'
import PieChart from '@components/chart/pie'
import L5ChartstatLayout from '@components/layouts/l5-chartstat-layout'
import { REMOUNT_MS } from '@constants/config'
import AuthContext from '@contexts/auth'
import { DashboardResponse } from '@interfaces/manage-monitor-dashboard'

import { ManageDataBuilder } from '../manage-chart-data-builder'

const MonitorDonutChartItem = ({ item }: { item: DashboardResponse }) => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false)

  const { appliedFilters } = useContext(AuthContext)
  const { calculationDate } = appliedFilters

  const builder = new ManageDataBuilder(item, calculationDate)
  const {
    chartData,
    allChartData,
    unit,
    title,
    dashboardId,
    displayName,
    centerField,
    centerLabel,
    categoryField,
    valueField,
  } = builder.build()

  /** simulate processing to remount chart component when filters change */
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [appliedFilters])

  return (
    <>
      {displayName && (
        <Typography className="text-center text-lg font-semibold">
          {displayName}{' '}
          {calculationDate && `for ${format(calculationDate, 'yyyy-MM-dd')}`}
        </Typography>
      )}
      <L5ChartstatLayout
        chart={
          <div className="text-center">
            {!!allChartData && chartData.length > 0 ? (
              <div className="bg-white/30 w-full text-center">
                <PieChart
                  id={`${dashboardId}-${title}`}
                  data={chartData}
                  label1Text={centerLabel ?? ''}
                  label2Text={allChartData[`${centerField ?? ''}`] ?? ''}
                  label3Text={unit ?? ''}
                  categoryField={categoryField}
                  valueField={valueField}
                  isLoading={isProcessing}
                />
              </div>
            ) : (
              <div className="my-56">
                No data found for the selected calculation date
              </div>
            )}
          </div>
        }
      />
    </>
  )
}
export default MonitorDonutChartItem
