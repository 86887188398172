import React, { useContext, useEffect, useState } from 'react'
import { format } from 'date-fns'
import { useQuery } from 'react-query'

import FilterDateRange from '@components/filters/filter-date-range'
import FilterCurrency from '@components/filters/filter-dates-currency'
import FilterTypeKey from '@components/filters/filter-type-key'
import L3TabsLayout from '@components/layouts/l3-tabs-layout'
import { filterDateAsOfType } from '@constants/filter'
import AuthContext from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import { capitalizeWords } from '@helpers/string-formatter'
import { ArrowPathIcon } from '@heroicons/react/24/outline'
import { GetAnalyticsProRequestDTO } from '@interfaces/analytics-pro'
import { Typography } from '@material-tailwind/react'
import { AnalyticsProService } from '@services/api-analytics/analytics-pro'

import AnalyticsProTabL4 from './tab'

const AnalyticsProCategoriesOverviewL3 = ({
  category,
}: {
  category: string
}) => {
  const { activeFilters, appliedFilters, company, setAppliedFilters } =
    useContext(AuthContext)
  const {
    dateStart,
    dateEnd,
    currency,
    categoryTypes = [],
    activeType,
  } = appliedFilters
  const isAggregate = activeType === 'All'
  const [tabs, setTabs] = useState<any[]>([])

  const requestBody: GetAnalyticsProRequestDTO = {
    slugName: company?.slug_name ?? '',
    category: category,
    currency: currency ?? 'USD',
    filters: isAggregate ? '' : categoryTypes.join(','),
    dateFrom: format(dateStart ?? company?.date_start, 'yyyy-MM-dd'),
    dateTo: format(dateEnd ?? company?.date_end, 'yyyy-MM-dd'),
  }

  const { data: analyticsProCategoryCharts, isFetching: analyticsProFetching } =
    useQuery(
      ['analyticsProCategoryCharts', requestBody],
      () => {
        setTabs([])
        return AnalyticsProService.listCategoryCharts(
          company?.slug_name ?? '',
          requestBody
        )
      },
      {
        ...getStaleMins(),
        enabled: !!company?.slug_name,
      }
    )

  useEffect(() => {
    const components: any[] = []
    analyticsProCategoryCharts?.map(chart => {
      components.push({
        label: capitalizeWords(chart.chart_title),
        component: <AnalyticsProTabL4 data={chart} />,
      })
    })
    setTabs(components)
  }, [analyticsProCategoryCharts])

  const [filterReady, setFilterReady] = useState<boolean>(false)
  useEffect(() => {
    if (
      !filterReady &&
      appliedFilters.dateStart &&
      appliedFilters.dateEnd &&
      appliedFilters.categoryTypes
    ) {
      //carries filters over unless no previously applied filters
      JSON.stringify(appliedFilters) !== JSON.stringify({ activeFilters }) &&
        setAppliedFilters(activeFilters)
      setFilterReady(true)
    }
  }, [activeFilters])

  useEffect(() => {
    setFilterReady(false)
  }, [company?.slug_name])

  /**
   * observe reset button disable state criteria
   */
  const disableReset =
    appliedFilters.currency ==
      company?.currencies_available?.[0]?.to_currency &&
    (!appliedFilters.dateStart ||
      new Date(appliedFilters.dateStart) ==
        new Date(company?.date_start ?? '')) &&
    (!appliedFilters.dateEnd ||
      new Date(appliedFilters.dateEnd) == new Date(company?.date_end ?? '')) &&
    appliedFilters.categoryTypes?.length == 0

  return (
    <L3TabsLayout
      title="Analytics Pro"
      filters={{
        left: <FilterCurrency dateAsOfType={filterDateAsOfType.loanTape} />,
        middle: <FilterDateRange />,
        right: <FilterTypeKey />,
      }}
      disableReset={disableReset}
      tabs={tabs}
      empty={
        analyticsProFetching ? (
          <div className="flex justify-center gap-2">
            <ArrowPathIcon className="animate-spin text-primary-main w-4" />
            <Typography className="text-center">Loading</Typography>
          </div>
        ) : (
          <Typography className="text-center">
            No {category} charts found
          </Typography>
        )
      }
      switchable
    />
  )
}

export default AnalyticsProCategoriesOverviewL3
