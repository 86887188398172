import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import Chart from '@components/chart'
import StatisticL5 from '@components/layouts/l5-stat'
import MultiToggleLayout from '@components/layouts/multi-toggle/multi-toggle-layout'
import { ToggleProps } from '@components/selectors/multi-option-buttons'
import useToggleState from '@components/selectors/toggle-index-tracker'
import Table from '@components/table'
import { REMOUNT_MS } from '@constants/config'
import AuthContext from '@contexts/auth'
import { useDisbursementCurrencies } from '@helpers/currency-hook'
import { numberFormatter } from '@helpers/number-formatter'
import { getStaleMins } from '@helpers/stale-timer'
import { RisksFilters } from '@interfaces/analytics-risk'
import { Filter, FilterItem } from '@interfaces/analytics-risk-filter-type-key'
import { DelinquencyService } from '@services/api-analytics/risk-delinquency'

const FirstPaymentDefaultL4 = ({ exportable }: { exportable: boolean }) => {
  const { currTabs, mapToggleChange } = useToggleState([0])
  const { company, appliedFilters, optionFilters } = useContext(AuthContext)
  const showHistoricalRateIndicator = useDisbursementCurrencies()
  const [value, setValue] = useState<number>(30)

  const balanceToggle: ToggleProps = {
    toggleTitle: 'DPD Frequency',
    toggleSelections: [30, 60, 90].map(v => ({
      label: `${v} DPD`,
      textInfo: {
        primary:
          'Loans that do not have any full schedule payments before becoming delinquent.',
        secondary:
          'Formula: Outstanding balance of loans reaching selected DPD cutoff without paying first amount due, divided by Total Principal Disbursed',
      },
      action: () => {
        setValue(v)
      },
    })),
  }

  const toggles: ToggleProps[] = mapToggleChange([balanceToggle])

  const {
    dateStart,
    dateEnd,
    categoryTypes = [],
    activeType,
    currency = 'USD',
  } = appliedFilters
  const { categoryTypeOptions } = optionFilters

  const isAggregate = activeType === 'All'
  const activeVal =
    categoryTypeOptions?.find((rf: Filter) => rf.type_key === activeType)
      ?.type_val ?? []

  const filters: RisksFilters = {
    date_from: moment(dateStart).format('YYYY-MM-DD'),
    date_to: moment(dateEnd).format('YYYY-MM-DD'),
    slug_name: company?.slug_name ?? '',
    filters: isAggregate ? [] : categoryTypes,
    value: `fpd${value}`,
    is_aggregate: isAggregate,
    currency,
  }

  const {
    error,
    data: fetchedData,
    isFetching,
  } = useQuery(
    [`dpd${value}fpd`, filters],
    () => DelinquencyService.getFpdByCohort(filters),
    getStaleMins()
  )

  const { data, ...growthRatios } = fetchedData ?? {}

  const cohort = (data || []).filter(
    (v, i, a) => a.findIndex(v2 => v2.cohort === v.cohort) === i
  )

  const chartData = cohort.map(c => {
    const result: { x: number; [key: string]: any } = {
      x: moment(c.cohort).valueOf(),
    }
    const cohortData = (data || []).filter(d => d.cohort === c.cohort)
    if (isAggregate) {
      result.type_all = parseFloat((cohortData?.[0]?.val ?? 0).toString())
      result.type_all_outstanding_balance = parseFloat(
        (cohortData?.[0]?.outstanding_balance ?? 0).toString()
      )
      result.type_all_disburse_principal = parseFloat(
        (cohortData?.[0]?.disburse_principal ?? 0).toString()
      )
    } else {
      let numerator = 0,
        denominator = 0
      categoryTypes.forEach((ct: number) => {
        const cohortCategory = cohortData.find(cd => cd.id === ct)
        result[`type_${ct}`] = parseFloat((cohortCategory?.val ?? 0).toString())
        const outstanding = parseFloat(
          (cohortCategory?.outstanding_balance ?? 0).toString()
        )
        result[`type_${ct}_outstanding_balance`] = outstanding
        const principal = parseFloat(
          (cohortCategory?.disburse_principal ?? 0).toString()
        )
        result[`type_${ct}_disburse_principal`] = principal
        numerator += outstanding
        denominator += principal
      })
      result['type_aggregate_outstanding_balance'] = numerator
      result['type_aggregate_disburse_principal'] = denominator
      result['type_aggregate'] = (numerator / denominator) * 100
    }

    return result
  })

  /** simulate processing to remount chart component */
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [data])

  const aggregateSeriesBars = (categoryTypes || []).map((ct: number) => {
    const typeVal = activeVal.find((av: FilterItem) => av.id === ct)
    const color = '#' + Math.floor(Math.random() * 16777215).toString(16)
    return {
      label: typeVal?.type,
      tooltipValueFormat: '#.00a%',
      field: `type_${ct}`,
      isTotal: false,
      color,
      type: 'ColumnSeries',
    }
  })
  aggregateSeriesBars.push({
    label: 'Aggregate',
    tooltipValueFormat: '#.00a%',
    field: `type_aggregate`, // we use type_0 for aggregate
    isTotal: false,
    color: '#' + Math.floor(Math.random() * 16777215).toString(16),
    type: 'ColumnSeries',
  })

  const series = isAggregate
    ? [
        {
          label: 'All',
          tooltipValueFormat: '#.00a%',
          field: 'type_all',
          type: 'ColumnSeries',
        },
      ]
    : aggregateSeriesBars

  const header_data = [
    series.reduce((p: any, s: any) => {
      return {
        ...p,
        [`${s.field}_disburse_principal`]: 'Disbursed Principal',
        [`${s.field}_outstanding_balance`]:
          'Unpaid Principal (No full payments)',
        [s.field]: 'FPD (%)',
      }
    }, {}),
  ]

  const table_columns = [
    {
      title: 'Cohort',
      field: 'x',
      align: 'center',
      className: 'sticky left-0 min-w-[150px] bg-white',
      render: (r: any) => {
        return r.x ? moment.utc(r.x).format('YYYY-MM-DD') : ''
      },
    },
    ...series.reduce((p: any[], s: any) => {
      return [
        ...p,
        {
          className: 'min-w-[150px]',
          align: 'right',
          field: `${s.field}_outstanding_balance`,
          title: header_data[0][`${s.field}_outstanding_balance`],
          head: { colSpan: 0 },
          render: (r: any) =>
            numberFormatter(r[`${s.field}_outstanding_balance`]),
        } as any,
        {
          className: 'min-w-[150px]',
          align: 'right',
          field: `${s.field}_disburse_principal`,
          title: header_data[0][`${s.field}_disburse_principal`],
          head: { colSpan: 0 },
          render: (r: any) =>
            numberFormatter(r[`${s.field}_disburse_principal`]),
        } as any,
        {
          className: 'min-w-[150px]',
          align: 'right',
          field: s.field,
          title: `First Payment Default (FPD) by Cohort ${
            isAggregate ? '' : `(${s.label})`
          }`,
          head: { colSpan: 3, align: 'center' },
          render: (r: any) => numberFormatter(r[s.field]),
        } as any,
      ]
    }, []),
  ]

  return (
    <MultiToggleLayout
      toggles={toggles}
      toggleSelection={currTabs}
      exchangeRateIndicator={showHistoricalRateIndicator}
      isHistorical={false}
      chart={
        <Chart
          loading={isFetching || isProcessing}
          data={chartData}
          id={`dpd${value}Fpd_by_${activeType}`}
          yLabel="Percentage"
          yFormat="#.00a%"
          ySetting={{ min: 0 }}
          series={series}
          exportable={exportable}
          exportableColumn={table_columns}
          error={error as { message: string }}
        />
      }
      stat={
        <StatisticL5
          description={`percentage at ${value}DPD`}
          classSetting={{ up: 'text-danger-hover', down: 'text-success-hover' }}
          {...growthRatios}
        />
      }
      toggleData={
        <Table
          containerClass="[&>table>thead>tr>td]:text-center [&>table>thead>tr>td]:font-semibold"
          loading={isFetching || isProcessing}
          data={chartData}
          headerData={header_data}
          columns={table_columns}
        />
      }
    />
  )
}

export default FirstPaymentDefaultL4
