import React from 'react'

import Table from '@components/table'

const AnalyticsProTableItem = ({
  tableData,
  tableColumns,
  stickyLeftCount,
  tableHeaderData,
  maxVal = 0,
  includeHeatMap = false,
  invertColours,
}: {
  tableData: any
  tableColumns: any
  stickyLeftCount?: number
  tableHeaderData?: any[]
  maxVal?: number
  includeHeatMap?: boolean
  invertColours?: boolean
}) => {
  return (
    <div className="overflow-auto rounded-lg border border-neutral-border-1 max-h-[calc(100vh-100px)]">
      <Table
        data={tableData}
        columns={tableColumns as any}
        useTableOverflow={false}
        stickyLeftCount={stickyLeftCount}
        headerData={tableHeaderData}
        includeHeatMap={includeHeatMap}
        maxVal={maxVal}
        invertColours={invertColours}
      />
    </div>
  )
}
export default AnalyticsProTableItem
