import React, { useState } from 'react'

import { GetAnalyticsProResponseDTO } from '@interfaces/analytics-pro'

import CovenantChart from './charts/covenant'
import AnalyticsProStackedAreaItem from './charts/stacked-area'

import 'react-grid-layout/css/styles.css'
import './style.css'

const AnalyticsProTabL4 = ({ data }: { data: GetAnalyticsProResponseDTO }) => {
  const [showTable, setShowTable] = useState(true)

  const _renderChart = (i: GetAnalyticsProResponseDTO) => {
    switch (i.chart_type) {
      case 'line_graph':
        return (
          <AnalyticsProStackedAreaItem
            data={i}
            showTableSwitch={[showTable, setShowTable]}
          />
        )
      case 'vintage':
        return (
          <CovenantChart data={i} showTableSwitch={[showTable, setShowTable]} />
        )
      default:
        return <></>
    }
  }

  return (
    <div className="md:grid md:grid-cols-2 lg:grid-cols-3 gap-10">
      {
        <div key={data.chart_id} className={`md:col-span-2 lg:col-span-3`}>
          {_renderChart(data)}
        </div>
      }
    </div>
  )
}

export default AnalyticsProTabL4
