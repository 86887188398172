export const numberFormatter = (value?: any) => {
  return typeof value === 'number'
    ? Intl.NumberFormat(undefined, {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(parseFloat((value ?? 0).toString()))
    : value
}

export const numberFormatterInPercentage = (value?: any) => {
  return typeof value === 'number'
    ? Intl.NumberFormat(undefined, {
        style: 'percent',
        notation: 'compact',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(parseFloat((value ?? 0).toString()))
    : value
}

export const formatCurrency = (amount: number, currency = 'USD') => {
  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
  }).format(amount)
}

export const formatPercent = (value: number) => {
  return Intl.NumberFormat(undefined, {
    style: 'percent',
    maximumFractionDigits: 2,
  }).format(value)
}

// For sorting series in complex filters with multiple "Category Types"
export const seriesSorter = (series: any) => {
  return series.sort((seriesA: any, seriesB: any) => {
    if (seriesA.label === 'NOT PROVIDED') {
      return 1
    }
    if (seriesB.label === 'NOT PROVIDED') {
      return -1
    }
    const numA = parseInt(seriesA.label.split('-')[0], 10) || 0
    const numB = parseInt(seriesB.label.split('-')[0], 10) || 0
    return numA - numB
  })
}
