import { useContext } from 'react'
import moment from 'moment'
import { useMutation } from 'react-query'

import { useAuth0 } from '@auth0/auth0-react'
import AuthContext from '@contexts/auth'
import { Facility } from '@interfaces/facility'
import { GenerateClientReportPayload } from '@interfaces/manage-monitor-action'
import { CategoriesResult } from '@interfaces/manage-reporting'
import { ActionService } from '@services/api-manage/monitor-action'

export const useGenerateClientReport = (
  category: CategoriesResult,
  addToDocCentre?: boolean
) => {
  const { user } = useAuth0()
  const { company, appliedFilters, optionFilters } = useContext(AuthContext)
  const { activeFacilityId } = appliedFilters
  const { facilities } = optionFilters
  const activeFacility = facilities?.find(
    (f: Facility) => f.facility_id === activeFacilityId
  )

  const { mutate: generateClientReport } = useMutation(
    (params: GenerateClientReportPayload) => {
      return ActionService.generateClientReport(params)
    }
  )

  const afterEffect = (
    effectiveDate: string,
    customData: Record<string, any>
  ) => {
    if (customData.fileUrl) {
      generateClientReport({
        slugName: activeFacility?.slug_name ?? company?.slug_name,
        facilityId: activeFacility?.facility_id,
        params: {
          effectiveDate: moment(effectiveDate).format('YYYY-MM-DD'),
          fileName: customData.fileUrl,
          category: category.category,
          docCentreUploadExtraData: addToDocCentre
            ? {
                docId: customData.docId,
                facilityName: activeFacility?.facility_name ?? '',
                category: customData.excelUploadCategory,
                title: customData.excelUploadTitle,
                author_email: user?.email ?? '',
                author_name: user?.company_name ?? '',
              }
            : undefined,
        },
      })
    }
  }

  return { afterEffect }
}
