import React, { useContext, useEffect, useState } from 'react'
import { MultiSelect, Option } from 'react-multi-select-component'
import { useQuery } from 'react-query'

import { useActiveTab } from '@components/layouts/tabs-context'
import AuthContext from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import {
  ValidationSelection,
  ValidationService,
} from '@services/api-data-validation/validation'

import { FilterCell } from './filter-cell'
const tabToTextMap: Record<number, string[]> = {
  0: ['Loan', 'Disburse'],
  1: ['Customer', 'Disburse'],
  2: ['Transaction', 'Transaction'],
}

interface TestSevOptionType {
  tests: Option[]
  severities: Option[]
}

const defaultValidation = {
  searchId: '',
  descriptionSelection: [],
  severitySelection: [],
}

/** Filter Quarantine is an object of 4 fields:
 * id, test name, severity
 *  */
const FilterQuarantine = ({ tab }: { tab: number }) => {
  const [TestSevOptions, setTestSevOptions] = useState<TestSevOptionType[]>([
    {
      tests: [],
      severities: [],
    },
    {
      tests: [],
      severities: [],
    },
    {
      tests: [],
      severities: [],
    },
  ])

  const [localSeverity, setLocalSeverity] = useState<Option[]>([])
  const [localDescription, setLocalDescription] = useState<Option[]>([])

  const {
    company,
    activeFilters,
    setActiveFilters,
    appliedFilters,
    setAppliedFilters,
  } = useContext(AuthContext)

  // Query for all severity and all qurantine at the beginning of this page rendering. use this query to find severity and description selection
  const { data: selectionData, isLoading } = useQuery<
    ValidationSelection | undefined
  >(
    ['quarantine-validation-filters', company?.slug_name],
    () => {
      const slugName = company?.slug_name
      if (slugName) {
        return ValidationService.getValidationSelections(slugName)
      } else {
        return undefined
      }
    },
    { ...getStaleMins(), enabled: !!company?.slug_name }
  )
  // initializing Applied Filters
  useEffect(() => {
    if (selectionData) {
      const options = Object.values(selectionData).reduce((acc, value) => {
        const t = value.tests.map(d => ({ label: d, value: d }))
        const s = value.severities.map(d => ({ label: d, value: d }))
        acc.push({ tests: t, severities: s })
        return acc
      }, [] as TestSevOptionType[])

      // initialize possible options
      setTestSevOptions(options)
    }
    // Initialize applied filter with default values
    setAppliedFilters({
      ...appliedFilters,
      validationQuery: defaultValidation,
    })
  }, [selectionData])

  // Updating Active Filters
  useEffect(() => {
    setActiveFilters({
      ...activeFilters,
      validationQuery: {
        ...activeFilters.validationQuery,
        severitySelection: localSeverity,
        descriptionSelection: localDescription,
      },
    })
  }, [localSeverity, localDescription])

  // Reseting Active and Applied Filters when changing Tabs
  const { activeContextTab } = useActiveTab()

  useEffect(() => {
    setAppliedFilters({
      ...appliedFilters,
      validationQuery: defaultValidation,
    })
    setActiveFilters({
      ...activeFilters,
      validationQuery: defaultValidation,
    })
  }, [activeContextTab])

  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <div className="flex flex-col sm:flex-row gap-4 gap-y-1 sm:flex-wrap justify-between">
      <div className="w-[100px]">
        <FilterCell title="Last Updated">
          {company?.lastAnalyticsPipeline}
        </FilterCell>
      </div>
      <VerticalSelection title={`${tabToTextMap[tab][0]} ID`}>
        <input
          value={activeFilters.validationQuery?.searchId || ''}
          onChange={e => {
            setActiveFilters({
              ...activeFilters,
              validationQuery: {
                ...activeFilters.validationQuery, // Corrected here
                searchId: e.target.value,
              },
            })
          }}
          placeholder={`Search by ${tabToTextMap[tab][0]}`}
          className="border rounded-md p-[6px] border-cc-primary-gray-light w-full"
        />
      </VerticalSelection>

      {!isLoading && (
        <VerticalSelection title="Test Name">
          <MultiSelect
            className="h-full min-w-[180px] z-20"
            options={TestSevOptions[tab].tests}
            value={activeFilters.validationQuery?.descriptionSelection || []}
            onChange={setLocalDescription}
            labelledBy="Select"
            hasSelectAll={false}
            disableSearch={true}
            ClearSelectedIcon={null}
          />
        </VerticalSelection>
      )}

      {!isLoading && (
        <VerticalSelection title="Severity">
          <MultiSelect
            className="h-full min-w-[180px] z-20"
            options={TestSevOptions[tab].severities}
            value={activeFilters.validationQuery?.severitySelection || []}
            onChange={setLocalSeverity}
            labelledBy="Select"
            hasSelectAll={false}
            disableSearch={true}
            ClearSelectedIcon={null}
          />
        </VerticalSelection>
      )}
    </div>
  )
}
export default FilterQuarantine

const VerticalSelection: React.FC<{
  title: string
  children: React.ReactNode
}> = ({ title, children }) => {
  return (
    <div className="flex flex-col gap-1 justify-between h-full sm:w-[175px] 2xl:w-[200px]">
      {/* Title should take up only the necessary space */}
      <div className="text-neutral-body-2 text-sm  w-auto">{title}</div>
      <div>{children}</div>
    </div>
  )
}
