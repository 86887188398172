import {
  GetAnalyticsProRequestDTO,
  GetAnalyticsProResponseDTO,
} from '@interfaces/analytics-pro'

import analyticsApiClient from './client'

export class AnalyticsProService {
  static async listCategories(slugName: string) {
    const { data } = await analyticsApiClient.get<string[]>(
      `pro/list-categories/${slugName}`
    )
    return data
  }

  static async listCategoryCharts(
    slugName: string,
    requestBody: GetAnalyticsProRequestDTO
  ): Promise<GetAnalyticsProResponseDTO[]> {
    const { data } = await analyticsApiClient.post<
      GetAnalyticsProResponseDTO[]
    >(`pro/list-category-charts/${slugName}`, requestBody)
    return data
  }
}
