import React, { useContext, useEffect, useState } from 'react'
import { format } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import HelloSign from 'hellosign-embedded'
import { useFieldArray, useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'

import { useAuth0 } from '@auth0/auth0-react'
import Button from '@components/atoms/button'
import Typography from '@components/atoms/typography'
import FormInput from '@components/form/form-input'
import { DROPBOX_SIGN_CLIENT_ID } from '@constants/config'
import AuthContext from '@contexts/auth'
import { getCurrencies } from '@helpers/currency-hook'
import { getStaleMins } from '@helpers/stale-timer'
import { ArrowPathIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Facility } from '@interfaces/facility'
import { CategoriesResult } from '@interfaces/manage-reporting'
import { BankInfo, SignatureRequest } from '@interfaces/manage-signature'
import {
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from '@material-tailwind/react'
import { pdf, PDFViewer } from '@react-pdf/renderer'
import { FacilityDetailsService } from '@services/api-manage/facility-details'
import { ActionService } from '@services/api-manage/monitor-action'
import { BorrowingBaseService } from '@services/api-manage/monitor-borrowing-base'
import { MonitorFilterService } from '@services/api-manage/monitor-filter'
import SignatureService from '@services/api-manage/signature'

import {
  CustomFields,
  SignerInput,
} from '../../../document-centre/templates/components'
import renderTemplate from '../../templates'

import { bankingInputs } from './constants'

interface CreateAdvanceRequestDialogProps {
  docData?: any
  open?: boolean
  handler: (needupdate?: boolean) => void
  docCentreId: string
  category: CategoriesResult
  canSign: boolean
}

export const CreateAdvanceRequestDialog = ({
  open = false,
  handler,
  docData,
  docCentreId,
  category,
  canSign,
}: CreateAdvanceRequestDialogProps) => {
  const client = new HelloSign()
  const { user } = useAuth0()
  const { company, optionFilters, appliedFilters } = useContext(AuthContext)
  const { currencies } = getCurrencies()
  const [submittedFormData, setSubmittedFormData] = useState<any>()
  const [guarantors, setGuarantors] = useState<string[]>([])
  const [borrower, setBorrower] = useState<string>('')
  const [agent, setAgent] = useState<string>('')
  const [bankingInfo, setBankingInfo] = useState<BankInfo>({} as BankInfo)
  const [bbCalcDate, setBbCalcDate] = useState<string>('')

  const { activeFacilityId = 0 } = appliedFilters
  const { facilities = [] } = optionFilters
  const activeFacility = facilities?.find(
    (f: Facility) => f.facility_id == activeFacilityId
  )

  const form = useForm({
    shouldUnregister: false,
  })
  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid, isSubmitting },
    reset,
  } = form

  const { fields, remove } = useFieldArray({
    control,
    name: 'lenders',
  })
  const amount = getValues('amount') ?? 0
  const currency = activeFacility?.agreement_currency ?? 'USD'
  const lenders_info = activeFacility?.lenders_info
    ? JSON.parse(activeFacility.lenders_info)
    : {}

  useEffect(() => {
    if (open && !!activeFacility?.facility_name) {
      setTimeout(() => {
        setValue('currency', activeFacility.agreement_currency ?? 'USD', {
          shouldValidate: true,
        })
        if (!getValues('advance_date')) {
          setValue('advance_date', new Date(), {
            shouldValidate: true,
          })
        }
        if (!!options?.signers?.length) {
          setValue('signer', 0, {
            shouldValidate: true,
          })
          setValue('name', options.signers[0].name, {
            shouldValidate: true,
          })
          setValue('email', options.signers[0].email, {
            shouldValidate: true,
          })
          setValue('title', 'CEO', {
            shouldValidate: true,
          })
        }
      })
    } else {
      reset()
      remove()
    }
  }, [open, activeFacility])

  const _successHandler = () => {
    handler?.(true)
  }

  const {
    mutate: getPartyDetails,
    data: partyDetails,
    isLoading: isLoadingPartyDetails,
  } = useMutation(
    // eslint-disable-next-line prettier/prettier
    (params: { facility_id: number; facility: string; slug_name: string }) => {
      return FacilityDetailsService.getDealPartyDetailsRequest(params)
    },
    {
      onSuccess: data => {
        const guarantorsList = data
          .filter(entry => entry.type === 'Guarantor')
          .map(entry => entry.legal_name as string)
        setGuarantors(guarantorsList)
        const foundBorrower = data.filter(
          entry => entry.type === 'Borrower'
        )?.[0]?.legal_name as string
        setBorrower(foundBorrower)
        const foundAgent = data.filter(entry => entry.type === 'Agent')?.[0]
          ?.legal_name as string
        setAgent(foundAgent)
        const banking = JSON.parse(
          data.find(entry => entry.type === 'Banking')?.supplementary ?? '{}'
        )
        setBankingInfo(banking)
      },
    }
  )

  const { mutate: createAdvanceRequest, isLoading: isLoadingCreate } =
    useMutation(
      ({
        slug_name,
        facility_id,
        formData,
      }: {
        slug_name: string
        facility_id: number
        formData: FormData
      }) => {
        return ActionService.createAdvanceRequest(
          slug_name,
          facility_id,
          formData
        )
      },
      {
        onSuccess: _successHandler,
        onError: () => {
          toast.error('There was an issue saving the document', {
            autoClose: false,
          })
        },
      }
    )

  const onSubmit = async (dt: any) => {
    if (dt.signers?.length) {
      generateSignableDoc(dt)
    } else {
      uploadDoc(dt)
    }
  }

  const uploadDoc = async (dt: any) => {
    const formData = new FormData()
    formData.append(
      'slug_name',
      activeFacility?.slug_name ?? company?.slug_name ?? ''
    )
    formData.append('facility', activeFacility?.facility_name ?? '')
    formData.append('date', format(dt.advance_date, 'UTC', 'yyyy-MM-dd'))
    formData.append('currency', dt.currency)
    formData.append('amount', Number(dt.amount).toString())
    formData.append(
      'title',
      `Advance Request - ${format(dt.advance_date, 'UTC', 'dd MMMM yyyy')}`
    )
    formData.append(
      'files',
      await pdf(
        renderTemplate(category?.subcategory ?? '', {
          slugName: company?.slug_name,
          facility: activeFacility?.facility_name,
          agent: agent,
          borrower: borrower,
          guarantors: guarantors,
          requestDate: format(new Date(), 'yyyy-MM-dd'),
          loanAgreementDate: activeFacility?.agreement_date
            ? format(new Date(activeFacility.agreement_date), 'yyyy-MM-dd')
            : '',
          advanceDate: dt.advance_date
            ? format(dt.advance_date, 'yyyy-MM-dd')
            : '',
          formData: dt,
          percentOfAvailable: calculatePercentOfAvailable(),
          bankInfo: bankingInfo,
          lenderRatios: generateLenderRatios(),
          originationFee: originationFee,
        })
      ).toBlob()
    )
    formData.append('form_values', JSON.stringify(dt))
    Object.keys(lenders_info)?.forEach((key: any, i: number) => {
      const ratio = lenders_info[key]?.[`lender_${i + 1}_advance_request_ratio`]
      formData.append(
        `lenders[${i}][payout_amount]`,
        (Number(amount) * ratio).toString()
      )
      formData.append(`lenders[${i}][lender_ratio]`, ratio.toString())
    })
    formData.append('status', 'signed')

    createAdvanceRequest({
      slug_name: company?.slug_name || '',
      facility_id: activeFacility?.facility_id,
      formData,
    })
  }

  const generateSignableDoc = async (dt: any) => {
    const formData = new FormData()
    formData.append(
      'slug_name',
      activeFacility?.slug_name ?? company?.slug_name ?? ''
    )
    setSubmittedFormData(dt)
    user?.email && formData.append('requester_email', `${user.email}`)
    user?.name && formData.append('requester_name', `${user.name}`)
    formData.append('send_emails', 'false')
    docData?.id && formData.append('id', docData.id)
    docCentreId && formData.append('id', docCentreId)
    formData.append('category', 'Advance Request')
    formData.append('facility', activeFacility?.facility_name)
    formData.append('effective_date', format(dt.advance_date, 'dd-MM-yyyy'))
    formData.append(
      'title',
      `Advance Request - ${format(dt.advance_date, 'dd MMMM yyyy')}`
    )
    formData.append(
      'files',
      await pdf(
        renderTemplate(category?.subcategory ?? '', {
          slugName: company?.slug_name,
          facility: activeFacility?.facility_name,
          agent: agent,
          borrower: borrower,
          guarantors: guarantors,
          requestDate: format(new Date(), 'yyyy-MM-dd'),
          loanAgreementDate: activeFacility?.agreement_date
            ? format(activeFacility?.agreement_date, 'yyyy-MM-dd')
            : '',
          advanceDate: dt.advance_date
            ? format(dt.advance_date, 'yyyy-MM-dd')
            : '',
          formData: dt,
          percentOfAvailable: calculatePercentOfAvailable(),
          bankInfo: bankingInfo,
          lenderRatios: generateLenderRatios(),
          originationFee: originationFee,
        })
      ).toBlob()
    )
    formData.append('subject', `Capital advance request form`)
    formData.append(
      'message',
      `${user?.name} has requested your signature via Cascade Debt`
    )
    formData.append(
      'signers',
      JSON.stringify(
        dt.signers
          ? dt.signers.map((s: any, i: number) => ({
              name: s.name,
              order: options?.enforceSignOrder ? i : undefined,
              emailAddress: s.email,
            }))
          : []
      )
    )
    formData.append('form_values', JSON.stringify(dt))
    formData.append('tags', 'true')
    createEmbeddedSignRequest(formData as SignatureRequest)
    toast.loading('Request in progress', { autoClose: false })
    if (canSign) {
      handler()
      reset()
    }
  }

  const saveAdvanceRequest = (
    fileUrl: string,
    status: string,
    docId: string
  ) => {
    const formData = new FormData()
    formData.append(
      'slug_name',
      activeFacility?.slug_name ?? company?.slug_name ?? ''
    )
    formData.append('facility', activeFacility?.facility_name ?? '')
    formData.append('doc_id', docId ?? '')
    formData.append(
      'date',
      format(submittedFormData.advance_date, 'yyyy-MM-dd')
    )
    formData.append('currency', submittedFormData.currency)
    formData.append(
      'amount',
      Number(
        category.subcategory === 'generic3'
          ? submittedFormData.amount / 0.98
          : submittedFormData.amount
      ).toString()
    )
    formData.append('fileUrl', fileUrl)
    formData.append('status', status)
    formData.append('requester_name', user?.name ?? '')
    formData.append(
      'signer_email',
      JSON.stringify(
        submittedFormData.signers
          ? submittedFormData.signers.map((s: any) => s.email)
          : []
      )
    )
    formData.append('origination_fee', Number(originationFee).toString())
    if (submittedFormData.lenders?.length) {
      submittedFormData.lenders?.forEach((l: any, i: number) => {
        const payout_amount =
          submittedFormData.amount * generateLenderRatios()[i]
        formData.append(
          `lenders[${i}][payout_amount]`,
          payout_amount.toString()
        )
        formData.append(
          `lenders[${i}][lender_ratio]`,
          generateLenderRatios()[i].toString()
        )
        l.id && formData.append(`lenders[${i}][id]`, l.id)
        l.comment && formData.append(`lenders[${i}][comment]`, l.comment)
        l.proof_of_transfer_file &&
          formData.append(
            `lenders[${i}][proof_of_transfer_file]`,
            l.proof_of_transfer_file
          )
      })
    } else {
      const ratios = generateLenderRatios()
      ratios.forEach((ratio, index) => {
        const payout_amount = submittedFormData.amount * ratio
        formData.append(
          `lenders[${index}][payout_amount]`,
          payout_amount.toString()
        )
        formData.append(`lenders[${index}][lender_ratio]`, ratio.toString())
      })
    }
    createAdvanceRequest({
      slug_name: company?.slug_name || '',
      facility_id: activeFacility?.facility_id,
      formData,
    })
    setSubmittedFormData(null)
    handler?.(true)
    reset()
  }

  /**
   * This section gets the advance rate
   */
  const filters = {
    slug_name: activeFacility?.slug_name ?? company?.slug_name ?? '',
    facility_id: activeFacility?.facility_id ?? '',
  }
  const { data: validDates, isFetching: isFetchingCalcDates } = useQuery(
    ['validDate', filters],
    () => MonitorFilterService.calculationDate(filters),
    {
      ...getStaleMins(),
      enabled: open && !!activeFacility.facility_name,
    }
  )

  const { data: bbData, isFetching: isFetchingBbData } = useQuery(
    ['borrowing-base', { ...filters, calculation_date: bbCalcDate }],
    () =>
      BorrowingBaseService.get({
        ...filters,
        calculation_date: bbCalcDate,
      }),
    {
      ...getStaleMins(),
      enabled: open && !!bbCalcDate,
    }
  )

  // make borrowing base calculation date available in template
  useEffect(() => {
    if (!isFetchingCalcDates && !!validDates?.length) {
      const calcDate = formatInTimeZone(
        new Date(
          validDates?.filter(
            vd => vd.facility_id === activeFacility.facility_id
          )?.[0]?.calculation_date
        ),
        'UTC',
        'yyyy-MM-dd'
      )
      setBbCalcDate(calcDate)
      setValue('calculation_date', calcDate, { shouldValidate: true })
    }
  }, [isFetchingCalcDates, validDates, open])

  useEffect(() => {
    if (!isFetchingBbData && !!bbData?.length) {
      setValue('borrowing_base_data', bbData, { shouldValidate: true })
    }
  }, [isFetchingBbData, bbData, open])

  const advanceRate =
    parseFloat(
      bbData
        ?.find(x => x.value_type === 'advance_rate')
        ?.display_value?.replace('%', '') ?? '100'
    ) / 100
  const originationFee = getValues('amount') / advanceRate - getValues('amount')

  const generateLenderRatios = () => {
    const ratios: number[] = []
    const lenderDetails = activeFacility?.lenders_info
      ? JSON.parse(activeFacility.lenders_info)
      : {}
    const numLenders = Object.keys(lenderDetails).length
    if (!!activeFacility?.facility_name && numLenders > 0) {
      let counter = 1
      while (counter <= numLenders) {
        ratios.push(
          lenderDetails[`lender_${counter}`]?.[
            `lender_${counter}_advance_request_ratio`
          ] ?? 0
        )
        counter++
      }
      return ratios
    } else {
      return []
    }
  }

  const committedAmount = Object.keys(lenders_info).reduce(
    (acc: number, cur: string) => {
      const lender_number = cur.replace('lender_', '')
      return (
        acc +
        parseInt(
          lenders_info[cur][
            `lender_${lender_number}_committed_amount` as any
          ] ?? 0
        )
      )
    },
    0
  )
  const calculatePercentOfAvailable = () => {
    const percentValue =
      (getValues('amount') ?? 0) /
      ((committedAmount ?? 0) -
        parseFloat(
          bbData
            ?.find(x => x.value_type === 'total_lender_principal_balance')
            ?.display_value?.replaceAll(',', '') ?? '0'
        ))
    return Intl.NumberFormat(undefined, {
      style: 'percent',
      maximumFractionDigits: 2,
    }).format(percentValue)
  }

  const options = category?.signers ? JSON.parse(category.signers) : {}

  /**
   * Signing functionality
   */
  const handleError = () => {
    toast.dismiss()
    toast.error(
      <div>
        An Error occurred during document creation
        <br />
        Please try again
      </div>,
      {
        autoClose: false,
      }
    )
  }
  const handleSuccess = (data?: any) => {
    toast.dismiss()
    if (data.data?.signature_request_id && data.data?.doc_id) {
      toast.success('Signature Request Created', { autoClose: 5000 })
      saveAdvanceRequest(data.data.final_copy_uri, 'pending', data.data.doc_id)
      return
    }
    if (data.data?.claim_url && data.data?.doc_id) {
      if (canSign) {
        client.open(data.data.claim_url, {
          clientId: DROPBOX_SIGN_CLIENT_ID,
          skipDomainVerification: true,
        })
        client.on('sign', () => {
          const maintainPendingStatus = docData?.signature_request
            ? JSON.parse(docData.signature_request.signees ?? '[]')?.filter(
                (s: any) =>
                  !s.signed_at && s.signer_email_address !== user?.email
              )?.length
            : submittedFormData?.signers.length >= 2

          saveAdvanceRequest(
            data.data.final_copy_uri,
            maintainPendingStatus ? 'pending' : 'signed',
            data.data.doc_id
          )
        })
        client.on('cancel', () =>
          saveAdvanceRequest(
            data.data.final_copy_uri,
            'pending',
            data.data.doc_id
          )
        )
        client.on('error', () =>
          saveAdvanceRequest(
            data.data.final_copy_uri,
            'pending',
            data.data.doc_id
          )
        )
      } else {
        saveAdvanceRequest(
          data.data.final_copy_uri,
          'pending',
          data.data.doc_id
        )
      }
    } else {
      toast.error('Unexpected Error Occurred', { autoClose: false })
    }
  }
  const { mutate: createEmbeddedSignRequest, isLoading: isLoadingDropBoxSign } =
    useMutation(
      (params: SignatureRequest) => {
        return SignatureService.createEmbeddedSignatureURL(params)
      },
      {
        onSuccess: handleSuccess,
        onError: handleError,
      }
    )

  useEffect(() => {
    if (open && !!activeFacility.facility_name && company?.slug_name) {
      setValue('advance_date', new Date(), { shouldValidate: true })
      getPartyDetails({
        facility_id: activeFacility?.facility_id,
        facility: activeFacility?.facility_name,
        slug_name: activeFacility?.slug_name ?? company?.slug_name,
      })
    }
  }, [open, activeFacility])

  // Adds banking info to custom fields only after party details has been fetched
  useEffect(() => {
    if (
      open &&
      !!activeFacility.facility_name &&
      company?.slug_name &&
      !isLoadingPartyDetails &&
      partyDetails &&
      !Object.keys(bankingInfo).length
    ) {
      category.custom_fields = JSON.stringify([
        ...bankingInputs,
        ...(category.custom_fields ? JSON.parse(category?.custom_fields) : []),
      ])
    }
  }, [open, activeFacility, isLoadingPartyDetails, bankingInfo])

  const isProcessing = isSubmitting || isLoadingDropBoxSign || isLoadingCreate

  const isFetching =
    isLoadingPartyDetails || isFetchingBbData || isFetchingCalcDates

  return (
    <Dialog open={open} handler={() => undefined} size="xl">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogHeader className="text-xl flex justify-between">
          <div />
          Capital Advance Request
          <XMarkIcon
            onClick={() => handler()}
            className="w-6 h-6 cursor-pointer"
          />
        </DialogHeader>
        <DialogBody
          divider
          className="flex flex-row overflow-y-auto h-[80vh] gap-4"
        >
          {isFetching ? (
            <div className="flex flex-col justify-center items-center w-full">
              <ArrowPathIcon className="w-10 h-10 mr-2 text-primary-main animate-spin" />
            </div>
          ) : (
            <PDFViewer showToolbar={false} className="h-full w-full">
              {renderTemplate(category?.subcategory ?? '', {
                slugName: company?.slug_name,
                facility: activeFacility?.facility_name,
                agent: agent,
                borrower: borrower,
                guarantors: guarantors,
                requestDate: format(new Date(), 'yyyy-MM-dd'),
                loanAgreementDate: activeFacility?.agreement_date
                  ? format(
                      new Date(activeFacility.agreement_date),
                      'yyyy-MM-dd'
                    )
                  : '',
                advanceDate: getValues('advance_date')
                  ? format(getValues('advance_date'), 'yyyy-MM-dd')
                  : '',
                formData: getValues(),
                percentOfAvailable: calculatePercentOfAvailable(),
                bankInfo: bankingInfo,
                lenderRatios: generateLenderRatios(),
                originationFee: originationFee,
              })}
            </PDFViewer>
          )}
          <div className="flex flex-col overflow-y-auto">
            <div className="mb-4">
              <div className="text-neutral-border-3 p-3 border border-neutral-border-1 flex justify-center items-center rounded-t-lg bg-neutral-surface-1">
                <Typography className="font-semibold text-sm">
                  Details
                </Typography>
              </div>
              <div className="p-4 flex flex-col border border-t-0 border-neutral-border-1 rounded-b-lg">
                <FormInput
                  type="date"
                  label={{ start: 'Advance Date' }}
                  value={getValues(`advance_date`)}
                  onSelected={val => {
                    setValue(`advance_date`, val, {
                      shouldValidate: true,
                    })
                  }}
                  error={errors?.advance_date?.message as string}
                />
                <FormInput
                  type="select"
                  label={{ start: 'Currency' }}
                  value={getValues('currency') || currency}
                  onSelected={val => {
                    setValue('currency', val, {
                      shouldValidate: true,
                    })
                  }}
                  options={(currencies ?? ['USD']).map(c => ({
                    value: c,
                    title: c,
                  }))}
                  isDisabled={true}
                  error={errors?.currency?.message as string}
                />
                <FormInput
                  type="number"
                  label={{ start: 'Capital Requested' }}
                  value={getValues('amount') || 0}
                  disabled={isProcessing}
                  {...register('amount', {
                    required: `Amount is required`,
                    onChange: e => {
                      setValue('amount', e.target.value, {
                        shouldValidate: true,
                      })
                    },
                    min: {
                      value: 1,
                      message: 'Amount can not be 0',
                    },
                  })}
                  {...{ step: '0.01' }}
                  error={errors?.amount?.message as string}
                />
                <CustomFields
                  category={category}
                  form={form}
                  certificateData={{}}
                  customData={{}}
                  docData={docData}
                />
                <div>
                  <SignerInput form={form} user={user} signers={options} />
                  {fields.map((f, i) => {
                    return (
                      <div key={i} className="mb-4">
                        <div className="text-neutral-border-3 p-3 border border-neutral-border-1 flex justify-center items-center rounded-t-lg bg-neutral-surface-1">
                          <Typography className="font-semibold text-sm">
                            {`Lender ${i + 1} Request Detail`}
                          </Typography>
                        </div>
                        <div className="p-4 flex flex-col border border-t-0 border-neutral-border-1 rounded-b-lg">
                          <div className="gap-8 grid grid-cols-3 items-center mb-2">
                            <Typography className="text-sm">Ratio</Typography>
                            <div className="col-span-2">
                              <Typography className="text-sm">
                                {Intl.NumberFormat(undefined, {
                                  style: 'percent',
                                  maximumFractionDigits: 2,
                                }).format(
                                  activeFacility?.[
                                    `lender_${i + 1}_advance_request_ratio`
                                  ] ?? 0
                                )}
                              </Typography>
                            </div>
                          </div>
                          <div className="gap-8 grid grid-cols-3 items-center mb-2">
                            <Typography className="text-sm">Amount</Typography>
                            <div className="col-span-2">
                              <Typography className="text-sm">
                                {`${Intl.NumberFormat(undefined, {
                                  style: 'decimal',
                                  maximumFractionDigits: 2,
                                }).format(
                                  amount *
                                    (activeFacility?.[
                                      `lender_${i + 1}_advance_request_ratio`
                                    ] ?? 0)
                                )} ${currency}`}
                              </Typography>
                            </div>
                          </div>
                          <div className="gap-8 grid grid-cols-3 items-center">
                            <Typography className="text-sm mb-2">
                              Additional Note
                            </Typography>
                            <div className="col-span-2">
                              <FormInput
                                type="textarea"
                                value={getValues(`lenders.${i}.comment`) || ''}
                                {...register(`lenders.${i}.comment`, {
                                  onChange: e => {
                                    setValue(
                                      `lenders.${i}.comment`,
                                      e.target.value,
                                      {
                                        shouldValidate: true,
                                      }
                                    )
                                  },
                                })}
                                error={
                                  errors?.[`lenders.${i}.comment`]
                                    ?.message as string
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
            {category?.subcategory === 'default' && (
              <div className="mb-4">
                <div className="text-neutral-border-3 p-3 border border-neutral-border-1 flex justify-center items-center rounded-t-lg bg-neutral-surface-1">
                  <Typography className="font-semibold text-sm">
                    Disclosure Notice
                  </Typography>
                </div>
                <div className="p-4 flex flex-col border border-t-0 border-neutral-border-1 rounded-b-lg">
                  <div className="gap-8 grid grid-cols-3 items-center">
                    <div className="col-span-3">
                      <FormInput
                        type="textarea"
                        value={getValues('disclosure')}
                        disabled={isProcessing}
                        {...register(`disclosure`, {
                          onChange: e => {
                            setValue(`disclosure`, e.target.value, {
                              shouldValidate: true,
                            })
                          },
                        })}
                        error={errors?.[`disclosure`]?.message as string}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </DialogBody>
        <DialogFooter className="justify-center">
          <Button
            type="submit"
            color="primary"
            disabled={!isValid || isProcessing}
          >
            {isProcessing && (
              <ArrowPathIcon className="w-4 h-4 mr-2 text-primary-main animate-spin" />
            )}
            Request
          </Button>
        </DialogFooter>
      </form>
    </Dialog>
  )
}
