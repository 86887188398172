/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import Chart from '@components/chart'
import MultiToggleLayout from '@components/layouts/multi-toggle/multi-toggle-layout'
import Table from '@components/table'
import { REMOUNT_MS } from '@constants/config'
import AuthContext from '@contexts/auth'
import { numberFormatter } from '@helpers/number-formatter'
import { RiskDataItem } from '@interfaces/analytics-risk'
import { getStaleMins } from '@helpers/stale-timer'
import { Filter, FilterItem } from '@interfaces/analytics-risk-filter-type-key'
import { RisksFilters } from '@interfaces/analytics-risk'
import { historicalExchgList } from '../../common/historical-exchange-rate'
import { seriesSorter } from '@helpers/number-formatter'
import { CurvesService } from '@services/api-analytics/risk-curves'

/**
 * It is the ratio of Unscheduled principal paid and the expected outstanding
 * balance at the end of the month.
 * @param param0
 * @returns Analytics/collection view
 */
const SingleMonthMortality = ({ exportable }: { exportable: boolean }) => {
  const { company, appliedFilters, optionFilters, activeFilters } =
    useContext(AuthContext)
  const { rates = {}, categoryTypeOptions = [] } = optionFilters

  const {
    dateStart,
    dateEnd,
    categoryTypes = [],
    activeType,
    currency = 'USD',
  } = appliedFilters

  const activeVal =
    categoryTypeOptions?.find((rf: Filter) => rf.type_key === activeType)
      ?.type_val ?? []

  const isAggregate = activeType === 'All'

  const filters: RisksFilters = {
    slug_name: company?.slug_name ?? '',
    date_from: moment(dateStart).format('YYYY-MM-DD'),
    date_to: moment(dateEnd).format('YYYY-MM-DD'),
    currency,
    filters: isAggregate ? [] : categoryTypes,
  }

  const { error, data, isFetching } = useQuery(
    ['SingleMonthMortality', filters],
    () => CurvesService.getSingleMonthMortality(filters),
    getStaleMins()
  )

  /** simulate processing to remount chart component */
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [data, currency])

  let maxVal = 0

  // Populate Chart Data
  const chartData = []

  for (const cohort in data?.data || {}) {
    const result = historicalExchgList(rates, '', cohort)
    let cohortData: RiskDataItem[] = []
    if (!data?.data[cohort as any]) {
      continue
    }
    cohortData = data?.data[cohort as any]

    if (isAggregate) {
      result.type_all = cohortData[0].val

      result.type_all_non_delinquent_principal_paid = cohortData[0]
        .non_delinquent_principal_paid as number

      result.type_all_principal_scheduled = cohortData[0]
        .principal_scheduled as number

      result.type_all_starting_outstanding_balance = cohortData[0]
        .starting_outstanding_balance as number

      if (result.type_all > maxVal) {
        maxVal = result.type_all
      }
    } else {
      // Assume return value MUST include all categoryTypes
      for (const categoryTypeObj of cohortData) {
        const categoryType = categoryTypeObj.id
        result[`type_${categoryType}`] = categoryTypeObj.val
        result[`type_${categoryType}_non_delinquent_principal_paid`] =
          categoryTypeObj.non_delinquent_principal_paid as number
        result[`type_${categoryType}_principal_scheduled`] =
          categoryTypeObj.principal_scheduled as number
        result[`type_${categoryType}_starting_outstanding_balance`] =
          categoryTypeObj.starting_outstanding_balance as number
        if (result[`type_${categoryType}`] > maxVal) {
          maxVal = result[`type_${categoryType}`]
        }
      }
    }
    chartData.push(result)
  }

  const series: any[] = isAggregate
    ? [
        {
          label: 'Single Month Mortality',
          tooltipValueFormat: '#.00a%',
          type: 'SmoothedXLineSeries',
          field: 'type_all',
        },
      ]
    : seriesSorter(
        [0, ...categoryTypes].map((ct: number) => {
          const typeVal = activeVal.find((av: FilterItem) => av.id === ct)
          const color = '#' + Math.floor(Math.random() * 16777215).toString(16)
          return {
            label: `${typeVal ? typeVal.type : 'All'}`,
            tooltipValueFormat: '#.00a%',
            field: `type_${ct}`,
            color,
            type: 'SmoothedXLineSeries',
            hasBullet: true,
          }
        })
      )

  const sub_series_headers = [
    series.reduce((accu: any, curr: any) => {
      return {
        ...accu,
        [`${curr.field}_non_delinquent_principal_paid`]:
          'Non Delinquent Principal Paid',
        [`${curr.field}_principal_scheduled`]: 'Principal Scheduled',
        [`${curr.field}_starting_outstanding_balance`]:
          'Starting Outstanding Balance',
        [curr.field]: 'SMM',
      }
    }, {}),
  ]

  const table_columns = [
    {
      title: 'Cohort',
      field: 'x',
      align: 'center',
      className: 'sticky left-0 min-w-[150px] bg-white',
      render: (r: any) => {
        return r.x ? moment.utc(r.x).format('YYYY-MM-DD') : ''
      },
    },
    ...series.reduce((accu, curr) => {
      const categoryType = curr.field
      const label = curr.label
      const localColumns = [
        '',
        '_non_delinquent_principal_paid',
        '_principal_scheduled',
        '_starting_outstanding_balance',
      ].map(suffix => {
        const field = categoryType + suffix
        return {
          className: 'min-w-[150px]',
          field: field,
          title: label + ` +`,
          align: 'right',
          head: { colSpan: suffix === '' ? 4 : 0, align: 'center' },
          render: (r: any) => numberFormatter(r[field]),
        }
      })

      return [...accu, ...localColumns]
    }, []),
  ]

  const staticToolTipText = (
    <span>
      <span className="font-semibold">Description:</span>
      <br />
      <br />
      Single Month Mortality (SMM) is the ratio of Unscheduled principal paid
      and the expected outstanding balance at the end of the month.
      <br />
      <br />
      <span className="font-semibold">Formula:</span>
      <br />
      <br />
      Unscheduled Principal / (Start of month outstanding balance - Scheduled
      principal) <br />
      <br />
      - Unscheduled Principal: Actual principal paid - the scheduled principal,
      floored at zero <br />
      <br />
      - Scheduled Principal: Expected principal due in the month.
      <br />
      <br />- Start of Month Balance: Outstanding balance of all active
      loans(excluding written off loans from previous months)
    </span>
  )

  return (
    <MultiToggleLayout
      toggles={false}
      staticTipInfo={staticToolTipText}
      chart={
        <Chart
          loading={isFetching || isProcessing}
          id={`SingleMonthMortality_${activeType}`}
          yLabel="Percentage"
          yFormat="#.00a%"
          ySetting={{ maxPrecision: 1 }}
          data={chartData as any}
          series={series as any}
          exportable={exportable}
          exportableColumn={table_columns}
          error={error as { message: string }}
        />
      }
      toggleData={
        <Table
          containerClass="[&>table>thead>tr>td]:text-center [&>table>thead>tr>td]:font-semibold"
          loading={isFetching || isProcessing}
          data={chartData}
          columns={table_columns}
          headerData={sub_series_headers}
        />
      }
    />
  )
}

export default SingleMonthMortality
