import React, { useContext } from 'react'
import { useQuery } from 'react-query'

import { DASHBOARD_LAYOUT_LOCAL_STORAGE_KEY } from '@constants/config'
import AuthContext from '@contexts/auth'
import { getFromLS, saveToLS } from '@helpers/local-storage'
import { getStaleMins } from '@helpers/stale-timer'
import { DashboardResponse } from '@interfaces/manage-monitor-dashboard'
import { FacilityFilter } from '@interfaces/manage-monitor-filter-facility'
import { DashboardService } from '@services/api-manage/monitor-dashboard'

import Overview from '../overview/overview'

import MonitorDashboardTabL4 from './tab'

export const useDashboard = (activeFacilityId: string) => {
  const { company, optionFilters } = useContext(AuthContext)

  const { facilities = [] } = optionFilters
  const activeFacility = facilities?.find(
    (f: FacilityFilter) => f.facility_id === Number(activeFacilityId)
  )
  const isMasterFacility = activeFacility?.is_master_facility

  const filters = {
    slug_name: company?.slug_name ?? '',
    facility: activeFacilityId,
  }

  const {
    data: covenantData,
    isFetching: isFetchingCovenants,
    error,
  } = useQuery(
    ['covenant-list', filters],
    () => DashboardService.getDashboards(filters),
    {
      ...getStaleMins(),
      enabled: !!activeFacilityId && !!company?.slug_name,
    }
  )

  const { data: childFacilitiesData, isFetching: isFetchingChildFacilities } =
    useQuery(
      ['master-child-facilities', [filters.slug_name, filters.facility]],
      () =>
        DashboardService.getMasterFacilityOverviewData(
          filters.slug_name,
          Number(filters.facility)
        ),
      { ...getStaleMins(), enabled: !!isMasterFacility }
    )

  if (!isMasterFacility) {
    const tabs = (covenantData ?? [])
      .reduce((p: any[], c: DashboardResponse) => {
        if (p.find(x => x.label === c.tab)) {
          return p
        }
        return [
          ...p,
          {
            label: c.tab,
            component: (
              <MonitorDashboardTabL4
                items={(covenantData ?? []).filter(x => x.tab === c.tab)}
              />
            ),
          },
        ]
      }, [])
      .sort((a, b) => (a.label < b.label ? -1 : 1))

    return {
      data: covenantData,
      isFetching: isFetchingCovenants,
      tabs,
      activeFacilityId,
      error,
    }
  } else {
    const tabs: any = (childFacilitiesData ?? []).map(f => {
      return {
        label: f.facility_name,
        component: <Overview facility={f} />,
      }
    })
    return {
      data: childFacilitiesData,
      isFetching: isFetchingChildFacilities,
      tabs,
      activeFacilityId,
      error,
    }
  }
}

export const getInitDashboardLayout = () => {
  const ls = getFromLS(DASHBOARD_LAYOUT_LOCAL_STORAGE_KEY)
  return ls
}

export const saveDashboardLayoutToLs = (key: string, value: any) => {
  const ls = getFromLS(DASHBOARD_LAYOUT_LOCAL_STORAGE_KEY) || {}
  const updatedLs = { ...ls, [key]: value }
  saveToLS(DASHBOARD_LAYOUT_LOCAL_STORAGE_KEY, updatedLs)
}
