import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import FilterDateRange from '@components/filters/filter-date-range'
import FilterCurrency from '@components/filters/filter-dates-currency'
import FilterFinancialDisplay from '@components/filters/filter-financial-display'
import L3TabsLayout from '@components/layouts/l3-tabs-layout'
import { filterDateAsOfType } from '@constants/filter'
import AuthContext from '@contexts/auth'
import { useUserAccessFeature } from '@helpers/auth-provider'
import { getStaleMins } from '@helpers/stale-timer'
import { EntityInfo } from '@interfaces/financial'
import { Alert } from '@material-tailwind/react'
import { StatementService } from '@services/api-analytics/financials-statement'

import BalanceSheetL4 from './balance-sheet'
import CashflowL4 from './cashflow'
import CustomSheetL4 from './custom-sheet'
import ProfitAndLossL4 from './profit-and-loss'

/**
 * Tabs are generated in-order from the data with no order. Thus we impose
 * component order with an integer key.
 * @param data
 * @returns Array of component objects.
 */

const getTabs = (data: string[]): any[] => {
  const { filter_access } = useUserAccessFeature()
  const FEATURE = `analytics_financials_statements`
  const components = new Map<string, any>()

  data?.map(sheet => {
    switch (sheet) {
      case 'balance_sheet':
      case 'balance_sheet_raw':
        components.set('balance_sheet', {
          label: 'Balance Sheet',
          component: <BalanceSheetL4 />,
          ordering: 1,
        })
        break
      case 'profit_and_loss':
      case 'profit_and_loss_raw':
        components.set('profit_and_loss', {
          label: 'Profit & Loss',
          component: <ProfitAndLossL4 />,
          ordering: 2,
        })
        break
      case 'cascade_cashflow':
      case 'cascade_cashflow_raw':
        components.set('cascade_cashflow', {
          label: 'Cashflow',
          component: <CashflowL4 />,
          ordering: 3,
        })
        break
      case 'custom':
        components.set('custom', {
          label: 'Custom Sheet',
          component: <CustomSheetL4 />,
          ordering: 4,
        })
        break
      default:
        break
    }
  })

  const sortedComponents = Array.from(components.values()).sort((a, b) => {
    return a.ordering - b.ordering
  })

  // After sorting we can remove the ordering key from each component object
  sortedComponents.forEach(componentObject => {
    delete componentObject.ordering
  })

  return sortedComponents.filter(t =>
    filter_access(`${FEATURE}_${t.label.toLowerCase().split(' ').join('-')}`)
  )
}

const StatementsL3 = () => {
  const {
    activeFilters,
    appliedFilters,
    company,
    optionFilters,
    setAppliedFilters,
  } = useContext(AuthContext)
  const { entityStartDate, entityEndDate, range, financialEntityID } =
    activeFilters
  const { financialEntities } = optionFilters

  const { data, isFetching } = useQuery(
    [
      'available-statements',
      company?.slug_name,
      appliedFilters.financialEntityID,
    ],
    () =>
      StatementService.getFinancialsAvailableStatements(
        company?.slug_name,
        appliedFilters.financialEntityID
      ),
    {
      ...getStaleMins(),
      enabled: !!company?.slug_name,
    }
  )

  const activeEntity = financialEntities?.find(
    (entity: EntityInfo) => entity.entity_id == financialEntityID
  )
  /**
   * observe whats filter required at initiation
   */
  const [filterReady, setFilterReady] = useState<boolean>(false)
  useEffect(() => {
    if (
      !filterReady &&
      entityStartDate &&
      entityEndDate &&
      financialEntityID &&
      range !== undefined
    ) {
      //carries filters over unless no previously applied filters
      JSON.stringify(appliedFilters) !== JSON.stringify({ activeFilters }) &&
        setAppliedFilters(activeFilters)

      setFilterReady(true)
    }
  }, [activeFilters])

  useEffect(() => {
    setFilterReady(false)
  }, [company?.slug_name, financialEntities])

  let tabs = []

  const start = appliedFilters?.entityStartDate
    ? moment.utc(appliedFilters?.entityStartDate).format('yyyy-MM-DD')
    : undefined
  const end = appliedFilters?.entityEndDate
    ? moment.utc(appliedFilters?.entityEndDate).format('yyyy-MM-DD')
    : undefined
  /**
   * observe reset button disable state criteria
   */
  const disableReset =
    financialEntities?.[0]?.entity_id === financialEntityID &&
    (activeEntity?.currency === appliedFilters.currency ||
      appliedFilters.currency ===
        company?.currencies_available?.[0]?.to_currency) &&
    activeEntity?.start_date === start &&
    activeEntity?.end_date === end

  if (!isFetching) {
    if (data?.length) {
      tabs = getTabs(data)
    } else {
      tabs = [
        {
          label: '',
          component: (
            <div
              className={`flex flex-col w-full min-h-[400px] justify-center items-center`}
            >
              <Alert className="w-1/2 text-neutral-subtitle-3 border border-primary-border text-center">
                No available data to display
              </Alert>
            </div>
          ),
        },
      ]
    }
  }

  return (
    <>
      <L3TabsLayout
        title="Statements"
        filters={{
          left: <FilterCurrency dateAsOfType={filterDateAsOfType.financials} />,
          middle: <FilterDateRange type={'financials'} />,
          right: <FilterFinancialDisplay />,
        }}
        disableReset={disableReset}
        tabs={tabs}
      />
    </>
  )
}
export default StatementsL3
