import React from 'react'

import FilterFacility from '@components/filters/filter-facility'
import L3TabsLayout from '@components/layouts/l3-tabs-layout'

import { FacilityPartyDetailsAdmin } from './components/facility-party-details'
import { ReportingCategoriesAdmin } from './components/reporting-categories-admin/reporting-categories-admin'
import { ReportingScheduleAdmin } from './components/reporting-schedule'

const FacilityDetailsL3 = () => {
  const tabs = [
    {
      label: 'Reporting Categories',
      component: <ReportingCategoriesAdmin />,
    },
    {
      label: 'Facility Party Details',
      component: <FacilityPartyDetailsAdmin />,
    },
    {
      label: 'Reporting Schedule',
      component: <ReportingScheduleAdmin />,
    },
  ]
  return (
    <L3TabsLayout
      title="Manage Admin"
      filters={{
        left: <FilterFacility />,
      }}
      tabs={tabs}
    />
  )
}

export default FacilityDetailsL3
