import { formatInTimeZone } from 'date-fns-tz'

import { ChartDataBuilder } from '@helpers/chart-data-builder'
import {
  numberFormatter,
  numberFormatterInPercentage,
} from '@helpers/number-formatter'
import { GetAnalyticsProResponseDTO } from '@interfaces/analytics-pro'
import { Filter, FilterItem } from '@interfaces/analytics-risk-filter-type-key'

function reduceStackedData(data: any) {
  return (existingIndex: number, acc: any, curr: any, xValue: any) => {
    if (existingIndex >= 0) {
      acc[existingIndex][
        curr.data[data.series_field ?? '' + data.filter_field ?? '']
      ] = curr.data[data.y_axis_field]
    } else {
      const newItem: any = { x: xValue }
      curr.data.data?.forEach((item: any) => {
        newItem[
          item[data.series_field ?? ''] + ` : ${item[data.filter_field ?? '']}`
        ] = item[data.y_axis_field]
      })
      acc.push(newItem)
    }
  }
}
export class AnalyticsDataBuilder extends ChartDataBuilder {
  private activeValue: any

  constructor(
    data: GetAnalyticsProResponseDTO,
    calculationDate: string,
    activeType: any,
    categoryTypeOptions: any
  ) {
    super(data, calculationDate, reduceStackedData(data), 'chart_type')
    this.activeValue =
      categoryTypeOptions?.find((rf: Filter) => rf.type_key === activeType)
        ?.type_val ?? []
  }

  public build() {
    const filteredData = this.getFilteredData()
    const chartData = this.getChartData(filteredData)
    const triggerSeries = this.getTriggerSeries()

    const uniqueSeries: Set<any> = new Set<any>()
    this.data.data?.forEach((d: { data: { data: any[] } }) => {
      d.data.data?.forEach((element: any) => {
        uniqueSeries.add({
          field:
            element[this.data.series_field ?? ''] +
            ` : ${element[this.data.filter_field ?? '']}`,
          sanitized_field: element[this.data.series_field ?? ''],
          id: element[this.data.filter_field ?? ''],
        })
      })
    })
    const seriesData = Array.from(uniqueSeries).map(el => {
      return {
        [this.data.series_field ?? '']: el.field,
        [this.data.filter_field ?? '']: el.id,
        sanitized_field: el.sanitized_field,
      }
    })

    const unsortedChartSeries = [
      'stacked_area',
      'total_stacked_area',
      'total_stacked_graph',
      'stacked_graph',
      'combo_series',
      'line_graph',
    ].includes(this.data.chart_type)
      ? seriesData.reduce((acc: any[], curr: any) => {
          const typeVal = this.activeValue.find(
            (av: FilterItem) => av.id === curr[this.data.filter_field ?? '']
          )

          if (
            !acc.some(
              x =>
                x.label ===
                curr.sanitized_field +
                  `${!!typeVal ? ` : ${typeVal?.type}` : ''}`
            )
          ) {
            const seriesType = this.getType(curr[this.data.series_field ?? ''])

            acc.push({
              label:
                this.getLabels(curr.sanitized_field) +
                `${!!typeVal ? ` : ${typeVal?.type}` : ''}`,
              tooltipValueFormat: this.getTooltipLabelFormatting(
                curr[this.data.series_field ?? '']
              ),

              type: seriesType,
              oppositeYAxis:
                this.data.chart_type == 'combo_series' &&
                seriesType == 'SmoothedXLineSeries',
              isStack: ![
                'combo_series',
                'line_graph',
                'multiline_graph',
              ].includes(this.data.chart_type),
              isTotal: this.data.chart_type?.includes('total'),
              field: curr[this.data.series_field ?? ''],
              color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
              fill: ['stacked_area', 'total_stacked_area'].includes(
                this.data.chart_type
              ),
              hasBullet: false,
            })
          }
          return acc
        }, [])
      : this.getDefaultUnsortedSeries(triggerSeries)

    //ensures line series are plotted over any other series
    const chartSeries = unsortedChartSeries.sort(
      (a: { type: string }, b: { type: string }) =>
        a.type === 'SmoothedXLineSeries'
          ? 1
          : b.type === 'SmoothedXLineSeries'
          ? -1
          : 0
    )
    const xAxisType = this.getXAxisType()
    const secondaryYAxisConfig = this.getSecondaryYAxisConfig()
    const tableColumns = this.getTableColumns()
    const tableData = this.getTableData()

    const dataTableColumns = [
      {
        title: 'Date',
        field: 'x',
        align: 'center',
        width: 200,
        render: (r: any) => {
          return formatInTimeZone(r.x, 'UTC', 'yyyy-MM-dd')
        },
      },
      ...chartSeries.map((s: any) => {
        return {
          width: 200,
          align: 'right',
          field: s.field,
          title: s.label,
          render: (r: any) => {
            return this.data.y_axis_format.includes('%')
              ? numberFormatterInPercentage(r[s.field])
              : numberFormatter(r[s.field])
          },
        }
      }),
    ]

    return {
      unit: this.unit,
      data: this.data,
      chartData,
      allChartData: filteredData?.map((d: any) => d.data)[0],
      chartSeries,
      xAxisType,
      filteredChartData: chartData,
      ySetting: { minVal: this.minYAxisValue, maxVal: this.maxYAxisValue },
      xSetting: this.data.x_axis_setting,
      xAxisLabel: this.data.x_axis_label,
      yAxisLabel: this.data.y_axis_label,
      yAxisFormat: this.data.y_axis_format ?? '#.00a',
      title: this.data.chart_title,
      id: this.data.chart_id,
      displayName: this.data.chart_title,
      dateFilterField: this.data.calculation_date_filter_field,
      tableColumns: ['table'].includes(this.data.chart_type)
        ? tableColumns
        : dataTableColumns,
      tableData: ['table'].includes(this.data.chart_type)
        ? tableData
        : chartData,
      numberTableColumns: this.data?.data[0]?.cols,
      numberTableData: this.data?.data[0]?.data,
      centerField: this.data.center_field,
      centerLabel: this.data.center_label,
      categoryField: this.data.category_field,
      valueField: this.data.value_field,
      secondaryYAxisConfig,
    }
  }
}
