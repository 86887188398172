import { useContext } from 'react'
import { useQuery } from 'react-query'

import AuthContext from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import { Facility } from '@interfaces/facility'
import {
  CovenantDetailRequest,
  CovenantDetailResponse,
  CovenantListRequest,
  CovenantListResponse,
  CovenantType,
} from '@interfaces/manage-monitor-covenant'
import { FacilityFilter } from '@interfaces/manage-monitor-filter-facility'
import { CovenantService } from '@services/api-manage/monitor-covenant'

export const useCovenantList = ({
  type,
  facility,
}: {
  type: CovenantType
  facility?: Facility
}) => {
  const { company, appliedFilters, optionFilters } = useContext(AuthContext)

  const { activeFacilityId: appliedFacilityId } = appliedFilters
  const { facilities = [] } = optionFilters
  const appliedFacility = facilities?.find(
    (f: FacilityFilter) => f.facility_id === appliedFacilityId
  )
  const filters: CovenantListRequest = {
    slug_name:
      facility?.slug_name ??
      appliedFacility?.slug_name ??
      company?.slug_name ??
      '',
    facility_id: facility?.facility_id ?? appliedFacilityId,
    type,
  }

  const { data, isLoading } = useQuery(
    ['covenant-list', filters],
    () => CovenantService.getList(filters),
    {
      ...getStaleMins(),
      enabled: !!company?.slug_name && !!appliedFacilityId,
    }
  )
  return { data, isLoading }
}

export const getLabel = (covenant: CovenantDetailResponse) => {
  if (covenant?.params?.label) {
    return covenant.params.label
  } else {
    return ''
  }
}

export const getUnit = (covenant: CovenantListResponse) => {
  if (covenant?.unit) {
    return ' ' + covenant.unit
  }
  const covenantName = covenant?.covenant_name?.toLowerCase()

  switch (covenantName) {
    case 'minimum cash balance':
      return ' USD'
    case 'minimum cash runway':
      return 'm'
    default:
      return '%'
  }
}

export const getLabelFormat = (covenant: CovenantListResponse) => {
  if (covenant?.params?.label_format) {
    return covenant?.params?.label_format
  } else {
    return '#.00a%'
  }
}

export const useCovenantDetail = ({
  type,
  id,
}: {
  type: CovenantType
  id: number
}) => {
  const { company, appliedFilters } = useContext(AuthContext)
  const { activeFacilityId } = appliedFilters

  const filters: CovenantDetailRequest = {
    slug_name: company?.slug_name ?? '',
    facility_id: activeFacilityId,
    type,
    id,
  }

  const { data, isLoading } = useQuery(
    ['covenant-detail', filters],
    () => CovenantService.getDetail(filters),
    {
      ...getStaleMins(),
      enabled: !!company?.slug_name && !!activeFacilityId && !!id,
    }
  )
  return { data, isLoading }
}

export const useCovenantAudit = ({
  type,
  id,
  open,
}: {
  type: CovenantType
  id: number
  open: boolean
}) => {
  const { company, appliedFilters } = useContext(AuthContext)
  const { activeFacilityId } = appliedFilters

  const filters: CovenantDetailRequest = {
    slug_name: company?.slug_name ?? '',
    facility_id: activeFacilityId,
    type,
    id,
  }

  const { data, isLoading } = useQuery(
    ['covenant-raw', filters],
    () => CovenantService.getRaw(filters),
    {
      ...getStaleMins(),
      enabled: !!company?.slug_name && !!activeFacilityId && !!id && open,
    }
  )
  return { data, isLoading }
}

export const getRandomColor = () =>
  '#00' +
  Math.floor(Math.random() * 16777215)
    .toString(16)
    .slice(2, -2) +
  'ff'

export const getMultiplier = (covenant: CovenantListResponse) => {
  if (
    covenant?.params.multiplier &&
    !['Vintage', 'Table'].includes(covenant.params.special_covenent)
  ) {
    return Number(covenant?.params.multiplier)
  } else {
    return 1
  }
}
