import React, { useContext } from 'react'
import { useQuery } from 'react-query'

import Table from '@components/table'
import AuthContext from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { Tooltip } from '@material-tailwind/react'
import { ValidationService } from '@services/api-data-validation/validation'
import { ValidationTest } from '@services/api-data-validation/validation'

const statusAppearance: { [key: string]: string } = {
  active: 'text-neutral-white bg-success-main border-success-main',
  inactive: 'text-neutral-body-1 bg-cc-secondary-hover-selected border-none',
}

const SummaryTable = () => {
  const { company } = useContext(AuthContext)

  const { data, isLoading } = useQuery(
    ['quarantine-tests', company?.slug_name],
    () => {
      const slugName = company?.slug_name
      if (slugName) {
        return ValidationService.getSummaries(slugName)
      }
    },
    { ...getStaleMins(), enabled: !!company?.slug_name }
  )

  const entityColors = {
    loans: 'border-orange bg-orange text-orange',
    customers: 'border-blue bg-blue text-blue',
    transactions: 'border-purple bg-purple text-purple',
  } as const

  const getColor = (s: keyof typeof entityColors) => entityColors[s]

  const columns = [
    {
      title: 'Test Name',
      align: 'start',
      field: 'name',
      render: (record: ValidationTest) => {
        const affectedColumns = record.columns.map((c, i) => (
          <div
            key={i}
            className="px-2 py-0.5 border text-gray-700 text-[13px] bg-opacity-10 rounded-[0.25rem] italic"
          >
            {c}
          </div>
        ))

        affectedColumns.unshift(<div className="py-0.5">Affected Columns:</div>)
        return (
          <div className="flex gap-3 items-center">
            <div>{record.name}</div>
            <div className="w-5">
              <Tooltip
                content={
                  <div className="flex flex-col gap-3 text-black">
                    <div>{record.description}</div>
                    <div className="flex flex-wrap gap-2">
                      {affectedColumns}
                    </div>
                  </div>
                }
                placement="right"
                animate={{
                  mount: { opacity: 1, y: 0 },
                  unmount: { opacity: 0, y: 25 },
                }}
                className="bg-white border border-cc-primary-border shadow-xl"
              >
                <InformationCircleIcon className="w-5" />
              </Tooltip>
            </div>
          </div>
        )
      },
    },
    {
      title: 'Severity',
      field: 'severity',
      align: 'center',
      width: '130',
    },
    {
      title: 'Entity',
      align: 'center',
      field: 'entity',
      width: '150',
      render: (record: ValidationTest) => {
        const color = getColor(record.entity)
        return (
          <div
            className={`px-2 py-0.5 border  bg-opacity-10 rounded-[0.25rem]  w-[100px] capitalize
                ${
                  record.status == 'inactive'
                    ? `border-cc-primary-border bg-cc-primary-border text-cc-primary-border`
                    : color
                } `}
          >
            {record.entity}
          </div>
        )
      },
    },
    {
      title: 'Count',
      field: 'count',
      align: 'center',
    },
    {
      title: 'Status',
      align: 'center',
      width: '130',
      render: (record: ValidationTest) => {
        return (
          <div
            className={`rounded-full px-2 py-1 w-[70px] ${
              statusAppearance[record.status]
            } capitalize text-[13px]`}
          >
            {record.status}
          </div>
        )
      },
    },
  ]

  const severityOrder = ['EXCLUSION', 'WARNING', 'INFO']

  return (
    <Table
      columns={columns as any}
      data={data?.sort((a: any, b: any) => {
        const statusDifference = a.status.localeCompare(b.status)
        if (statusDifference !== 0) {
          return statusDifference
        }

        const severityDifference =
          severityOrder.indexOf(a.severity) - severityOrder.indexOf(b.severity)

        if (severityDifference !== 0) {
          return severityDifference
        }

        return a.description.localeCompare(b.description)
      })}
      loading={isLoading}
      clickable={false}
    />
  )
}

export default SummaryTable
