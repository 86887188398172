import React, { useContext, useEffect, useState } from 'react'
import { format } from 'date-fns'

import Typography from '@components/atoms/typography'
import Chart from '@components/chart'
import { REMOUNT_MS } from '@constants/config'
import AuthContext from '@contexts/auth'
import { DashboardResponse } from '@interfaces/manage-monitor-dashboard'

import { ManageDataBuilder } from '../manage-chart-data-builder'

const MonitorDashboardStackedAreaItem = ({
  item,
}: {
  item: DashboardResponse
}) => {
  const { appliedFilters } = useContext(AuthContext)
  const { calculationDate } = appliedFilters
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  const builder = new ManageDataBuilder(item, calculationDate)
  const {
    chartSeries,
    unit,
    filteredChartData,
    xSetting,
    xAxisType,
    yAxisLabel,
    yAxisFormat,
    title,
    dashboardId,
    displayName,
    dateFilterField,
    xAxisLabel,
    ySetting,
  } = builder.build()

  /** simulate processing to remount chart component when filters change */
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [appliedFilters])

  return (
    <>
      {displayName && (
        <Typography className="text-center text-lg font-semibold">
          {displayName}{' '}
          {dateFilterField &&
            calculationDate &&
            `as of ${format(calculationDate, 'yyyy-MM-dd')}`}
        </Typography>
      )}
      <Chart
        data={filteredChartData}
        series={chartSeries}
        loading={isProcessing}
        yLabel={`${yAxisLabel} ${unit ? `(${unit})` : ''}`}
        yFormat={yAxisFormat ?? '#.00a'}
        xSetting={xSetting}
        ySetting={ySetting}
        xLabel={xAxisLabel}
        id={`${title}-${dashboardId}`}
        exportable={false}
        xAxisType={xAxisType}
      />
    </>
  )
}
export default MonitorDashboardStackedAreaItem
