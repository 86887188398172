import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import Typography from '@components/atoms/typography'
import ExchangeRateBadge from '@components/exchange-rate-badge'
import AuthContext from '@contexts/auth'
import { useDisbursementCurrencies } from '@helpers/currency-hook'
import { getStaleMins } from '@helpers/stale-timer'
import {
  ArrowPathIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline'
import { RisksFilters } from '@interfaces/analytics-risk'
import { Filter, FilterItem } from '@interfaces/analytics-risk-filter-type-key'
import IndicatorService from '@services/api-analytics/risk-indicator'

const IndicatorL4 = () => {
  const showHistoricalRateIndicator = useDisbursementCurrencies()
  const { company, appliedFilters, optionFilters } = useContext(AuthContext)
  const {
    dateStartCohort,
    dateEndCohort,
    categoryTypes,
    currency = 'USD',
    activeType,
  } = appliedFilters
  const { categoryTypeOptions = [] } = optionFilters

  const isAggregate = activeType === 'All'
  const activeVal =
    categoryTypeOptions?.find((rf: Filter) => rf.type_key === activeType)
      ?.type_val ?? []
  const numberOfCategory = isAggregate ? 1 : categoryTypes?.length ?? 0

  const filters: RisksFilters = {
    date_from: moment(dateStartCohort).format('YYYY-MM-DD'),
    date_to: moment(dateEndCohort).format('YYYY-MM-DD'),
    slug_name: company?.slug_name ?? '',
    filters: isAggregate ? [] : categoryTypes,
    currency,
  }

  const { data: fetchedData, isFetching } = useQuery(
    ['key-indicator', filters],
    () => IndicatorService.getMetric(filters),
    {
      ...getStaleMins(),
      enabled:
        !!currency &&
        !!company?.slug_name &&
        !!dateStartCohort &&
        !!dateEndCohort,
    }
  )

  const render_unit = (label: string, value?: any) => {
    if (
      ['avg monthly volume', 'outstanding portfolio', 'avg loan size'].some(x =>
        label.toLowerCase().includes(x)
      )
    ) {
      return currency
    }
    if (
      ['apr', 'headline', 'par', 'bad 90'].some(x =>
        label.toLowerCase().includes(x)
      )
    ) {
      return `%`
    }
    if (['term'].some(x => label.toLowerCase().includes(x))) {
      return (value ?? 0) >= 2 ? `months` : `days`
    }
    return ``
  }

  const transform_value = (label: string, value: any) => {
    if (['term'].some(x => label.toLowerCase().includes(x))) {
      return (value ?? 0) >= 2 ? value : value * 30
    }
    return value
  }

  const { data = {} }: any = fetchedData ?? {}
  const table_data: any = Object.keys(data).reduce((p, c) => {
    return {
      ...p,
      [c]: data[c].reduce((_p: any, _c: any) => {
        return {
          ..._p,
          [_c.label]: {
            ..._p[_c.label],
            [_c.id]: _c,
          },
        }
      }, {}),
    }
  }, {})
  const [activeRows, setActiveRows] = useState<number[]>(
    Object.keys(data).map((_, i) => i)
  )
  const [hoverCell, setHoverCell] = useState<string | undefined>(undefined)

  useEffect(() => {
    setActiveRows(Object.keys(data).map((_, i) => i))
  }, [isFetching])

  return (
    <>
      {showHistoricalRateIndicator && (
        <div className="flex mb-4">
          <ExchangeRateBadge isHistorical={false} />
        </div>
      )}
      {isFetching && (
        <div className="w-full h-[300px] flex justify-center items-center gap-4">
          <ArrowPathIcon className="animate-spin text-primary-main w-4" />
          <Typography>Loading</Typography>
        </div>
      )}
      {!isFetching && (
        <div
          className={`overflow-auto rounded-md border border-neutral-border-1`}
        >
          <table className="border-separate border-spacing-0">
            <thead className="sticky top-0">
              <th
                className={`lg:min-w-[250px] sticky left-0 p-3 border-b border-r border-neutral-border-1 p-3  bg-neutral-surface-1 text-sm font-semibold`}
              ></th>
              {isAggregate ? (
                <>
                  <th
                    className={`min-w-[200px] p-3 border-b border-r border-neutral-border-1 text-sm font-semibol ${
                      hoverCell?.endsWith('-val')
                        ? 'bg-cc-secondary-hover-selected'
                        : 'bg-neutral-white'
                    }`}
                  >
                    All
                  </th>
                  <th
                    className={`min-w-[200px] p-3 border-b border-r border-neutral-border-1 text-sm font-semibol ${
                      hoverCell?.endsWith('-percentage')
                        ? 'bg-cc-secondary-hover-selected'
                        : 'bg-neutral-white'
                    }`}
                  >
                    Percent of Total Portfolio
                  </th>
                </>
              ) : (
                Array(numberOfCategory)
                  .fill('')
                  .map((_, i) => {
                    const activeTypeTitle = activeVal?.find(
                      (t: FilterItem) => t.id === categoryTypes[i]
                    )
                    return (
                      <React.Fragment key={i}>
                        <th
                          className={`min-w-[150px] p-3 border-b border-r border-neutral-border-1 text-sm font-semibol ${
                            hoverCell?.endsWith(`-${i}-val`)
                              ? 'bg-cc-secondary-hover-selected'
                              : i % 2 === 0
                              ? 'bg-neutral-surface-2'
                              : 'bg-neutral-surface-1'
                          }`}
                        >
                          {activeTypeTitle?.type}
                        </th>
                        <th
                          className={`min-w-[150px] p-3 border-b border-r border-neutral-border-1 text-sm font-semibol ${
                            hoverCell?.endsWith(`-${i}-percentage`)
                              ? 'bg-cc-secondary-hover-selected'
                              : i % 2 === 0
                              ? 'bg-neutral-surface-2'
                              : 'bg-neutral-surface-1'
                          }`}
                        >
                          Percentage of Portfolio
                        </th>
                      </React.Fragment>
                    )
                  })
              )}
            </thead>

            <tbody>
              {Object.keys(table_data).map((td, i) => {
                const isOpen = activeRows.includes(i)
                const Icon = isOpen ? ChevronDownIcon : ChevronRightIcon
                return (
                  <React.Fragment key={`row-${i}`}>
                    <tr
                      onClick={() => {
                        const nextActiveRow = isOpen
                          ? activeRows.filter(x => x !== i)
                          : [...activeRows, i]
                        setActiveRows(nextActiveRow)
                      }}
                      className="cursor-pointer"
                    >
                      <td className="sticky left-0 text-sm p-3 bg-neutral-white">
                        <div className="flex">
                          <Icon className="w-4 h-4" />
                          <span className="ml-4">{td}</span>
                        </div>
                      </td>
                      <td
                        className="bg-neutral-white border-r border-neutral-border-1"
                        colSpan={numberOfCategory * 2}
                      ></td>
                    </tr>
                    <tr>
                      <td colSpan={numberOfCategory * 2 + 1} className="p-0">
                        <hr />
                      </td>
                    </tr>

                    {/* subrows */}
                    {Object.keys(table_data[td])?.map((c: any, j: number) => {
                      return (
                        <tr
                          data-testid="table-row"
                          key={`row-${i}-${j}`}
                          className={`[&>td]:transition-all ${
                            isOpen
                              ? '[&>td]:leading-6'
                              : '[&>td]:leading-[0px] [&>td]:overflow-hidden [&>td]:whitespace-nowrap [&>td]:p-0 [&>td]:border-none'
                          }`}
                        >
                          <td
                            data-testid="table-sticky-cell"
                            className={`sticky left-0 text-sm p-3 pl-12 border-b border-r border-neutral-border-1 ${
                              hoverCell?.startsWith(`${i}-${j}-`)
                                ? 'bg-cc-secondary-hover-selected'
                                : 'bg-neutral-surface-1'
                            }`}
                          >
                            {c}
                          </td>
                          {Object.keys(table_data[td][c]).map(
                            (column: any, k: any) => {
                              const current_data = table_data[td][c][column]
                              const neutralBackgroundClass = isAggregate
                                ? 'bg-neutral-white'
                                : k % 2 === 0
                                ? 'bg-neutral-surface-2'
                                : 'bg-neutral-surface-1'
                              const hoveredCellIndex = hoverCell?.split('-')
                              /**
                               * hover when:
                               * i === group && row === hoverRow && k < hoverColumn
                               * i === group && row < hoverRow && k === hoverColumn
                               * i < group && k === hoverColumn
                               * active when:
                               * i === group && row === hoverRow && k === hoverColumn
                               */
                              const isSiblingHovered =
                                hoveredCellIndex &&
                                ((i === parseFloat(hoveredCellIndex?.[0]) &&
                                  j === parseFloat(hoveredCellIndex?.[1]) &&
                                  k < parseFloat(hoveredCellIndex?.[2])) ||
                                  (i === parseFloat(hoveredCellIndex?.[0]) &&
                                    j < parseFloat(hoveredCellIndex?.[1]) &&
                                    k === parseFloat(hoveredCellIndex?.[2])) ||
                                  (i < parseFloat(hoveredCellIndex?.[0]) &&
                                    k === parseFloat(hoveredCellIndex?.[2])))
                              const isHovered =
                                hoveredCellIndex &&
                                i === parseFloat(hoveredCellIndex?.[0]) &&
                                j === parseFloat(hoveredCellIndex?.[1]) &&
                                k === parseFloat(hoveredCellIndex?.[2])

                              const isSiblingPercentageHovered =
                                isSiblingHovered &&
                                (hoverCell?.includes('-percentage') ||
                                  (i === parseFloat(hoveredCellIndex?.[0]) &&
                                    j === parseFloat(hoveredCellIndex?.[1])))
                              const isPercentageHovered =
                                isHovered && hoverCell?.includes('-percentage')

                              const isSiblingValHovered =
                                isSiblingHovered &&
                                (hoverCell?.includes('-val') ||
                                  (i === parseFloat(hoveredCellIndex?.[0]) &&
                                    j === parseFloat(hoveredCellIndex?.[1])))
                              const isValHovered =
                                isHovered && hoverCell?.includes('-val')

                              return (
                                <React.Fragment key={`column-${i}-${j}-${k}`}>
                                  <td
                                    data-testid="table-cell-number"
                                    className={`p-3 text-sm border-b border-r border-neutral-border-1 text-right ${
                                      isValHovered
                                        ? 'bg-yellow'
                                        : isSiblingValHovered ||
                                          isPercentageHovered
                                        ? 'bg-cc-secondary-hover-selected'
                                        : neutralBackgroundClass
                                    }`}
                                    onMouseEnter={() => {
                                      setHoverCell(`${i}-${j}-${k}-val`)
                                    }}
                                    onMouseLeave={() => {
                                      setHoverCell(undefined)
                                    }}
                                  >
                                    {`${Intl.NumberFormat(undefined, {
                                      maximumFractionDigits:
                                        isPercentageCategory(td, c) ? 2 : 0,
                                      minimumFractionDigits: 0,
                                      style: 'decimal',
                                    }).format(
                                      transform_value(
                                        current_data.label,
                                        current_data.val
                                      )
                                    )} ${render_unit(
                                      current_data.label,
                                      current_data.val
                                    )}`}
                                  </td>
                                  <td
                                    data-testid="table-cell-percent"
                                    className={`p-3 text-sm border-b border-r border-neutral-border-1 text-right ${
                                      isPercentageHovered
                                        ? 'bg-yellow'
                                        : isSiblingPercentageHovered
                                        ? 'bg-cc-secondary-hover-selected'
                                        : neutralBackgroundClass
                                    }`}
                                    onMouseEnter={() => {
                                      setHoverCell(`${i}-${j}-${k}-percentage`)
                                    }}
                                    onMouseLeave={() => {
                                      setHoverCell(undefined)
                                    }}
                                  >
                                    {current_data.ratio
                                      ? `${Intl.NumberFormat(undefined, {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2,
                                          style: 'decimal',
                                          notation: 'compact',
                                        }).format(
                                          transform_value(
                                            current_data.label,
                                            current_data.ratio
                                          )
                                        )}%`
                                      : '-'}
                                  </td>
                                </React.Fragment>
                              )
                            }
                          )}
                        </tr>
                      )
                    })}
                  </React.Fragment>
                )
              })}
            </tbody>
          </table>
        </div>
      )}
    </>
  )
}

export default IndicatorL4

// Ideally we want to use index but i am not sure if different clients display different rows
const isPercentageCategory = (td: string, c: string): boolean => {
  const tdList = new Set(['Portfolio Characteristic', 'Portfolio Performance'])
  const cList = new Set([
    'Avg Headline Interest Rate',
    'Avg APR',
    'Avg Headline Interest Rate (Last 12m)',
    'Avg APR (Last 12m)',
    'PAR 30',
    'PAR 90',
    'Bad 90 @ 6 Months',
    'Bad 90 @ 12 Months',
  ])
  return tdList.has(td) && cList.has(c)
}
