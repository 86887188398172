import React, { useContext, useEffect, useState } from 'react'
import { AxiosError } from 'axios'
import moment from 'moment'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'

import Button from '@components/atoms/button'
import FormInput from '@components/form/form-input'
import AuthContext from '@contexts/auth'
import { formatDownloadURL } from '@helpers/downloadURL-formatter'
import { useToastNotification } from '@helpers/notification-hook'
import { getStaleMins } from '@helpers/stale-timer'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { ArrowPathIcon } from '@heroicons/react/24/solid'
import { Facility } from '@interfaces/facility'
import { CollateralListUploadRequest } from '@interfaces/manage-monitor-borrowing-base'
import { AllTableData } from '@interfaces/manage-reporting'
import {
  SignatureRequest,
  TemplateDialogProps,
} from '@interfaces/manage-signature'
import { Dialog, DialogBody } from '@material-tailwind/react'
import { pdf } from '@react-pdf/renderer'
import { PDFViewer } from '@react-pdf/renderer'
import { FacilityDetailsService } from '@services/api-manage/facility-details'
import { BorrowingBaseService } from '@services/api-manage/monitor-borrowing-base'
import { MonitorFilterService } from '@services/api-manage/monitor-filter'

import { CustomFields, SignerInput } from '../components'
import { getCustomData } from '../custom-data'

import { generateTables } from './data-helpers'
import { renderTemplate } from './templates'

export const BorrowingBaseCertAction = ({
  form,
  user,
  createEmbeddedSignRequest,
  uploadDocument,
  handler,
  dialogOpen,
  category,
  docData,
}: TemplateDialogProps) => {
  const { company, appliedFilters, optionFilters } = useContext(AuthContext)
  const { facilities = [] } = optionFilters
  const { activeFacilityId = 0 } = appliedFilters
  const activeFacility = facilities?.find(
    (f: Facility) => f.facility_id === activeFacilityId
  )
  const [guarantors, setGuarantors] = useState<string>('')
  const [agent, setAgent] = useState<string>('')
  const [borrower, setBorrower] = useState<string>('')
  const [dateOptions, setDateOptions] = useState<any>([])
  const [fileUrl, setFileUrl] = useState<string>('')
  const [calculationDate, setCalculationDate] = useState<string>(
    docData?.effective_date
      ? moment.utc(docData.effective_date).format('YYYY-MM-DD')
      : ''
  )
  const { displayToastError } = useToastNotification()

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid, isSubmitting },
    reset,
  } = form

  const { mutate: uploadFile } = useMutation(
    (params: CollateralListUploadRequest) => {
      return BorrowingBaseService.uploadCollateralReportData(params)
    },
    {
      onError: (err: AxiosError) => {
        displayToastError(
          err.response?.status,
          'Collateral report upload failed'
        )
      },
    }
  )

  const options = category?.signers ? JSON.parse(category.signers) : undefined

  const [isLoadingCustomData, customData] = getCustomData(category)

  /**
   * Get data and format
   */
  const {
    data: partyDetailsData,
    mutate: getPartyDetails,
    isLoading: isLoadingPartyDetails,
  } = useMutation(
    (params: { facility_id: number; facility: string; slug_name: string }) => {
      return FacilityDetailsService.getDealPartyDetailsRequest(params)
    }
  )

  const filters = {
    slug_name: activeFacility?.slug_name ?? company?.slug_name ?? '',
    facility_id: activeFacility?.facility_id ?? '',
    calculation_date: calculationDate
      ? moment.utc(calculationDate).format('YYYY-MM-DD')
      : moment.utc(dateOptions?.[0]).format('YYYY-MM-DD'),
  }

  const { data: validDates, isFetching: isFetchingCalcDates } = useQuery(
    ['validDate', filters],
    () => MonitorFilterService.calculationDate(filters),
    {
      ...getStaleMins(),
      enabled: !!activeFacility.facility_name,
    }
  )

  useEffect(() => {
    if (validDates?.length) {
      const dates = validDates
        .filter(e => e.facility_id === activeFacility.facility_id)
        .map(d => moment.utc(d.calculation_date).utc().format('YYYY-MM-DD'))
      setDateOptions(dates)
      if (!calculationDate) {
        setCalculationDate(dates[0])
      }
    }
  }, [validDates])

  const { data: borrowingBaseData, isFetching: isLoadingBorrowingBase } =
    useQuery(
      ['borrowing-base', filters],
      () => BorrowingBaseService.get(filters),
      { ...getStaleMins(), enabled: !!calculationDate || !!dateOptions?.length }
    )

  const allTableData: AllTableData[] = borrowingBaseData
    ? generateTables(borrowingBaseData)
    : []

  const isLoading =
    isFetchingCalcDates ||
    isLoadingBorrowingBase ||
    isLoadingPartyDetails ||
    isLoadingCustomData

  const onSubmit = (cd: any) => {
    if (!!options?.signers && !options.signers.length) {
      uploadDoc(cd)
    } else {
      createSignableDoc(cd)
    }
  }

  const uploadDoc = async (cd: any) => {
    const formData = new FormData()
    if (activeFacility?.slug_name || company?.slug_name) {
      formData.append(
        'slug_name',
        activeFacility?.slug_name ?? company?.slug_name
      )
    }
    formData.append(
      'effective_date',
      moment.utc(calculationDate).format('DD-MM-YYYY')
    )
    formData.append('category', 'Borrowing Base Certificate')
    formData.append(
      'title',
      `Borrowing Base Certificate - ${moment
        .utc(calculationDate)
        .format('DD MMMM yyyy')}`
    )
    formData.append('facility', activeFacility?.facility_name)
    formData.append('facility_id', activeFacility?.facility_id)
    user?.email && formData.append('author_email', `${user.email}`)
    user?.name && formData.append('author_name', `${user.name}`)
    formData.append('form_values', JSON.stringify(cd))
    formData.append(
      'files',
      await pdf(
        renderTemplate(category, {
          agent,
          borrower,
          guarantors,
          agreementDate: moment
            .utc(activeFacility?.agreement_date)
            .format('YYYY-MM-DD'),
          effectiveDate: moment
            .utc(calculationDate || dateOptions?.[0])
            .format('YYYY-MM-DD'),
          tableData: allTableData,
          fileUrl,
          customData,
          formData: cd,
        })
      ).toBlob()
    )

    uploadDocument(formData)
    handler()
    toast.loading('Request in progress', { autoClose: false })
    reset()
  }

  const createSignableDoc = async (cd: any) => {
    if (fileUrl && company?.slug_name && activeFacility?.facility_name) {
      const fileName = fileUrl.replace(/\n|\r/g, '').split('fileName=').pop()
      fileName &&
        uploadFile({
          facility_id: activeFacility?.facility_id,
          slug_name: activeFacility?.slug_name ?? company.slug_name,
          calculation_date:
            moment.utc(calculationDate).month() ===
              moment.utc(dateOptions?.[0]).month() || !calculationDate
              ? moment.utc(dateOptions[0]).format('YYYY-MM-DD')
              : moment.utc(calculationDate).endOf('month').format('YYYY-MM-DD'),
          file_name: fileName,
        })
    }
    const formData = new FormData()
    user?.email && formData.append('requester_email', `${user.email}`)
    user?.name && formData.append('requester_name', `${user.name}`)
    docData.id && formData.append('id', docData.id)
    if (activeFacility?.slug_name || company?.slug_name) {
      formData.append(
        'slug_name',
        activeFacility?.slug_name ?? company?.slug_name
      )
    }
    formData.append('facility', activeFacility?.facility_name)
    formData.append('category', 'Borrowing Base Certificate')
    formData.append(
      'effective_date',
      calculationDate
        ? moment.utc(calculationDate).format('DD-MM-YYYY')
        : moment.utc(dateOptions[0]).format('DD-MM-YYYY')
    )
    formData.append(
      'title',
      `Borrowing Base Certificate - ${moment
        .utc(calculationDate)
        .format('DD MMMM yyyy')}`
    )
    formData.append(
      'files',
      await pdf(
        renderTemplate(category, {
          agent,
          borrower,
          guarantors,
          agreementDate: moment
            .utc(activeFacility?.agreement_date)
            .format('YYYY-MM-DD'),
          effectiveDate: moment
            .utc(calculationDate || dateOptions?.[0])
            .format('YYYY-MM-DD'),
          tableData: allTableData,
          fileUrl,
          customData,
          formData: cd,
        })
      ).toBlob()
    )
    formData.append('subject', `Borrowing Base Certificate`)
    formData.append(
      'message',
      `${user?.name} has requested your signature via Cascade Debt`
    )
    formData.append(
      'signers',
      JSON.stringify(
        cd.signers.map(
          (s: { name: string; email: string; title?: string }, i: number) => ({
            name: s.name,
            order: options?.enforceSignOrder ? i : undefined,
            emailAddress: s.email,
          })
        )
      )
    )
    formData.append('form_values', JSON.stringify(cd))
    formData.append('tags', 'true')
    createEmbeddedSignRequest(formData as SignatureRequest)
    handler()
    toast.loading('Request in progress', { autoClose: false })
    reset()
  }

  useEffect(() => {
    if (dialogOpen) {
      const fileName = `${moment().unix()}_borrowing_base_certificate_collateral.xlsx`
      setFileUrl(
        formatDownloadURL(
          `${window.location.origin}/manage/monitor/actions?pid=${company?.slug_name}&facility=${activeFacility?.facility_id}&tab=document-centre&fileName=${fileName}`,
          90
        )
      )
    }
  }, [dialogOpen])

  useEffect(() => {
    if (dialogOpen && !!activeFacility.facility_name && company?.slug_name) {
      getPartyDetails({
        facility_id: activeFacility?.facility_id,
        facility: activeFacility?.facility_name,
        slug_name: activeFacility?.slug_name ?? company?.slug_name,
      })
    }
  }, [dialogOpen, activeFacility])

  useEffect(() => {
    if (partyDetailsData) {
      const guarantorsList = partyDetailsData
        .filter((entry: { type: string }) => entry.type === 'Guarantor')
        .map(entry => entry.legal_name as string)
      if (guarantorsList.length) {
        setGuarantors(guarantorsList.join(' and '))
      }
      const borrower = partyDetailsData.filter(
        (entry: { type: string }) => entry.type === 'Borrower'
      )?.[0]?.legal_name as string
      const agent = partyDetailsData.filter(
        (entry: { type: string }) => entry.type === 'Agent'
      )?.[0]?.legal_name as string
      setAgent(agent)
      setBorrower(borrower)
    }
  }, [partyDetailsData])

  return (
    <Dialog
      open={dialogOpen}
      size={'xl'}
      dismiss={{ enabled: false }}
      handler={handler}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogBody className="flex flex-row h-[80vh] overflow-hidden relative">
          <XMarkIcon
            className="absolute right-3 w-7 h-7 cursor-pointer"
            onClick={handler}
          />
          {isLoading ? (
            <div className="flex flex-col justify-center items-center w-full">
              <ArrowPathIcon className="w-10 h-10 mr-2 text-primary-main animate-spin" />
            </div>
          ) : (
            <PDFViewer showToolbar={false} className="h-full w-full">
              {renderTemplate(category, {
                agent,
                borrower,
                guarantors,
                agreementDate:
                  moment
                    .utc(activeFacility?.agreement_date)
                    .format('YYYY-MM-DD') || '',
                effectiveDate: moment
                  .utc(calculationDate || dateOptions?.[0])
                  .format('YYYY-MM-DD'),
                tableData: allTableData,
                fileUrl,
                customData,
                formData: getValues(),
              })}
            </PDFViewer>
          )}
          <div className="m-5 w-1/3 overflow-auto">
            <div className="flex flex-col content-between">
              <div className="font-bold text-lg pb-5">
                Fill in below fields to generate document
              </div>
              <FormInput
                type="select"
                label={{ start: 'Date of Calculation' }}
                options={dateOptions.map((d: string) => ({
                  value: d,
                  title: d,
                }))}
                value={calculationDate || dateOptions?.[0]}
                onSelected={val =>
                  setCalculationDate(moment.utc(val).format('YYYY-MM-DD'))
                }
                isDisabled={!!docData?.effective_date}
                error={errors?.date?.message as string}
              />
              <CustomFields category={category} form={form} />
              <SignerInput form={form} user={user} signers={options} />
              {!options?.hideDisclosure && (
                <FormInput
                  type="textarea"
                  label={{ start: 'Disclosure Notice' }}
                  value={getValues('disclosure')}
                  {...register('disclosure', {
                    disabled: isSubmitting,
                    onChange: (e: any) => {
                      setValue('disclosure', e.target.value, {
                        shouldValidate: true,
                      })
                    },
                  })}
                  error={errors?.disclosure?.message as string}
                />
              )}
              <div className="flex flex-row w-full justify-end my-3">
                <Button
                  disabled={isLoading || !isValid}
                  type="submit"
                  color="primary"
                  className="w-full"
                >
                  {isLoading ? (
                    <ArrowPathIcon className="w-4 h-4 mx-20 text-primary-main animate-spin" />
                  ) : (
                    `Prepare Signature Request`
                  )}
                </Button>
              </div>
            </div>
          </div>
        </DialogBody>
      </form>
    </Dialog>
  )
}
