import React, { useContext, useEffect } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import Typography from '@components/atoms/typography'
import { filterDateAsOfType } from '@constants/filter'
import AuthContext from '@contexts/auth'
import { LayoutFilter } from '@interfaces/analytics-risk-filter-type-key'
import { Option, Select } from '@material-tailwind/react'

import { getStaleMins } from '../../helpers/stale-timer'
import ExchangeService from '../../services/api-analytics/risk-currency-exchange'

import { FilterCell } from './filter-cell'

export interface FilterCurrencyProps {
  hideSelect?: boolean
  dateAsOfType: string
  displayDateAsOf?: boolean
}

const FilterCurrency = ({
  hideSelect,
  dateAsOfType,
  displayDateAsOf = true,
}: FilterCurrencyProps) => {
  const {
    company,
    setActiveFilters,
    activeFilters,
    appliedFilters,
    setAppliedFilters,
    setOptionFilters,
  } = useContext(AuthContext)

  const { currency, financialEntityCurrency } = appliedFilters

  const asOfMap = {
    [filterDateAsOfType.loanTape]: company?.loanTapeAsOf,
    [filterDateAsOfType.bankData]: company?.bankDataAsOf,
    [filterDateAsOfType.financials]: company?.financialsAsOf,
  }

  const lastUpdatedMap = {
    [filterDateAsOfType.loanTape]: company?.lastAnalyticsPipeline,
    [filterDateAsOfType.bankData]: company?.lastManagePipeline,
    [filterDateAsOfType.financials]: company?.lastFinancialsPipeline,
  }

  let availableCurrencies = (
    company?.currencies_available?.reduce((prev: string[], cur) => {
      const newCur = []
      if (cur?.to_currency && prev.findIndex(x => x === cur?.to_currency) < 0) {
        newCur.push(cur?.to_currency)
      }
      if (
        cur?.from_currency &&
        prev.findIndex(x => x === cur?.from_currency) < 0
      ) {
        newCur.push(cur?.from_currency)
      }

      return [...prev, ...newCur]
    }, []) ?? []
  ).filter(function (item, pos, self) {
    return item && self.indexOf(item) === pos
  })

  if (
    dateAsOfType === filterDateAsOfType.financials &&
    !availableCurrencies.includes(financialEntityCurrency)
  ) {
    availableCurrencies = [...availableCurrencies, financialEntityCurrency]
  }

  const filters = {
    slug_name: company?.slug_name ?? '',
    start_date: moment(company?.date_start).format('YYYY-MM-DD'),
    end_date: moment(company?.date_end).format('YYYY-MM-DD'),
  }

  const { data } = useQuery(
    ['historical-exchange', filters],
    () => ExchangeService.getHistoricalRate(filters),
    {
      ...getStaleMins(),
      enabled: !!company?.slug_name && availableCurrencies.length > 0,
    }
  )

  const hasUSD = availableCurrencies.find(x => x === 'USD')
  const currencies = hasUSD
    ? availableCurrencies
    : [...availableCurrencies, 'USD']

  useEffect(() => {
    if (data) {
      const display_rates: string[] = []

      const rates = (data ?? []).reduce((p: { [key: string]: any }, c) => {
        if (c.to_ccy === 'USD') {
          return p
        }

        if (!display_rates.includes(c.to_ccy)) {
          display_rates.push(c.to_ccy)
        }

        if (p[c.cohort_date]) {
          p[c.cohort_date][c.to_ccy] = c.xchg_rate
        } else {
          p[c.cohort_date] = {
            [c.to_ccy]: c.xchg_rate,
          }
        }

        return p
      }, {})

      setOptionFilters((prev: LayoutFilter) => {
        return Object.keys(rates).length > 0
          ? {
              ...prev,
              rates,
              display_rates,
            }
          : prev
      })
    }
  }, [data])

  useEffect(() => {
    let defaultCurrency = currency ?? availableCurrencies[0]
    if (
      dateAsOfType !== filterDateAsOfType.financials &&
      !availableCurrencies.includes(currency)
    ) {
      defaultCurrency = availableCurrencies[0]
    }
    setActiveFilters((prev: LayoutFilter) => {
      return {
        ...prev,
        currency: defaultCurrency,
      }
    })
    setAppliedFilters((prev: LayoutFilter) => {
      return {
        ...prev,
        currency: defaultCurrency,
      }
    })
  }, [currency])

  return (
    <div className="flex gap-3 flex-row gap-y-1 w-full">
      {displayDateAsOf && (
        <FilterCell title={`${dateAsOfType} As Of`}>
          <Typography className="text-neutral-black text-sm font-medium leading-[40px]">
            {asOfMap[dateAsOfType]}
          </Typography>
        </FilterCell>
      )}
      <FilterCell title="Last Updated">
        <Typography className="text-neutral-black text-sm font-medium leading-[40px]">
          {lastUpdatedMap[dateAsOfType]}
        </Typography>
      </FilterCell>

      {!hideSelect && (
        <FilterCell title="Currency">
          <Select
            data-testid="currency-dropdown"
            className=" [&~ul]:max-h-[100px] w-auto bg-neutral-white text-neutral-black flex-grow w-full"
            disabled={currencies.length <= 1}
            onChange={val => {
              setActiveFilters({
                ...activeFilters,
                currency: val,
              })
            }}
            selected={() => {
              return <span>{activeFilters.currency}</span>
            }}
          >
            {currencies.map((c: string) => (
              <Option key={c} value={c}>
                {c}
              </Option>
            ))}
          </Select>
        </FilterCell>
      )}
    </div>
  )
}
export default FilterCurrency
