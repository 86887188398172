import React, { useContext, useEffect, useState } from 'react'
import { add, format, isAfter, isBefore, isValid, sub } from 'date-fns'

import Button from '@components/atoms/button'
import DatePicker from '@components/atoms/date-picker'
import Typography from '@components/atoms/typography'
import AuthContext from '@contexts/auth'
import { normalizeDateToLocal } from '@helpers/date-utils'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { LayoutFilter } from '@interfaces/analytics-risk-filter-type-key'
import { EntityInfo } from '@interfaces/financial'
import {
  Popover,
  PopoverContent,
  PopoverHandler,
} from '@material-tailwind/react'

import { FilterCell } from './filter-cell'

const FORMAT = 'yyyy-MM-dd'

// transforms any date object to yyyy-mm-dd without converting to UTC ISO
const formatDateIntl = (date: Date): string => {
  return new Intl.DateTimeFormat('en-CA', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).format(date)
}

const FilterDateRange = ({
  isCohort = false,
  isCurrentDate = false,
  type,
  addMonthToEndDate = false,
  noLimits = false,
}: {
  isCohort?: boolean
  isCurrentDate?: boolean
  type?: string
  addMonthToEndDate?: boolean
  noLimits?: boolean
}) => {
  const [isChildOpen, setIsChildOpen] = useState<boolean>(false)
  const { activeFilters, setActiveFilters, company, optionFilters } =
    useContext(AuthContext)
  const {
    dateStart,
    dateEnd,
    dateStartCohort,
    dateEndCohort,
    range,
    financialEntityID,
    entityStartDate,
    entityEndDate,
  } = activeFilters

  const isFinancials = type == 'financials'

  const activeEntity = optionFilters.financialEntities?.find(
    (entity: EntityInfo) => entity.entity_id == financialEntityID
  )

  const companyEnd = new Date(normalizeDateToLocal(company?.date_end) ?? '')

  let end: Date

  if (isCurrentDate) {
    end = new Date()
  } else if (isAfter(companyEnd, Date.now())) {
    end = dateEnd ? new Date(dateEnd) : new Date()
  } else {
    end = companyEnd
  }

  if (addMonthToEndDate) {
    end = add(end, { months: 1 })
  }

  const start =
    range <= 0
      ? new Date(normalizeDateToLocal(company?.date_start) ?? '')
      : sub(end, { years: range })

  const financialsStart =
    range <= 0
      ? new Date(entityStartDate ?? '')
      : sub(entityEndDate, { years: range })

  const financialsEnd = isCurrentDate ? Date.now() : entityEndDate

  const dateEndCohortLimit =
    normalizeDateToLocal(company?.date_end_cohort) ?? new Date()

  const startCohort: Date = company?.date_start
    ? normalizeDateToLocal(company.date_start)
    : sub(new Date(), { years: 1 })

  let startCohortWithRange: Date = startCohort
  if (range != 0) {
    startCohortWithRange = sub(dateEndCohortLimit, {
      years: range,
    })
  }

  const getDate = (
    isCohort: boolean,
    isFinancials: boolean,
    cohortDate: Date,
    financialDate: Date,
    defaultDate: Date
  ) => {
    if (isCohort) {
      return cohortDate
    } else if (isFinancials) {
      return financialDate
    } else {
      return defaultDate
    }
  }

  const formatValidDate = (date: Date) =>
    isValid(new Date(date))
      ? format(new Date(date), 'yyyy-MM-dd')
      : 'Invalid Date'

  const formattedStartDate = formatValidDate(
    getDate(
      isCohort,
      isFinancials,
      typeof dateStartCohort == 'string'
        ? normalizeDateToLocal(dateStartCohort)
        : dateStartCohort,
      typeof financialsStart == 'string'
        ? normalizeDateToLocal(financialsStart)
        : financialsStart,
      typeof dateStart == 'string' ? normalizeDateToLocal(dateStart) : dateStart
    )
  )

  const formattedEndDate = formatValidDate(
    getDate(
      isCohort,
      isFinancials,
      typeof dateEndCohort == 'string'
        ? normalizeDateToLocal(dateEndCohort)
        : dateEndCohort,
      typeof financialsEnd == 'string'
        ? normalizeDateToLocal(financialsEnd)
        : financialsEnd,

      typeof dateEnd == 'string' ? normalizeDateToLocal(dateEnd) : dateEnd
    )
  )
  const formattedMinDate = formatValidDate(
    getDate(
      isCohort,
      isFinancials,
      startCohort,
      normalizeDateToLocal(activeEntity?.start_date),
      start
    )
  )
  const formattedMaxDate = formatValidDate(
    getDate(
      isCohort,
      isFinancials,
      dateEndCohortLimit,
      normalizeDateToLocal(activeEntity?.end_date),
      typeof dateEnd == 'string' ? normalizeDateToLocal(dateEnd) : dateEnd
    )
  )

  //defaults when the specified dependancies change
  useEffect(() => {
    if (company) {
      setActiveFilters((prev: LayoutFilter) => {
        return {
          ...prev,
          range: range ?? 0,
          dateStart: format(start, FORMAT),
          dateEnd: format(end, FORMAT),
          endCohort: format(dateEndCohortLimit, FORMAT),
          dateStartCohort: format(startCohortWithRange, FORMAT),
          dateEndCohort: format(dateEndCohortLimit, FORMAT),
          entityStartDate:
            !!activeEntity?.start_date &&
            format(normalizeDateToLocal(activeEntity?.start_date), FORMAT),
          entityEndDate:
            !!activeEntity &&
            format(normalizeDateToLocal(activeEntity?.end_date), FORMAT),
        }
      })
    }
  }, [company, financialEntityID, optionFilters.financialEntities])

  useEffect(() => {
    if (range !== -1) {
      const entityStartWithRange = format(
        sub(normalizeDateToLocal(activeEntity?.end_date), {
          years: range,
        }),
        FORMAT
      )

      setActiveFilters((prev: LayoutFilter) => ({
        ...prev,
        dateStart: format(start, FORMAT),
        dateEnd: format(end, FORMAT),
        endCohort: format(dateEndCohortLimit, FORMAT),
        dateStartCohort:
          range !== 0
            ? format(startCohortWithRange, FORMAT)
            : format(startCohort, FORMAT),
        dateEndCohort: format(dateEndCohortLimit, FORMAT),
        entityStartDate:
          !!activeEntity?.end_date && range !== 0
            ? format(normalizeDateToLocal(entityStartWithRange), FORMAT)
            : format(normalizeDateToLocal(activeEntity?.start_date), FORMAT),
        entityEndDate:
          !!activeEntity &&
          format(normalizeDateToLocal(activeEntity?.end_date), FORMAT),
      }))
    }
  }, [range])

  useEffect(() => {
    return () =>
      setActiveFilters((prev: LayoutFilter) => ({
        ...prev,
        range: 0,
      }))
  }, [isFinancials, isCohort])

  return (
    <FilterCell title="Date Range">
      <Popover placement="bottom" dismiss={{ enabled: !isChildOpen }}>
        <PopoverHandler>
          <button className="border border-neutral-border-2 !bg-neutral-white rounded-lg min-w-[200px] min-h-[40px] !outline-none flex items-center px-4 justify-between gap-4 ">
            <div className="flex gap-2">
              <Typography className="text-sm !text-neutral-black font-medium">
                {formattedStartDate}
              </Typography>
              <Typography className="text-sm !text-neutral-black">
                to
              </Typography>
              <Typography className="text-sm !text-neutral-black font-medium">
                {formattedEndDate}
              </Typography>
            </div>
            <ChevronDownIcon className="h-3 w-3" />
          </button>
        </PopoverHandler>
        <PopoverContent className="z-[10000] border-t border-neutral-border-2 rounded-lg flex flex-col w-[270px] shadow-lg">
          <div className="mt-2">
            <DatePicker
              label="From"
              value={normalizeDateToLocal(formattedStartDate)}
              onChange={fd => {
                const key = isCohort
                  ? 'dateStartCohort'
                  : isFinancials
                  ? 'entityStartDate'
                  : 'dateStart'

                setActiveFilters((prev: LayoutFilter) => ({
                  ...prev,
                  [key]: fd ? formatDateIntl(fd) : '',
                  range: -1,
                }))
              }}
              minDate={
                !noLimits ? normalizeDateToLocal(formattedMinDate) : undefined
              }
              maxDate={
                !noLimits ? normalizeDateToLocal(formattedMaxDate) : undefined
              }
              setParentDismiss={setIsChildOpen}
            />
          </div>
          <div className="mt-5">
            <DatePicker
              label="To"
              value={normalizeDateToLocal(formattedEndDate)}
              onChange={td => {
                const key = isCohort
                  ? 'dateEndCohort'
                  : isFinancials
                  ? 'entityEndDate'
                  : 'dateEnd'

                setActiveFilters((prev: LayoutFilter) => ({
                  ...prev,
                  [key]: td ? formatDateIntl(td) : '',
                  range: -1,
                }))
              }}
              minDate={
                !noLimits ? normalizeDateToLocal(formattedMinDate) : undefined
              }
              maxDate={
                !noLimits ? normalizeDateToLocal(formattedMaxDate) : undefined
              }
              setParentDismiss={setIsChildOpen}
            />
          </div>
          {normalizeDateToLocal(formattedStartDate) >
          normalizeDateToLocal(formattedEndDate) ? (
            <Typography className="text-xs mt-3 text-red text-center">
              {' "From" date cannot be after "To" date'}
            </Typography>
          ) : null}
          <Typography className="text-sm !text-neutral-black mt-4">
            Latest
          </Typography>
          <div className="flex w-full">
            {Array(3)
              .fill('')
              .map((x, i) => (
                <Button
                  key={i}
                  disabled={isBefore(
                    sub(normalizeDateToLocal(formattedMaxDate), {
                      years: i + 1,
                    }),
                    isFinancials
                      ? new Date(activeEntity?.start_date)
                      : startCohort
                  )}
                  color={range === i + 1 ? 'primary' : 'default'}
                  onClick={() => {
                    setActiveFilters((prev: LayoutFilter) => ({
                      ...prev,
                      range: i + 1,
                    }))
                  }}
                  className={`flex-1 h-[40px] !outline-none rounded-lg ${
                    i === 0 ? 'rounded-r-none' : 'rounded-none'
                  }`}
                >{`${i + 1}Y`}</Button>
              ))}
            <Button
              className="rounded-lg rounded-l-none flex-1 !outline-none"
              color={range === 0 ? 'primary' : 'default'}
              onClick={() => {
                setActiveFilters((prev: LayoutFilter) => ({
                  ...prev,
                  range: 0,
                }))
              }}
            >
              All
            </Button>
          </div>
        </PopoverContent>
      </Popover>
    </FilterCell>
  )
}
export default FilterDateRange
