import React from 'react'

import { ArrowPathIcon } from '@heroicons/react/24/outline'

import { Part, TableProps } from './type'

const PositionedTable = ({
  id = null,
  title,
  loading = false,
  columns,
  data = [],
  allowOverflow = true,
  onRowClick,
  rowClass,
}: TableProps) => {
  /*
   * Renders positioned columns specifically for Borrowing Base.
   * tdElement accumulates items in the table cell based on conditions.
   * You can add new conditions here for additional logic.
   * The render function and column props, defined by the caller, dictate the cell's appearance.
   */
  const renderPositionedColumnData = (
    record: any,
    recordIndex: number,
    part: Part
  ) => {
    return columns.map(({ render, props, ...restColumn }, j) => {
      const allProps = {
        ...restColumn,
        ...props?.(record, recordIndex, part),
      }
      const tdElement: any = []

      if (render) {
        // First column with info always pushed.
        if (j === 0) {
          tdElement.push(render(record, recordIndex, part))
        } else if (record.position === j) {
          tdElement.push(render(record, recordIndex, part))
        }
      }

      return (
        <td key={j} {...allProps}>
          {tdElement}
        </td>
      )
    })
  }

  return (
    <div className="flex flex-col flex-1" id={id ?? undefined}>
      <div
        className={`cascade-table overflow-y-hidden overflow-x-auto ${
          !allowOverflow ? 'max-h-[calc(100vh-10rem)]' : ''
        }`}
      >
        <table>
          {title && (
            <thead>
              <tr>
                <th colSpan={columns.length} className="text-black">
                  {title}
                </th>
              </tr>
            </thead>
          )}

          <tbody>
            {loading && (
              <tr>
                <td align="center">
                  <ArrowPathIcon className="w-6 h-6 text-primary-main animate-spin m-6" />
                </td>
              </tr>
            )}
            {!loading &&
              data?.length > 0 &&
              data?.map((d, i) => {
                return (
                  <React.Fragment key={i}>
                    <tr
                      onClick={() => {
                        !!onRowClick && onRowClick?.(d, i)
                      }}
                      className={`${rowClass?.(d, i)} row-head`}
                    >
                      {renderPositionedColumnData(d, i, 'body')}
                    </tr>
                  </React.Fragment>
                )
              })}
            {!loading && data.length === 0 && (
              <tr>
                <td className="text-center">{`No data`}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default PositionedTable
