import {
  CovenantDataBuilder,
  CovenantType,
} from '@helpers/covenant-data-builder'
import {
  CovenantDetailResponse,
  CovenantListResponse,
} from '@interfaces/manage-monitor-covenant'

export class ManageCovenantDataBuilder extends CovenantDataBuilder {
  constructor(
    setSelectedCohort: React.Dispatch<React.SetStateAction<string>>,
    selectedCohort: string,
    covenant: CovenantDetailResponse,
    summary: CovenantListResponse
  ) {
    super(
      CovenantType.MANAGE,
      setSelectedCohort,
      selectedCohort,
      covenant,
      summary
    )

    this.withCohortInterval(this.data.params.x_axis[1])
    this.withCohortColumnName(summary.params.column[0])
    this.withSpecialCovenant(covenant.params.special_covenent)
    this.withSpecialSeriesLabels(
      this.data.params?.special_series_label?.split(',') ?? []
    )
    this.withSpecialSeriesFields(
      this.data.params?.special_series_field?.split(',') ?? []
    )
    this.withInvertedColours(summary.params.invert_colours)
  }
}
