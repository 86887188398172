import React from 'react'

import {
  CategoriesResult,
  WaterfallTemplateProps,
} from '@interfaces/manage-reporting'

import BaubapWaterfallTemplate from './baubap/baubap-waterfall-template'
import DefaultWaterfallTemplate from './default/default-waterfall-template'
import FlexpathWaterfallTemplate from './flexpath/flexpath-waterfall-template'
import RaistoneWaterfallTemplate from './raistone/raistone-waterfall-template'
import SpecialtyCapitalWaterfallTemplate from './specialtycapital/specialty-capital-waterfall-template'

export const renderTemplate = (
  category: CategoriesResult,
  templateProps: WaterfallTemplateProps
) => {
  switch (category?.subcategory) {
    case 'baubap-checkbox-waterfall': {
      return <BaubapWaterfallTemplate {...templateProps} />
    }
    case 'flexpath-waterfall': {
      return <FlexpathWaterfallTemplate {...templateProps} />
    }
    case 'raistone-waterfall': {
      return <RaistoneWaterfallTemplate {...templateProps} />
    }
    case 'specialty-capital-waterfall': {
      return <SpecialtyCapitalWaterfallTemplate {...templateProps} />
    }
    case 'default':
    default: {
      return <DefaultWaterfallTemplate {...templateProps} />
    }
  }
}
