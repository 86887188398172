import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'

import Button from '@components/atoms/button'
import FormInput from '@components/form/form-input'
import Table from '@components/table'
import { SortOrder } from '@components/table/type'
import AuthContext from '@contexts/auth'
import { useActiveFacility } from '@helpers/active-facility-hook'
import { ArrowPathIcon, PlusIcon } from '@heroicons/react/24/outline'
import { FacilityDetailResponse } from '@interfaces/manage-monitor-action'
import { Card, CardBody } from '@material-tailwind/react'
import { FacilityDetailsService } from '@services/api-manage/facility-details'

import { OptionsMenu } from './options-menu'

export const FacilityPartyDetailsAdmin = () => {
  const { company } = useContext(AuthContext)
  const [sortField, setSortField] = useState<string>('status')
  const [sortOrder, setSortOrder] = useState<SortOrder>('asc')
  const [dataArray, setDataArray] = useState<FacilityDetailResponse[]>([])
  const activeFacility = useActiveFacility()
  const {
    formState: { errors, isValid },
    getValues,
    handleSubmit,
    register,
    reset,
    setValue,
  } = useForm()

  const params = {
    facility: activeFacility?.facility_name,
    slug_name: company?.slug_name ?? '',
    facility_id: activeFacility?.facility_id,
  }

  const { data, isLoading, refetch } = useQuery(
    ['getDealPartyDetailsRequest', params],
    () => FacilityDetailsService.getDealPartyDetailsRequest(params),
    {
      enabled: !!company && !!activeFacility,
    }
  )
  useEffect(() => data && setDataArray(data), [data])

  const { mutate: updateFacilityPartyDetail, isLoading: createLoading } =
    useMutation(
      (values: FacilityDetailResponse) =>
        FacilityDetailsService.updateFacilityPartyDetailRequest({
          ...params,
          entry: values,
        }),
      {
        onSuccess: () => {
          refetch()
          reset()
        },
      }
    )

  const { mutate: deleteFacilityPartyDetail, isLoading: deleteLoading } =
    useMutation(
      (id: string) =>
        FacilityDetailsService.deleteFacilityPartyDetailRequest({
          ...params,
          id: id,
        }),
      {
        onSuccess: () => {
          refetch()
          reset()
        },
      }
    )

  const onSubmit = (values: any) => {
    if (
      values.type &&
      (values.legal_name || values.distribution_list || values.supplementary)
    ) {
      updateFacilityPartyDetail(values)
    }
  }

  const handleEdit = (id: string) => {
    const entry = dataArray.find(e => e.id === id)
    if (!entry) {
      return
    }
    Object.keys(entry).forEach((key: string) => {
      setValue(key, entry[key as keyof FacilityDetailResponse], {
        shouldValidate: true,
      })
    })
  }

  const cancelEdit = () => {
    if (dataArray?.length && !dataArray[dataArray.length - 1].id) {
      setDataArray(dataArray.slice(0, dataArray.length - 1))
    }
    reset()
  }

  const isWorking = createLoading || deleteLoading

  return (
    <Card className="rounded-md h-full w-full">
      <CardBody className="flex flex-col items-left ">
        <div className="flex justify-between w-full">
          <div className="text-lg font-bold">Facility Party Details</div>
          <div className="flex gap-2">
            <button
              className="flex justify-center items-center w-24 h-10 py-1 mb-2 text-sm font-medium cursor-pointer rounded-lg bg-primary-main hover:bg-primary-hover text-white transition ease-in-out delay-50 duration-300"
              onClick={() =>
                setDataArray([...dataArray, {} as FacilityDetailResponse])
              }
              disabled={
                isWorking ||
                (!!dataArray?.length &&
                  (!dataArray[dataArray.length - 1].id || !!getValues('id')))
              }
            >
              <PlusIcon className="w-4 h-4 mr-2 -ml-2" />
              Add
            </button>
            <Button
              color="default"
              className="mb-2 "
              onClick={() => {
                refetch()
              }}
              disabled={isWorking}
            >
              <ArrowPathIcon
                className={`${isLoading ? 'animate-spin' : ''} w-4 h-4 `}
              />
            </Button>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Table
            loading={isLoading}
            containerClass="max-w-[100%]"
            columns={[
              {
                title: 'Type',
                field: 'type',
                sortable: true,
                width: '110px',
                render: entry =>
                  !entry.id || entry.id === getValues('id') ? (
                    <FormInput
                      containerClass="max-w-[85px]"
                      type="text"
                      value={getValues('type')}
                      {...register('type', {
                        required: {
                          value: true,
                          message: 'This is required',
                        },
                        minLength: 1,
                        maxLength: 2048,
                        disabled: isWorking,
                        onChange: (e: any) => {
                          setValue('type', e.target.value, {
                            shouldValidate: true,
                          })
                        },
                        onBlur: (e: any) => {
                          setValue('type', e.target.value, {
                            shouldValidate: true,
                          })
                        },
                      })}
                      error={errors.type?.message as string}
                    />
                  ) : (
                    entry.type
                  ),
              },
              {
                title: 'Name',
                field: 'legal_name',
                sortable: true,
                width: '200px',
                render: entry =>
                  !entry.id || entry.id === getValues('id') ? (
                    <FormInput
                      containerClass="max-w-[175px]"
                      type="text"
                      value={getValues('legal_name')}
                      {...register('legal_name', {
                        minLength: 1,
                        maxLength: 2048,
                        disabled: createLoading,
                        onChange: (e: any) => {
                          setValue('legal_name', e.target.value, {
                            shouldValidate: true,
                          })
                        },
                        onBlur: (e: any) => {
                          setValue('legal_name', e.target.value, {
                            shouldValidate: true,
                          })
                        },
                      })}
                      error={errors.legal_name?.message as string}
                    />
                  ) : (
                    entry.legal_name
                  ),
              },
              {
                title: 'Distribution List',
                field: 'distribution_list',
                sortable: false,
                render: entry =>
                  !entry.id || entry.id === getValues('id') ? (
                    <FormInput
                      containerClass="max-w-[200px]"
                      type="text"
                      error={errors.distribution_list?.message as string}
                      value={getValues('distribution_list')}
                      {...register('distribution_list', {
                        minLength: 1,
                        maxLength: 2048,
                        disabled: isWorking,
                        onChange: (e: any) => {
                          setValue('distribution_list', e.target.value, {
                            shouldValidate: true,
                          })
                        },
                        onBlur: (e: any) => {
                          setValue('distribution_list', e.target.value, {
                            shouldValidate: true,
                          })
                        },
                        validate: value => {
                          try {
                            if (value) {
                              JSON.parse(value)
                            }
                          } catch {
                            return 'Invalid JSON'
                          }
                        },
                      })}
                    />
                  ) : (
                    entry.distribution_list
                  ),
              },
              {
                title: 'Supplementary',
                field: 'supplementary',
                sortable: false,
                render: entry =>
                  !entry.id || entry.id === getValues('id') ? (
                    <FormInput
                      containerClass="max-w-[840px]"
                      type="text"
                      error={errors.supplementary?.message as string}
                      value={getValues('supplementary')}
                      {...register('supplementary', {
                        minLength: 1,
                        maxLength: 2048,
                        disabled: isWorking,
                        onChange: (e: any) => {
                          setValue('supplementary', e.target.value, {
                            shouldValidate: true,
                          })
                        },
                        onBlur: (e: any) => {
                          setValue('supplementary', e.target.value, {
                            shouldValidate: true,
                          })
                        },
                        validate: value => {
                          try {
                            if (value) {
                              JSON.parse(value)
                            }
                          } catch {
                            return 'Invalid JSON'
                          }
                        },
                      })}
                    />
                  ) : (
                    entry.supplementary
                  ),
              },
              {
                title: 'Actions',
                field: 'id',
                width: '64.5px',
                render: entry => (
                  <OptionsMenu
                    id={entry.id}
                    edit={entry.id === getValues('id')}
                    disableMenu={
                      isWorking || !dataArray[dataArray.length - 1].id
                    }
                    disableButtons={isWorking}
                    disableSave={!isValid}
                    handleEdit={handleEdit}
                    handleDelete={deleteFacilityPartyDetail}
                    handleCancel={cancelEdit}
                  />
                ),
              },
            ]}
            data={dataArray}
            onSort={(field: string, order: SortOrder) => {
              setSortField(order != '' ? field : 'status')
              setSortOrder(order != '' ? order : 'asc')
            }}
            sortField={sortField}
            sortOrder={sortOrder}
          />
        </form>
      </CardBody>
    </Card>
  )
}
