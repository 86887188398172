import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import FormInput from '@components/form/form-input'
import AuthContext from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import { UserResponse } from '@interfaces/administrations-settings-user'
import Company from '@interfaces/company'
import { Facility } from '@interfaces/facility'
import { LayoutFilter } from '@interfaces/filter'
import { Option, Select, Typography } from '@material-tailwind/react'
import { UserService } from '@services/api-admin/settings-user'
import DocumentService from '@services/api-manage/docs'

const statuses = [
  {
    status: '',
    display: 'All',
  },
  { status: 'overdue', display: 'Overdue' },
  { status: 'due', display: 'Due' },

  {
    status: ['awaiting_signature', 'created'],
    display: 'Awaiting Signature',
  },
  { status: 'upcoming', display: 'Upcoming' },
  {
    status: ['signed', 'completed'],
    display: 'Completed',
  },
  { status: ['voided', 'signed_and_retracted'], display: 'Nullified' },
]

export const setFilterDocCentre = (
  activeFacility: Facility,
  company: Company | undefined,
  setActiveFilters: (filters: LayoutFilter) => void,
  optionFilters: LayoutFilter,
  setOptionFilters: (filters: LayoutFilter) => void,
  shouldReset: boolean
) => {
  if (activeFacility) {
    DocumentService.listCategories({
      slug_name: activeFacility?.slug_name ?? company?.slug_name ?? '',
      facility_id: activeFacility?.facility_id,
      facility: activeFacility?.facility_name ?? '',
      action: 'upload',
    })
      .then(response => {
        const allValues: any = { category: 'All', subcategory: [] }
        response.forEach(i => allValues.subcategory.push(i.category))
        response.unshift(allValues)
        if (
          optionFilters?.initialFilters === undefined &&
          !optionFilters?.facilitiesIsFetching
        ) {
          setOptionFilters((prev: LayoutFilter) => ({
            ...prev,
            initialDocCentreFilters: {
              dateStart: moment(company?.date_start).format('YYYY-MM-DD'),
              dateEnd: moment(company?.date_end).isAfter(moment())
                ? moment()
                : moment(company?.date_end),
              docKeyword: '',
              docCategory: response.filter(
                (cat: any) => cat.category == 'All'
              )[0],
              docStatus: statuses.filter(
                (status: any) => status.display == 'All'
              )[0],
              docSigner: '',
            },
          }))
        }
        setActiveFilters((prev: LayoutFilter) => ({
          ...prev,
          docKeyword:
            !shouldReset && prev?.docKeyword !== '' ? prev?.docKeyword : '',
          docCategory:
            !shouldReset && prev?.docCategory?.category !== 'All'
              ? prev?.docCategory
              : response.find((cat: any) => cat.category == 'All'),
          docStatus:
            !shouldReset && prev?.docStatus?.display !== 'All'
              ? prev?.docStatus
              : statuses.find((status: any) => status.display == 'All'),
          docSigner:
            !shouldReset && prev?.docSigner !== '' ? prev?.docSigner : '',
        }))
        setOptionFilters((prev: LayoutFilter) => ({
          ...prev,
          docKeyword: '',
          docCategories: response,
          docStatuses: statuses,
        }))
      })
      .catch(() => {
        console.error('error')
      })
  }
}

const FilterDocCentre = () => {
  const {
    company,
    activeFilters,
    appliedFilters,
    setActiveFilters,
    optionFilters,
    setOptionFilters,
  } = useContext(AuthContext)
  const [users, setUsers] = useState<(UserResponse | undefined)[]>([
    { name: 'All', email: '' } as UserResponse,
  ])

  const { docCategory, docStatus, docKeyword, docSigner } = activeFilters
  const { activeFacilityId } = appliedFilters
  const {
    facilities = [],
    docCategories = [],
    docStatuses = [],
  } = optionFilters
  const activeFacility = facilities?.find(
    (f: Facility) => f.facility_id === activeFacilityId
  )

  const userQueries = [
    {
      slug_name: company?.slug_name ?? '',
      is_external: false,
      page: 0,
      per_page: 100,
    },
    {
      slug_name: company?.slug_name ?? '',
      is_external: true,
      page: 0,
      per_page: 100,
    },
  ].map(params =>
    useQuery(
      ['userList', params],
      () => UserService.getUsers(params),
      getStaleMins()
    )
  )

  useEffect(() => {
    if (userQueries.every(query => !query.isLoading)) {
      const users = userQueries
        .map(query => query.data?.data)
        .flat()
        .filter((user: UserResponse | undefined) => {
          const isSuperAdmin =
            user?.user_metadata?.companies?.[company?.slug_name ?? '']
              ?.control === 'superadmin' || user?.user_metadata?.is_superadmin
          return !!user && !isSuperAdmin
        })
        .sort((a, b) =>
          // @ts-expect-error it says that a and b can be undefined but it filters for that above
          a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        )
      users.unshift({ name: 'All', email: '' } as UserResponse)

      setUsers(users)
    }
  }, [userQueries.every(query => !query.isLoading)])

  useEffect(() => {
    setFilterDocCentre(
      activeFacility,
      company,
      setActiveFilters,
      optionFilters,
      setOptionFilters,
      false
    )
  }, [company, activeFacility])

  const getStatusDisplayName = (val: any) => {
    const status = statuses.find((s: any) => s.display === val?.display)
    return status ? status.display : 'All'
  }

  const getCategoryDisplayName = (val: any) => {
    const category = docCategories.find(
      (s: any) => s.category === val?.category
    )
    return category ? category.category : 'All'
  }

  return (
    <div className="flex flex-row gap-3">
      <div className="flex flex-col min-w-[260px]">
        <div className="flex flex-col gap-1">
          <Typography className="text-neutral-body-2 text-sm">
            Document
          </Typography>
          <div className="w-full">
            <FormInput
              value={docKeyword}
              {...{
                placeholder: 'Enter Document Name..',
                onChange: (e: any) => {
                  setActiveFilters({
                    ...activeFilters,
                    docKeyword: e.target.value,
                  })
                },
              }}
            />
          </div>
        </div>
        {!!docCategories && docCategories.length > 0 && (
          <div className="flex flex-col gap-1 mt-[-6px]">
            <Typography className="text-neutral-body-2 text-sm">
              Category
            </Typography>
            <div className="w-full">
              <Select
                className="w-full [&~ul]:max-h-[48vh] border border-neutral-border-1 mb-0"
                onChange={(val: any) => {
                  setActiveFilters({
                    ...activeFilters,
                    docCategory: val,
                  })
                }}
                selected={() => (
                  <span>{getCategoryDisplayName(docCategory)}</span>
                )}
              >
                {docCategories.map((e: any, i: number) => (
                  <Option key={i} value={e}>
                    {e.category}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-col min-w-[260px]">
        <div className="flex flex-col gap-1">
          <Typography className="text-neutral-body-2 text-sm">
            Status
          </Typography>
          <div className="w-full">
            <Select
              className="w-full [&~ul]:max-h-[48vh] border border-neutral-border-1"
              onChange={val => {
                setActiveFilters({
                  ...activeFilters,
                  docStatus: val,
                })
              }}
              selected={() => <span>{getStatusDisplayName(docStatus)}</span>}
            >
              {docStatuses.map((e: any, i: number) => (
                <Option key={i} value={e}>
                  {e.display}
                </Option>
              ))}
            </Select>
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <Typography className="text-neutral-body-2 text-sm">
            Signer
          </Typography>
          <div className="w-full">
            <Select
              className="w-full [&~ul]:max-h-[48vh] border border-neutral-border-1"
              onChange={val => {
                setActiveFilters({
                  ...activeFilters,
                  docSigner: val,
                })
              }}
              selected={() => (
                <span>
                  {users.find(user => user?.email === docSigner)?.name}
                </span>
              )}
            >
              {users.map((user: any, i: number) => (
                <Option key={`signer-${i}`} value={user.email}>
                  {user.name}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      </div>
    </div>
  )
}
export default FilterDocCentre
