import { ChartDataBuilder } from '@helpers/chart-data-builder'
import { DashboardResponse } from '@interfaces/manage-monitor-dashboard'

function reduceStackedData(data: any) {
  return (existingIndex: number, acc: any, curr: any, xValue: any) => {
    if (existingIndex >= 0) {
      acc[existingIndex][curr.data[data.series_field ?? '']] =
        curr.data[data.y_axis_field]
    } else {
      const newItem: any = { x: xValue }
      curr.data.data?.forEach((item: any) => {
        newItem[item[data.series_field ?? '']] = item[data.y_axis_field]
      })
      acc.push(newItem)
    }
  }
}

export class ManageDataBuilder extends ChartDataBuilder {
  constructor(data: DashboardResponse, calculationDate: string) {
    super(data, calculationDate, reduceStackedData(data), 'display')
  }

  public build() {
    const filteredData = this.getFilteredData()
    const chartData = this.getChartData(filteredData)
    const filteredChartData = this.getFilteredChartData(chartData)
    const triggerSeries = this.getTriggerSeries()

    //obtain data for the series
    const uniqueSeries: Set<string> = new Set<string>()
    this.data.data?.forEach((d: { data: { data: any[] } }) => {
      d.data.data?.forEach((element: any) => {
        uniqueSeries.add(element[this.data.series_field ?? ''])
      })
    })
    const seriesData = Array.from(uniqueSeries).map(el => {
      return {
        [this.data.series_field ?? '']: el,
      }
    })

    const shouldDisplayData = [
      'stacked_area',
      'total_stacked_area',
      'total_stacked_graph',
      'stacked_graph',
      'combo_series',
      'line_graph',
    ].includes(this.data.display)

    let unsortedChartSeries
    if (shouldDisplayData) {
      unsortedChartSeries = seriesData.reduce((acc: any[], curr: any) => {
        if (!acc.some(x => x.label === curr[this.data.series_field ?? ''])) {
          const seriesType = this.getType(curr[this.data.series_field ?? ''])

          acc.push({
            label: this.getLabels(curr[this.data.series_field ?? '']),
            tooltipValueFormat: this.getTooltipLabelFormatting(
              curr[this.data.series_field ?? '']
            ),

            type: seriesType,
            oppositeYAxis:
              this.data.display == 'combo_series' &&
              seriesType == 'SmoothedXLineSeries',
            isStack: ![
              'combo_series',
              'line_graph',
              'multiline_graph',
            ].includes(this.data.display),
            isTotal: this.data.display?.includes('total'),
            field: curr[this.data.series_field ?? ''],
            color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
            fill: ['stacked_area', 'total_stacked_area'].includes(
              this.data.display
            ),
            hasBullet: false,
          })
        }
        return acc
      }, [])
    } else {
      unsortedChartSeries = this.getDefaultUnsortedSeries(triggerSeries)
    }

    //ensures line series are plotted over any other series
    const chartSeries = unsortedChartSeries.sort(
      (a: { type: string }, b: { type: string }) =>
        a.type === 'SmoothedXLineSeries'
          ? 1
          : b.type === 'SmoothedXLineSeries'
          ? -1
          : 0
    )
    const xAxisType = this.getXAxisType()
    const secondaryYAxisConfig = this.getSecondaryYAxisConfig()
    const tableColumns = this.getTableColumns()
    const tableData = this.getTableData()

    return {
      unit: this.unit,
      data: this.data,
      chartData,
      allChartData: filteredData?.map((d: any) => d.data)[0],
      chartSeries,
      xAxisType,
      filteredChartData,
      ySetting: { minVal: this.minYAxisValue, maxVal: this.maxYAxisValue },
      xSetting: this.data.x_axis_setting,
      xAxisLabel: this.data.x_axis_label,
      yAxisLabel: this.data.y_axis_label,
      yAxisFormat: this.data.y_axis_format ?? '#.00a',
      title: this.data.title,
      dashboardId: this.data.dashboard_id,
      displayName: this.data.external_name,
      dateFilterField: this.data.calculation_date_filter_field,
      tableColumns,
      tableData,
      numberTableColumns: this.data?.data[0]?.cols,
      numberTableData: this.data?.data[0]?.data,
      centerField: this.data.center_field,
      centerLabel: this.data.center_label,
      categoryField: this.data.category_field,
      valueField: this.data.value_field,
      secondaryYAxisConfig,
    }
  }
}
