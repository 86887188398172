import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import Chart from '@components/chart'
import MultiToggleLayout from '@components/layouts/multi-toggle/multi-toggle-layout'
import Table from '@components/table'
import { REMOUNT_MS } from '@constants/config'
import AuthContext from '@contexts/auth'
import { useDisbursementCurrencies } from '@helpers/currency-hook'
import { numberFormatter } from '@helpers/number-formatter'
import { getStaleMins } from '@helpers/stale-timer'
import { RisksFilters } from '@interfaces/analytics-risk'
import { DelinquencyService } from '@services/api-analytics/risk-delinquency'

import {
  displayRates,
  historicalExchgList,
} from '../../common/historical-exchange-rate'

const BorrowerConcentrationL4 = ({ exportable }: { exportable: boolean }) => {
  const showHistoricalRateIndicator = useDisbursementCurrencies()
  const { company, appliedFilters, optionFilters } = useContext(AuthContext)

  const {
    dateStart,
    dateEnd,
    categoryTypes = [],
    activeType,
    currency = 'USD',
  } = appliedFilters
  const { rates = {}, display_rates = [] } = optionFilters
  const isAggregate = activeType === 'All'

  const filters: RisksFilters = {
    date_from: moment(dateStart).format('YYYY-MM-DD'),
    date_to: moment(dateEnd).format('YYYY-MM-DD'),
    slug_name: company?.slug_name ?? '',
    filters: isAggregate ? [] : categoryTypes,
    currency,
  }

  const { error, data, isFetching } = useQuery(
    ['BorrowerConcentration', filters],
    () => DelinquencyService.getBorrowerConcentration(filters),
    getStaleMins()
  )

  const chartData = (data?.data || []).map(x => {
    const typeCastX = x as unknown as { [key: string]: number | string }
    const result = historicalExchgList(rates, x, typeCastX.cohort)
    return {
      ...result,
      ...typeCastX,
    }
  })

  /** simulate processing to remount chart component */
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [data, currency])

  let dynamicSeries: { bucketOrder: number; label: string; field: string }[] =
    []
  if (data?.data && data.data.length > 0) {
    const firstElem = data.data[0]
    for (let i = 0; i <= firstElem.bucketOrder; i++) {
      dynamicSeries.push({
        ...data.data,
        bucketOrder: i,
        label: firstElem[`bucketLabel_${i}`],
        field: `bucketPercentage_${i}`,
      })
    }
    dynamicSeries = dynamicSeries.map(row => ({
      ...row,
      tooltipValueFormat: '#.00a%',
      type: 'ColumnSeries',
      isStack: true,
    }))
  }

  const table_columns = [
    {
      title: 'Date',
      field: 'x',
      align: 'center',
      className: 'sticky left-0 min-w-[150px] bg-white',
      render: (r: any) => {
        return r.x ? moment.utc(r.x).format('YYYY-MM-DD') : ''
      },
    },
    ...dynamicSeries.map((s: any) => {
      return {
        className: 'min-w-[150px]',
        align: 'right',
        field: `bucketOutstandingBalance_${s.bucketOrder}`,
        title: s.label,
        head: { align: 'center' },
        render: (r: any) =>
          numberFormatter(r[`bucketOutstandingBalance_${s.bucketOrder}`]),
      } as any
    }),
    {
      className: 'min-w-[150px]',
      align: 'right',
      field: `total_outstanding_balance`,
      title: 'Total Outstanding Balance',
      head: { align: 'center' },
      render: (r: any) => numberFormatter(r[`total_outstanding_balance`]),
    },
  ]

  return (
    <MultiToggleLayout
      toggles={false}
      exchangeRateIndicator={showHistoricalRateIndicator}
      staticTipInfo={
        <div>
          The outstanding balance of a borrower / client as a percentage of the
          total outstanding portfolio.
          <br /> <br />
          Formula: For each individual borrower / client: outstanding balance of
          that borrower / total outstanding balance.
        </div>
      }
      chart={
        <Chart
          loading={isFetching || isProcessing}
          id={`BorrowerConcentration_by_${activeType}`}
          yLabel="Percentage"
          yFormat="#.00a%"
          data={chartData}
          series={dynamicSeries as any}
          tooltipSubtitle={
            showHistoricalRateIndicator
              ? displayRates(display_rates)
              : undefined
          }
          exportable={exportable}
          exportableColumn={table_columns}
          error={error as { message: string }}
        />
      }
      toggleData={
        <Table
          containerClass="[&>table>thead>tr>td]:text-center [&>table>thead>tr>td]:font-semibold"
          loading={isFetching || isProcessing}
          data={chartData}
          columns={table_columns}
        />
      }
    />
  )
}

export default BorrowerConcentrationL4
