import { FacilityDetailResponse } from '@interfaces/manage-monitor-action'
import { ReportingSchedulesResult } from '@interfaces/manage-reporting'

import manageApiClient from './client'

export class FacilityDetailsService {
  static async getDealPartyDetailsRequest(params: {
    facility: string
    slug_name: string
    facility_id: number
  }) {
    const { data } = await manageApiClient.post<FacilityDetailResponse[]>(
      `/deal-party-details/filter-by-facility`,
      params
    )
    return data
  }

  static async updateFacilityPartyDetailRequest(params: {
    facility: string
    slug_name: string
    facility_id: number
    entry: {
      type: string
      legal_name?: string
      distribution_list?: string
      supplementary?: string
    }
  }) {
    const { data } = await manageApiClient.post<boolean>(
      `/deal-party-details/update-party-detail`,
      params
    )
    return data
  }

  static async deleteFacilityPartyDetailRequest(params: {
    facility: string
    slug_name: string
    facility_id: number
    id: string
  }) {
    const { data } = await manageApiClient.post<boolean>(
      `/deal-party-details/delete-party-detail`,
      params
    )
    return data
  }

  static async getReportingScheduleRequest({
    facility,
    slug_name,
  }: {
    facility: string
    slug_name: string
  }) {
    const { data } = await manageApiClient.get<ReportingSchedulesResult[]>(
      `/deal-party-details/${slug_name}/reporting-schedule/${facility}`
    )
    return data
  }

  static async updateReportingScheduleRequest(params: {
    facility: string
    slug_name: string
    facility_id: number
    entry: {
      doc_name: string
      target: string
      frequency: string
      grace_period: number
      interval_ref_date: string
      enabled: boolean
    }
  }) {
    const { data } = await manageApiClient.post<boolean>(
      `/deal-party-details/update-reporting-schedule`,
      params
    )
    return data
  }

  static async deleteReportingScheduleRequest(params: {
    facility: string
    slug_name: string
    facility_id: number
    id: string
  }) {
    const { data } = await manageApiClient.post<boolean>(
      `/deal-party-details/delete-reporting-schedule`,
      params
    )
    return data
  }
}
