import {
  RiskCohortDataResponse,
  RisksFilters,
} from '@interfaces/analytics-risk'

import apiClient from './client'
export class CurvesService {
  static async getConditionalDefaultRate(params: RisksFilters) {
    const { data } = await apiClient.post<RiskCohortDataResponse>(
      '/curves/conditional-default-rate',
      params
    )

    return data
  }

  static async getSingleMonthMortality(params: RisksFilters) {
    const { data } = await apiClient.post<RiskCohortDataResponse>(
      '/curves/single-month-mortality',
      params
    )

    return data
  }

  static async getConditionalPrepaymentRate(params: RisksFilters) {
    const { data } = await apiClient.post<RiskCohortDataResponse>(
      '/curves/conditional-prepayment-rate',
      params
    )

    return data
  }
}
