import React, { useContext } from 'react'

import BaseLayout from '@components/layouts/base-layout'
import AuthContext from '@contexts/auth'
import { Typography } from '@material-tailwind/react'
import { Card } from '@material-tailwind/react'

import SummaryTable from './tests-table'

const SummaryL3 = () => {
  const { company } = useContext(AuthContext)

  return (
    <BaseLayout title="tests">
      <Card className="p-6 rounded-md">
        <div className="flex justify-between items-center mb-6">
          <Typography
            variant="h6"
            className="font-semibold text-cc-text-primary"
          >
            Summary Data&nbsp;&nbsp;
            <span className="text-sm">
              as of&nbsp;&nbsp;&nbsp;
              <span className="px-2 py-1 border border-cc-primary-border rounded-[0.25rem]">
                {company?.loanTapeAsOf}
              </span>
            </span>
          </Typography>
        </div>
        <SummaryTable />
      </Card>
    </BaseLayout>
  )
}

export default SummaryL3
