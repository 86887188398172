import React, { useState } from 'react'

import Table from '@components/table'
import {
  CovenantDetailResponse,
  CovenantListResponse,
  CovenantType,
} from '@interfaces/manage-monitor-covenant'

import { ManageCovenantDataBuilder } from '../manage-covenant-data-builder'

interface CovenantDetailVintageTableOnlyProps {
  covenant: CovenantDetailResponse
  type: CovenantType
  summary: CovenantListResponse
}

const CovenantDetailVintageTableOnly = ({
  covenant,
  summary,
}: CovenantDetailVintageTableOnlyProps) => {
  const [selectedCohort, setSelectedCohort] = useState<string>('Summary')
  const covenantBuilder = new ManageCovenantDataBuilder(
    setSelectedCohort,
    selectedCohort,
    covenant,
    summary
  )
  const { tableColumns, tableData, tableHeaderData } = covenantBuilder.build()

  return (
    <div className="gap-6 [&>div]:w-full">
      <Table
        containerClass="nohover overflow-auto [&>table>thead>tr>td]:font-semibold"
        allowOverflow={false}
        data={tableData}
        headerData={tableHeaderData}
        columns={tableColumns as any}
      />
    </div>
  )
}

export default CovenantDetailVintageTableOnly
