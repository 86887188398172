import {
  DataResponse,
  RiskDataResponse,
  RisksFilters,
} from '@interfaces/analytics-risk'

import apiClient from './client'
export class DelinquencyService {
  static async getFpdByCohort(params: RisksFilters) {
    const { data } = await apiClient.post<RiskDataResponse<DataResponse>>(
      '/delinquency/first-payment-default-rate',
      params
    )

    return data
  }

  static async getPar(params: RisksFilters) {
    const { data } = await apiClient.post<RiskDataResponse<DataResponse>>(
      '/delinquency/portfolio-at-risk',
      params
    )

    return data
  }

  static async getRdr(params: RisksFilters) {
    const { data } = await apiClient.post<RiskDataResponse<DataResponse>>(
      '/delinquency/rolling-default-rate',
      params
    )

    return data
  }

  static async getOutstandingDelinq(params: RisksFilters) {
    const { data } = await apiClient.post<RiskDataResponse<DataResponse>>(
      '/delinquency/outstanding-by-delinquency',
      params
    )

    return data
  }

  static async getBorrowerConcentration(params: RisksFilters) {
    const { data } = await apiClient.post<RiskDataResponse<DataResponse>>(
      '/delinquency/borrower-concentration',
      params
    )

    return data
  }

  static async getDelinqByCohort(params: RisksFilters) {
    const { data } = await apiClient.post<RiskDataResponse<DataResponse>>(
      '/delinquency/cohort-by-delinquency',
      params
    )

    return data
  }
}
