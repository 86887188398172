import moment, { Moment } from 'moment'

import { CategoriesResult } from '@interfaces/manage-reporting'

import { useCoignBackupInterestReportData } from './coign/backup-interest-report-data'
import { useCoignComplianceCertData } from './coign/compliance-cert-data'
import { useCoignMonthEndReportData } from './coign/month-end-report'
import { useFlexpathWaterfallCustomData } from './flexpath/useFlexpathWaterfallCustomData'
import { useFSVNCustomData } from './fsvn/fsvn-custom-data-hook'
import { useOrangeBankComplianceCertData } from './orange-bank/compliance-cert-data'
import { useOrangeBankQuarterlyPaymentDemandCertData } from './orange-bank/quarterly-payment-demand'
import { useRaistoneWaterfallCustomData } from './raistone/useRaistoneWaterfallCustomData'
import { useSpecialtyCapitalWaterfallCustomData } from './specialty-capital/useSpecialtyCapitalWaterfallCustomData'
import { useVariantReportData } from './variant/useVariantReportCustomData'

export const getCustomData = (
  category: CategoriesResult,
  effectiveDate?: Moment
): [boolean, Record<string, any>] => {
  switch (category.category) {
    case 'Borrowing Base Certificate': {
      switch (category.subcategory) {
        case 'fsvn-alteriq':
          return useFSVNCustomData()
        default:
          return [false, {}]
      }
    }

    case 'Compliance Certificate': {
      switch (category.subcategory) {
        case 'coign-compliance-cert':
          return useCoignComplianceCertData()
        default:
          return [false, {}]
      }
    }

    case 'Financial Covenant Certificate': {
      switch (category.subcategory) {
        case 'fsvn-alteriq':
          return useFSVNCustomData()
        default:
          return [false, {}]
      }
    }
    case 'Portfolio Covenant Certificate': {
      switch (category.subcategory) {
        case 'fsvn-alteriq':
          return useFSVNCustomData()
        default:
          return [false, {}]
      }
    }
    case 'Unaudited Financial Statements': {
      switch (category.subcategory) {
        default:
          return [false, {}]
      }
    }

    case 'Waterfall Report': {
      switch (category.subcategory) {
        case 'flexpath-waterfall':
          return useFlexpathWaterfallCustomData()
        case 'raistone-waterfall':
          return useRaistoneWaterfallCustomData()
        case 'specialty-capital-waterfall':
          return useSpecialtyCapitalWaterfallCustomData(
            effectiveDate ?? moment.utc()
          )
        default:
          return [false, {}]
      }
    }

    default:
      switch (category.subcategory) {
        case 'coign-backup-interest-report':
          return useCoignBackupInterestReportData(effectiveDate ?? moment.utc())
        case 'coign-monthly-report':
          return useCoignMonthEndReportData()
        case 'orange-bank-compliance-monthly':
          return useOrangeBankComplianceCertData('monthly')
        case 'orange-bank-compliance-quarterly':
          return useOrangeBankComplianceCertData('quarterly')
        case 'orange-bank-payment-demand':
          return useOrangeBankQuarterlyPaymentDemandCertData('quarterly', true)
        case 'variant-report':
          return useVariantReportData(effectiveDate)

        default:
          return [false, {}]
      }
  }
}
