import apiClient from './client'
/**
 * Series of ANALYTICS helper functions that get triggered everytime we "switch" clients
 */

export interface HistoricalAnalyticsType {
  loanTapeAsOf: string
  lastPipeline: string
  financialsAsOf: string
  lastFinancialsPipeline: string
}

const DEFAULT_ERR_MSG = 'N/A'
const DEFAULT_ERR_RESPONSE: HistoricalAnalyticsType = {
  loanTapeAsOf: DEFAULT_ERR_MSG,
  lastPipeline: DEFAULT_ERR_MSG,
  financialsAsOf: DEFAULT_ERR_MSG,
  lastFinancialsPipeline: DEFAULT_ERR_MSG,
}

export const fetchAnalyticsHistoricals = async (
  slug: string
): Promise<HistoricalAnalyticsType> => {
  try {
    const response = await apiClient.get(`/client/historical-dates/${slug}`)
    const data = response.data
    const loanTapeAsof = data?.loanTapeAsOf?.split('T')[0] ?? 'Unavailable'
    const lastAnalyticsPipeline =
      data?.lastUpdated?.split('T')[0] ?? 'Unavailable'
    const financialsAsOf = data?.financialsAsOf?.split('T')[0] ?? 'Unavailable'
    const lastFinancialsPipeline =
      data?.lastFinancialsPipeline?.split('T')[0] ?? 'Unavailable'
    return {
      loanTapeAsOf: loanTapeAsof,
      lastPipeline: lastAnalyticsPipeline,
      financialsAsOf: financialsAsOf,
      lastFinancialsPipeline: lastFinancialsPipeline,
    }
  } catch (e) {
    console.log('analytics dates failing because: ', e)
    return DEFAULT_ERR_RESPONSE
  }
}
