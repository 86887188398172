import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import Chart from '@components/chart'
import MultiToggleLayout from '@components/layouts/multi-toggle/multi-toggle-layout'
import Table from '@components/table'
import { REMOUNT_MS } from '@constants/config'
import AuthContext from '@contexts/auth'
import { numberFormatter } from '@helpers/number-formatter'
import { seriesSorter } from '@helpers/number-formatter'
import { getStaleMins } from '@helpers/stale-timer'
import { RisksFilters } from '@interfaces/analytics-risk'
import { RiskDataItem } from '@interfaces/analytics-risk'
import { Filter, FilterItem } from '@interfaces/analytics-risk-filter-type-key'
import { CurvesService } from '@services/api-analytics/risk-curves'

import { historicalExchgList } from '../../common/historical-exchange-rate'

const ConditionalDefaultRate = ({ exportable }: { exportable: boolean }) => {
  const { company, appliedFilters, optionFilters } = useContext(AuthContext)
  const { rates = {}, categoryTypeOptions = [] } = optionFilters

  const {
    dateStart,
    dateEnd,
    categoryTypes = [],
    activeType,
    currency = 'USD',
  } = appliedFilters

  const activeVal =
    categoryTypeOptions?.find((rf: Filter) => rf.type_key === activeType)
      ?.type_val ?? []

  const isAggregate = activeType === 'All'

  const filters: RisksFilters = {
    slug_name: company?.slug_name ?? '',
    date_from: moment(dateStart).format('YYYY-MM-DD'),
    date_to: moment(dateEnd).format('YYYY-MM-DD'),
    currency,
    filters: isAggregate ? [] : categoryTypes,
  }

  const { error, data, isFetching } = useQuery(
    ['ConditionalDefaultRate', filters],
    () => CurvesService.getConditionalDefaultRate(filters),
    getStaleMins()
  )

  /** simulate processing to remount chart component */
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  useEffect(() => {
    setIsProcessing(true)
    setTimeout(() => {
      setIsProcessing(false)
    }, REMOUNT_MS)
  }, [data, currency])

  let maxVal = 0

  // Populate Chart Data
  const chartData = []

  for (const cohort in data?.data || {}) {
    const result = historicalExchgList(rates, '', cohort)
    let cohortData: RiskDataItem[] = []
    if (!data?.data[cohort as any]) {
      continue
    }
    cohortData = data?.data[cohort as any]

    if (isAggregate) {
      result.type_all = cohortData[0].val
      result.type_all_denom = cohortData[0].denom
      result.type_all_numer = cohortData[0].numer
      result.type_all_liq = cohortData[0].liquidation_rate as number
      if (result.type_all > maxVal) {
        maxVal = result.type_all
      }
    } else {
      // Assume return value MUST include all categoryTypes
      for (const categoryTypeObj of cohortData) {
        const categoryType = categoryTypeObj.id
        result[`type_${categoryType}`] = categoryTypeObj.val
        result[`type_${categoryType}_liq`] =
          categoryTypeObj.liquidation_rate as number
        result[`type_${categoryType}_denom`] = categoryTypeObj.denom
        result[`type_${categoryType}_numer`] = categoryTypeObj.numer
        if (result[`type_${categoryType}`] > maxVal) {
          maxVal = result[`type_${categoryType}`]
        }
      }
    }
    chartData.push(result)
  }

  const series: any[] = isAggregate
    ? [
        {
          label: 'Conditional Default Rate',
          tooltipValueFormat: '#.00a%',
          type: 'SmoothedXLineSeries',
          field: 'type_all',
        },
      ]
    : seriesSorter(
        [0, ...categoryTypes].map((ct: number) => {
          const typeVal = activeVal.find((av: FilterItem) => av.id === ct)
          const color = '#' + Math.floor(Math.random() * 16777215).toString(16)
          return {
            label: `${typeVal ? typeVal.type : 'All'}`,
            tooltipValueFormat: '#.00a%',
            field: `type_${ct}`,
            color,
            type: 'SmoothedXLineSeries',
            hasBullet: true,
          }
        })
      )

  const sub_series_headers = [
    series.reduce((accu: any, curr: any) => {
      return {
        ...accu,
        [`${curr.field}_numer`]: 'Written Off Amount',
        [`${curr.field}_denom`]: 'Starting Outstanding Balance',
        [`${curr.field}_liq`]: 'Liquidation Rate',
        [curr.field]: 'CDR',
      }
    }, {}),
  ]

  const table_columns = [
    {
      title: 'Cohort',
      field: 'x',
      align: 'center',
      className: 'sticky left-0 min-w-[150px] bg-white',
      render: (r: any) => {
        return r.x ? moment.utc(r.x).format('YYYY-MM-DD') : ''
      },
    },
    ...series.reduce((accu, curr) => {
      const categoryType = curr.field
      const label = curr.label
      const localColumns = ['_numer', '_denom', '_liq', ''].map(suffix => {
        const field = categoryType + suffix
        return {
          className: 'min-w-[150px]',
          field: field,
          title: label + ` +`,
          align: 'right',
          head: { colSpan: suffix === '' ? 4 : 0, align: 'center' },
          render: (r: any) => numberFormatter(r[field]),
        }
      })

      return [...accu, ...localColumns]
    }, []),
  ]

  const staticToolTipText = (
    <span>
      <span className="font-semibold">Description:</span>
      <br />
      <br />
      Conditional default rate (CDR) is based on the monthly liquidation rate
      annualized.
      <br />
      <br />
      <span className="font-semibold">Formula:</span>
      <br />
      <br />
      Liquidation rate for a month is the Principal balance written off in a
      month / Start of month outstanding balance. <br />
      <br />
      CDR: 1 - ( 1 - Liquidation Rate)<sup>12</sup>
    </span>
  )

  return (
    <MultiToggleLayout
      toggles={false}
      staticTipInfo={staticToolTipText}
      chart={
        <Chart
          loading={isFetching || isProcessing}
          id={`ConditionalDefaultRate_by_${activeType}`}
          yLabel="Percentage"
          yFormat="#.00a%"
          ySetting={{ maxPrecision: 1 }}
          data={chartData as any}
          series={series as any}
          exportable={exportable}
          exportableColumn={table_columns}
          error={error as { message: string }}
        />
      }
      toggleData={
        <Table
          containerClass="[&>table>thead>tr>td]:text-center [&>table>thead>tr>td]:font-semibold"
          loading={isFetching || isProcessing}
          data={chartData}
          columns={table_columns}
          headerData={sub_series_headers}
        />
      }
    />
  )
}

export default ConditionalDefaultRate
