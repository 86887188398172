import React from 'react'

import {
  CheckIcon,
  EllipsisHorizontalIcon,
  MinusCircleIcon,
  PencilIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline'
import {
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Tooltip,
} from '@material-tailwind/react'

interface Props {
  id: string
  edit: boolean
  disableMenu?: boolean
  disableButtons?: boolean
  disableSave: boolean
  handleEdit: (id: string) => void
  handleDelete: (id: string) => void
  handleCancel: () => void
}

export const OptionsMenu = ({
  id,
  edit,
  disableMenu,
  disableButtons,
  disableSave,
  handleEdit,
  handleDelete,
  handleCancel,
}: Props) => {
  return edit ? (
    <div className="flex flex-row gap-1">
      <Tooltip content="Save" placement="top">
        <button
          type="submit"
          className="flex justify-center items-center p-2 cursor-pointer rounded-lg bg-primary-main enabled:hover:bg-primary-hover disabled:bg-neutral-border-1 disabled:border-neutral-border-2 disabled:border disabled:text-neutral-body-1 disabled:cursor-auto text-white transition ease-in-out delay-50 duration-300"
          disabled={disableButtons || disableSave}
        >
          <CheckIcon className="w-4 h-4" />
        </button>
      </Tooltip>
      <Tooltip content="Cancel" placement="top">
        <button
          className="flex justify-center items-center p-2 cursor-pointer rounded-lg border border-gray-300 bg-white hover:bg-gray-200 text-red hover:text-gray-600 transition ease-in-out delay-50 duration-300"
          onClick={() => {
            handleCancel()
          }}
          disabled={disableButtons}
        >
          <XCircleIcon className={'w-4 h-4'} />
        </button>
      </Tooltip>
    </div>
  ) : (
    <Menu placement="left-end">
      <MenuHandler disabled={disableMenu}>
        <button
          className={`flex justify-center w-full p-2 text-sm rounded-lg hover:bg-primary-main hover:text-white transition ease-in-out delay-50 duration-300 curor-default ${
            !disableMenu && 'cursor-pointer'
          }`}
        >
          <EllipsisHorizontalIcon className="w-4 h-4" />
        </button>
      </MenuHandler>
      <MenuList className="z-10">
        <MenuItem
          className="!text-primary-main flex hover:bg-primary-surface-2"
          onClick={() => handleEdit(id)}
        >
          <PencilIcon className="mr-2 w-4 h-4" />
          Edit
        </MenuItem>
        <MenuItem
          className="!text-primary-main flex hover:bg-primary-surface-2"
          onClick={() => handleDelete(id)}
        >
          <MinusCircleIcon className="mr-2 w-4 h-4" />
          Delete
        </MenuItem>
      </MenuList>
    </Menu>
  )
}
