import React from 'react'

import L4TabsLayout from '@components/layouts/l4-tabs-layout'

import CashflowL5 from './cashflow'

const CashflowL4 = () => {
  const tabs = [
    {
      label: 'Cashflow',
      component: <CashflowL5 />,
    },
  ]

  return <L4TabsLayout tabs={tabs} />
}

export default CashflowL4
